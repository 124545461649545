import { ProductManager } from '@/components/entities/product/ProductManager'
import { ButtonLinkBack } from '@/components/lib/ButtonLinkBack'
import { Loading } from '@/components/lib/Loading'
import { Title } from '@/components/lib/Title'
import CompanyService from '@/services/Company/CompanyService'
import OrderService from '@/services/Order/OrderService'
import { BookingSlotType } from '@/types/BookingSlotType'
import { ClientType } from '@/types/ClientType'
import { CompanyType } from '@/types/CompanyType'
import { ProductType } from '@/types/ProductType'
import {
  generateBarcode,
  generateMultiorderCode,
  generateRandomNumber,
} from '@/utils/Functions'
import { flashStore } from '@/utils/Store'
import * as Sentry from '@sentry/react'
import { AxiosError, AxiosResponse } from 'axios'
import { InfoIcon } from 'lucide-react'
import React from 'react'
import { Alert, Button, Card, Col, Form, Image, Row, Spinner, Table } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

// interface OrderInfo {
//   slotSize?: string;
//   temperatureZonePredefined?: string;
//   products: ProductType[];
//   bookingSlot: string;
//   _bookingSlot: BookingSlotType;
//   totalSlot: number;
//   client: ClientType;
// }

interface OrderCreate {
  ageRestriction?: number | string | null
  barcode?: string
  bookingSlot: string
  _bookingSlot?: BookingSlotType
  client?: {
    email: string
    firstname: string
    lastname: string
    phone: string
  }
  hold?: number
  products: ProductType[]
  receiveCode?: string
  multiOrderCode?: string
  totalSlot?: number
}

// interface OrderCreate {
//   ageRestriction: number | string | null;
//   barcode: string;
//   bookingSlot: string;
//   _bookingSlot: BookingSlotType[];
//   client: {
//     email: string;
//     firstname: string;
//     lastname: string;
//     phone: string;
//   };
//   hold: number;
//   products: ProductType[];
//   receiveCode: string;
//   multiOrderCode?: string;
//   totalSlot: number;
// }

export const OrderAdd: React.FC = () => {
  /* States / Hooks
   *******************************************************************************************/
  const [companies, setCompanies] = React.useState<CompanyType[]>([])
  const [companySelected, setCompanySelected] = React.useState<CompanyType | undefined>()
  const flash = flashStore()
  const [onSubmitLoading, setOnSubmitLoading] = React.useState<boolean>(false)
  const [orders, setOrders] = React.useState<OrderCreate[]>([])
  const [client, setClient] = React.useState<ClientType>()
  const [stepCreation, setStepCreation] = React.useState<number>(1)
  const { register, handleSubmit, reset } = useForm<ClientType>()
  const navigate = useNavigate()

  /* UseEffect
   *******************************************************************************************/
  React.useEffect(() => {
    getCompanies()
  }, [])

  /* Functions
   *******************************************************************************************/
  // OK - Lister toutes les sociétés
  const getCompanies = () => {
    CompanyService.getBookingSlotsOfCompanies(1, 1000).then((response: AxiosResponse) => {
      setCompanies(response.data['hydra:member'])
    })
  }

  // OK - A la sélection d'une société lister tous ces bookings
  const handleCompanySelected = (company: CompanyType) => {
    setCompanySelected(company)
    setStepCreation(2)
  }

  // OK - ClearState rollback to step 1
  const handleClearState = () => {
    setCompanySelected(undefined)
    setOrders([])
    setStepCreation(1)
  }

  // OK - Sauvegarder le nombre de casier réservé par booking
  const handleSaveNbSlot = (nb_slot: number, currentBooking: BookingSlotType) => {
    if (currentBooking?.available) {
      if (nb_slot > currentBooking?.available) {
        flash.setMessage(
          'error',
          'Attention ! il en reste que ' +
            currentBooking?.available +
            ' casier pour ce booking.'
        )
      } else {
        // Set First Data Orders
        const firstData = {
          bookingSlot: '/api/booking_slots/' + currentBooking?.id,
          _bookingSlot: currentBooking,
          products: [],
          totalSlot: nb_slot,
        }
        setOrders([...orders, { ...firstData }])
      }
    }
  }

  // OK - Submit Nb casier by bookings
  const handleSumbitNbSlot = () => {
    // Set New Data Format Orders
    const new_orders = orders.reduce<OrderCreate[]>((acc, item) => {
      const numSlots = item.totalSlot || 1
      for (let i = 0; i < numSlots; i++) {
        acc.push({
          ...item,
          totalSlot: 1,
        })
      }
      return acc
    }, [])

    setOrders([])
    setOrders(new_orders)
    setStepCreation(3)
  }

  // OK - Submit for preparing order
  const handlePrepareOrder = (customer: ClientType) => {
    setClient(customer)
    let orderMain = null
    const receiveCode = generateRandomNumber()
    const barcode = companySelected?.cleveronCompanyId + '-' + generateBarcode()
    const _orders = orders.map((order: OrderCreate, index: number) => ({
      ...order,
      ...{ client: customer },
      ...{
        receiveCode: receiveCode,
        barcode: orders.length > 1 ? barcode + (index + 1) : barcode,
        hold: 2880,
      },
    }))
    if (_orders.length > 1) {
      const multiOrderCode = generateMultiorderCode()
      const multi_orders = _orders.map((_order: OrderCreate) => ({
        ..._order,
        multiOrderCode,
      }))
      orderMain = multi_orders
    } else {
      orderMain = _orders
    }

    setClient(customer)
    setOrders(orderMain)
    setStepCreation(4)
  }

  // OK - Set Products by Carts
  const handleSetProducts = (index: number, products: ProductType[]) => {
    const newOrders = [...orders]
    newOrders[index].products = products
    setOrders(newOrders)
  }

  // OK - Remove Product by Line
  const handleRemoveProductLine = (index: number, productIndex: number) => {
    const updatedOrders = [...orders]
    updatedOrders[index].products = updatedOrders[index].products.filter(
      (_, i) => i !== productIndex
    )
    setOrders(updatedOrders)
  }

  // Ok - Save Order
  const handleSaveOrder = () => {
    setOnSubmitLoading(true)
    // SetAlcohol Orders
    const updatedOrders = orders.map((order) => {
      const updatedProducts = order.products.map((product) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { isAlcoholic, ...rest } = product // Retirez le champ isAlcoholic
        return rest
      })

      // Retirez _bookingSlot de l'objet order
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { _bookingSlot, ...orderWithoutBookingSlot } = order

      const hasAlcoholicProduct = order.products.some((product) => product.isAlcoholic)

      if (hasAlcoholicProduct) {
        return {
          ...orderWithoutBookingSlot,
          ageRestriction: 18,
          products: updatedProducts, // Utilisez les produits mis à jour
        }
      } else {
        return {
          ...orderWithoutBookingSlot,
          ageRestriction: null,
          products: updatedProducts, // Utilisez les produits mis à jour
        }
      }
    })

    Promise.all(
      updatedOrders.map((_order) => {
        return OrderService.create(_order)
          .then((response: AxiosResponse) => {
            // Peut-être effectuer d'autres actions en fonction de la réponse
            return response // Passer la réponse à la chaîne de promesses
          })
          .catch((error: AxiosError) => {
            flash.setMessageErrorForm()
            Sentry.captureException(error)
            throw error // Propager l'erreur pour être capturée par le gestionnaire d'erreurs global
          })
      })
    )
      .then(() => {
        navigate('/livraisons')
      })
      .catch((error: AxiosError) => {
        console.error('Une ou plusieurs requêtes de création ont échoué:', error)
        // Peut-être afficher un message d'erreur à l'utilisateur
      })
      .finally(() => {
        setOnSubmitLoading(false)
      })
  }

  /* Render
   *******************************************************************************************/
  return (
    <>
      <Row>
        <Col>
          <Title name={'Livraison / création'} />
        </Col>
        <Col>
          <ButtonLinkBack />
        </Col>
      </Row>
      {/* Step 1 */}
      {stepCreation === 1 && (
        <Row className='mt-4'>
          <Col className='col-xl-8 col-lg-10 col-md-12 mx-auto py-3'>
            {companies.length == 0 ? (
              <Loading />
            ) : (
              <>
                <h6 className='text-center mb'>Sélectionnez la société</h6>
                <div className='text-center mb-4'>
                  <small className='text-muted'>Etape 1 sur 4</small>
                </div>
                <Row>
                  {companies?.map(
                    (company: CompanyType) =>
                      company?.bookingSlots.length > 0 && (
                        <Col key={company?.id} className='col-4'>
                          <Card
                            className='my-3 py-2 pt-3 text-center cursor-pointer hover-box-shadow'
                            onClick={() => handleCompanySelected(company)}
                            key={company.id}
                          >
                            <Card.Body>
                              <h6>{company.name}</h6>
                            </Card.Body>
                          </Card>
                        </Col>
                      )
                  )}
                </Row>
              </>
            )}
          </Col>
        </Row>
      )}
      {/* Step 2 */}
      {stepCreation === 2 && (
        <Row className='mt-4'>
          <Col className='col-xl-8 col-lg-10 col-md-12 mx-auto py-3'>
            <h6 className='text-center'>Réservez vos casiers</h6>
            <div className='text-center mb-4'>
              <small className='text-muted'>Etape 2 sur 4</small>
            </div>
            <Table striped hover>
              <thead>
                <tr>
                  <th>Machine</th>
                  <th className=''>Zone et dimensions </th>
                  <th className='text-center'>Casiers disponibles</th>
                  <th className='text-center'>Qté de casier souhaitée</th>
                </tr>
              </thead>
              <tbody>
                {companySelected?.bookingSlots?.map((booking: BookingSlotType) => (
                  <tr key={booking.id}>
                    <td>
                      {booking?.slot?.temperatureZone?.locker?.location}
                      <br />
                      {booking?.slot?.temperatureZone?.locker?.shortLocation}
                    </td>
                    <td className=''>
                      {' '}
                      {booking?.slot?.temperatureZone?.name}
                      <br />H {booking?.slot?.height} x L {booking?.slot?.width} x P{' '}
                      {booking?.slot?.depth} cm
                    </td>
                    <td className='text-center'>{booking.available}</td>
                    <td className='text-end card'>
                      <input
                        type='text'
                        className=' form-control text-center bg-white'
                        placeholder='0'
                        onChange={(e) =>
                          handleSaveNbSlot(Number(e.currentTarget.value), booking)
                        }
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <div className='buttons text-end'>
              <Button variant='secondary' className='me-3' onClick={handleClearState}>
                Annuler
              </Button>
              <Button type='submit' variant='dark' onClick={handleSumbitNbSlot}>
                Valider
              </Button>
            </div>
          </Col>
        </Row>
      )}
      {/* Step 3 */}
      {stepCreation === 3 && (
        <Row className='mt-4'>
          <Col className='col-xl-6 col-lg-10 col-md-12 mx-auto py-3'>
            <h6 className='text-center'>Renseignez les coordonnées du destinaire</h6>
            <div className='text-center mb-4'>
              <small className='text-muted'>Etape 3 sur 4</small>
            </div>
            <Card>
              <Card.Body className='p-5'>
                <Form onSubmit={handleSubmit(handlePrepareOrder)}>
                  <Form.Group as={Row} className='mb-3'>
                    <Form.Label column sm={3}>
                      Nom <span className='text-danger'>*</span>
                    </Form.Label>
                    <Col sm={9}>
                      <Form.Control
                        type='text'
                        {...register('lastname', { required: true })}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className='mb-3'>
                    <Form.Label column sm={3}>
                      Prénom <span className='text-danger'>*</span>
                    </Form.Label>
                    <Col sm={9}>
                      <Form.Control
                        type='text'
                        {...register('firstname', { required: true })}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className='mb-3'>
                    <Form.Label column sm={3}>
                      N° Téléphone <span className='text-danger'>*</span>
                    </Form.Label>
                    <Col sm={9}>
                      <Form.Control type='text' {...register('phone', { required: true })} />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className='mb-3'>
                    <Form.Label column sm={3}>
                      Adresse email <span className='text-danger'>*</span>
                    </Form.Label>
                    <Col sm={9}>
                      <Form.Control type='text' {...register('email', { required: true })} />
                    </Col>
                  </Form.Group>
                  <div className='buttons text-end mt-4'>
                    <Button
                      variant='secondary'
                      className='me-3'
                      onClick={() => {
                        reset()
                        setOrders([])
                        setStepCreation(2)
                      }}
                    >
                      Annuler
                    </Button>
                    <Button type='submit' variant='dark'>
                      Enregistrer
                    </Button>
                  </div>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      )}
      {/* Step 4 */}
      {stepCreation === 4 && (
        <>
          <Row className='mt-4'>
            <Col className='col-xl-8 col-lg-10 col-md-12 mx-auto py-3'>
              <h6 className='text-center'>Récapitulatif de la commande</h6>
              <div className='text-center mb-4'>
                <small className='text-muted'>Etape 4 sur 4</small>
              </div>
            </Col>
          </Row>
          <Row>
            <Col className='col-xl-8 col-lg-10 col-md-12 mx-auto'>
              <Row>
                <Col className='col-4'>
                  <Card>
                    <Card.Body>
                      <div className='text-center'>
                        <Image
                          src={'/img/avatar-default.png'}
                          alt='Image'
                          className='img-fluid my-3'
                          width={80}
                        />
                        <p>
                          {client?.firstname + ' ' + client?.lastname}
                          <br />
                          {client?.phone}
                          <br />
                          {client?.email}
                        </p>
                      </div>
                    </Card.Body>
                  </Card>
                  <div className='buttons text-end mt-4'>
                    <Button
                      variant='secondary'
                      className='me-3 w-100'
                      onClick={() => {
                        reset()
                        setOrders([])
                        setStepCreation(1)
                      }}
                    >
                      Annuler la commande
                    </Button>
                    <Button
                      type='submit'
                      variant='success'
                      className='w-100 mt-3'
                      onClick={handleSaveOrder}
                    >
                      {onSubmitLoading ? <Spinner size={'sm'} /> : 'Valider la commande'}
                    </Button>
                  </div>
                </Col>
                <Col>
                  <Alert variant='info'>
                    <Row>
                      <Col className='col-1 text-center'>
                        <InfoIcon className='pt-1' size={35} />
                      </Col>
                      <Col>
                        Renseigner les produits n'est pas une obligation, sauf si la commande
                        contient de l'alcool, auquel cas il est important et obligatoire de le
                        déclarer.
                      </Col>
                    </Row>
                  </Alert>
                  {orders?.map((order: OrderCreate, index: number) => (
                    <Card key={index} className='mb-3'>
                      <Card.Header className='bg-card-header'>
                        <h5 className='mb-0'>Casier {index + 1}</h5>
                        <small>
                          Destination :{' '}
                          {order?._bookingSlot?.slot?.temperatureZone?.locker?.location}
                          {' - '}
                          {order?._bookingSlot?.slot?.temperatureZone?.locker?.shortLocation}
                          <br />
                          Zone de température :{' '}
                          {order?._bookingSlot?.slot?.temperatureZone?.name}
                        </small>
                      </Card.Header>
                      <Card.Body>
                        <ProductManager
                          key={index}
                          initialProducts={order.products}
                          setProducts={(products) => handleSetProducts(index, products)}
                          removeProduct={(productIndex) =>
                            handleRemoveProductLine(index, productIndex)
                          }
                        />
                      </Card.Body>
                    </Card>
                  ))}
                </Col>
              </Row>
            </Col>
          </Row>
        </>
      )}
    </>
  )
}
