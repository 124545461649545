import { Badge, Card, Col, Image, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Title } from "@/components/lib/Title";
import { ButtonLinkBack } from "@/components/lib/ButtonLinkBack";
import { MemberShipListTable } from "@/components/entities/company/MemberShipListTable";
import React from "react";
import { UserType } from "@/types/UserType";
import { flashStore } from "@/utils/Store";
import UserService from "@/services/User/UserService";
import AuthService from "@/services/Auth/AuthService";
import * as Sentry from "@sentry/react";
import { AxiosError, AxiosResponse } from "axios";
import { Loading } from "@/components/lib/Loading";
import { getRoleUserText, setDate } from "@/utils/Functions";
import { Edit } from "lucide-react";
import { OwnerShipListTable } from "@/components/entities/company/OwnerShipListTable";

export const MyAccount = () => {
  /* States / Hooks
   *******************************************************************************************/
  const [data, setData] = React.useState<UserType>();
  const flash = flashStore();

  /* useEffect
   *******************************************************************************************/
  React.useEffect(() => {
    getMe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /* Functions / Events
   *******************************************************************************************/
  const getMe = () => {
    UserService.getCurrentUser()
      .then((response: AxiosResponse) => {
        setData(response.data);
      })
      .catch((error: AxiosError) => {
        AuthService.refreshToken();
        flash.setMessageErrorFetch();
        Sentry.captureException(error);
      });
  };

  /* Render
   *******************************************************************************************/
  return (
    <>
      <Row>
        <Col>
          <Title name={"Mon compte"} />
        </Col>
        <Col>
          <ButtonLinkBack />
        </Col>
      </Row>
      <Row className="mt-4">
        <Col lg={3}>
          <Card className="py-3">
            <Card.Body>
              {!data ? (
                <Loading />
              ) : (
                <>
                  <div className="text-center">
                    <Image
                      src={"/img/avatar-default.png"}
                      alt="Image"
                      className="img-fluid mb-3"
                      width={100}
                    />
                  </div>
                  <div className="text-center mb-4">
                    <Card.Title>
                      {data?.firstName + " " + data?.lastName}
                    </Card.Title>
                    {data?.ownerships?.length > 0 ? (
                      <Badge bg="primary pt-2 pb-1 mb-3">Propriétaire</Badge>
                    ) : (
                      <Badge bg="primary pt-2 pb-1 mb-3">
                        {getRoleUserText(data)}
                      </Badge>
                    )}
                    <br />
                    <small className="text-muted">
                      Date création : {setDate(data?.createdAt)}
                    </small>
                  </div>
                  <hr />
                  <p>
                    Username :
                    <span className="float-end">{data?.username}</span>
                  </p>
                  <p>
                    Email :<span className="float-end">{data?.email}</span>
                  </p>
                  <p>
                    Tél :<span className="float-end">{data?.phone}</span>
                  </p>
                  <p>
                    Statut :
                    <span className="float-end">
                      {data?.enabled ? "Activé" : "Désactivé"}
                    </span>
                  </p>

                  <hr />
                  <Link
                    className="btn btn-sm btn-dark d-block"
                    to={"/mon-compte/modification/"}
                  >
                    <Edit className="me-2" size={20} />
                    Modifier mon profil
                  </Link>
                </>
              )}
            </Card.Body>
          </Card>
        </Col>
        <Col lg={9}>
          {data && data?.memberships.length > 0 && (
            <MemberShipListTable memberships={data?.memberships} />
          )}
          {data && data?.ownerships.length > 0 && (
            <OwnerShipListTable ownerships={data?.ownerships} />
          )}
        </Col>
      </Row>
    </>
  );
};
