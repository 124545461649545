import { Outlet } from "react-router-dom";
import { FlashComponent } from "@/components/lib/Flash";
import SidebarApp from "@/components/SidebarApp";
import TopBarApp from "@/components/TopBarApp";
import { Helmet } from "react-helmet";
import { FooterApp } from "@/components/FooterApp";

export const AdminLayout = () => {
  return (
    <>
      <Helmet>
        <script type="text/javascript" src="/js/sb-admin-2.js"></script>
      </Helmet>
      <FlashComponent />
      <div id="wrapper">
        <SidebarApp />
        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content" className="pb-5">
            <TopBarApp />
            <div className="container-fluid">
              <Outlet />
            </div>
          </div>
          <FooterApp />
        </div>
      </div>
      <a className="scroll-to-top rounded" href="#page-top">
        <i className="ri-arrow-up-s-line"></i>
      </a>
    </>
  );
};
