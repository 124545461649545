import { Image } from 'react-bootstrap'
import { NavLink } from 'react-router-dom'

function SidebarApp() {
  return (
    <ul
      className='navbar-nav bg--primary sidebar sidebar-dark accordion'
      id='accordionSidebar'
    >
      <NavLink
        className='sidebar-brand d-flex align-items-center justify-content-center'
        to='/'
      >
        <div className='sidebar-brand-icon'>
          <Image src='/img/logo.png' width={50} className='img-fluid' alt='Logo' />
        </div>
        <div className='sidebar-brand-text mx-3'>Dashboard</div>
      </NavLink>

      <hr className='sidebar-divider my-0' />
      <li className='nav-item'>
        <NavLink className='nav-link' to='/societes'>
          <i className='ri-building-line'></i>
          <span>Sociétés</span>
        </NavLink>
      </li>
      <li className='nav-item'>
        <NavLink className='nav-link' to='/lockers'>
          <i className='ri-home-wifi-line'></i>
          <span>Machines</span>
        </NavLink>
      </li>
      <li className='nav-item'>
        <NavLink className='nav-link' to='/bookings'>
          <i className='ri-calendar-check-fill'></i>
          <span>Bookings</span>
        </NavLink>
      </li>
      <li className='nav-item'>
        <NavLink className='nav-link' to='/livraisons'>
          <i className='ri-truck-line'></i>
          <span>Livraisons</span>
        </NavLink>
      </li>
      <hr className='sidebar-divider d-none d-md-block border-white' />
      <li className='nav-item'>
        <NavLink className='nav-link' to='/utilisateurs'>
          <i className='ri-group-line'></i>
          <span>Utilisateurs</span>
        </NavLink>
      </li>
      <li className='nav-item'>
        <a href={import.meta.env.VITE_APP_END_POINT} target='_blank' className='nav-link'>
          <i className='ri-code-line'></i>
          <span>Documentation API</span>
        </a>
      </li>

      <div className='text-center d-none d-md-inline mt-5'>
        <button className='rounded-circle border-0' id='sidebarToggle'>
          <i className='ri-arrow-left-s-line'></i>
        </button>
      </div>
    </ul>
  )
}

export default SidebarApp
