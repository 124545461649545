import React from "react";
import { Button, Col, Row, Form, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { CompanyType } from "@/types/CompanyType";
import { flashStore } from "@/utils/Store";
import CompanyService from "@/services/Company/CompanyService";
import { AxiosResponse } from "axios";
import * as Sentry from "@sentry/react";
import { getMessageErrorSubmit } from "@/utils/Functions";

interface ApiResponse {
  response: AxiosResponse;
  violations: Violation[];
}

interface Violation {
  propertyPath: string;
  message: string;
  code: string;
}

export const CompanyAddForm = () => {
  /* States / Hooks
   *******************************************************************************************/
  const [onSubmitLoading, setOnSubmitLoading] = React.useState<boolean>(false);
  const [messageErrorInput, setMessageErrorInput] = React.useState();
  const navigate = useNavigate();
  const flash = flashStore();
  const { register, handleSubmit } = useForm<CompanyType>();

  /* Functions / Events
   *******************************************************************************************/
  const onSubmit = (data: CompanyType) => {
    setOnSubmitLoading(true);
    CompanyService.create(data)
      .then(() => {
        flash.setMessage("success", "Société créée !");
        navigate(-1);
      })
      .catch((error: ApiResponse) => {
        if (error?.response?.data?.violations?.length > 0) {
          flash.setMessageErrorValidator();
          setMessageErrorInput(error.response.data.violations);
        } else {
          flash.setMessageErrorForm();
          Sentry.captureException(error);
        }
      })
      .finally(() => {
        setOnSubmitLoading(false);
      });
  };

  /* Render
   *******************************************************************************************/
  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm={3}>
          External ID <span className="text-danger">*</span>
        </Form.Label>
        <Col sm={9}>
          <Form.Control
            type="text"
            {...register("cleveronCompanyId", { required: true })}
          />
          {messageErrorInput ? (
            <small className="text-danger">
              {getMessageErrorSubmit(messageErrorInput, "cleveronCompanyId")}
            </small>
          ) : (
            <Form.Text className="text-muted">
              Fait référence à l'external ID de la société créé dans Clever
              Office
            </Form.Text>
          )}
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm={3}>
          Order Event Url<span className="text-danger">*</span>
        </Form.Label>
        <Col sm={9}>
          <Form.Control
            type="url"
            {...register("orderEventUrl", { required: true })}
          />
          {messageErrorInput ? (
            <small className="text-danger">
              {getMessageErrorSubmit(messageErrorInput, "orderEventUrl")}
            </small>
          ) : (
            <Form.Text className="text-muted">
              Url qui servira à mettre à jour les états des commandes
            </Form.Text>
          )}
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm={3}>
          Nom société <span className="text-danger">*</span>
        </Form.Label>
        <Col sm={9}>
          <Form.Control type="text" {...register("name", { required: true })} />
          {messageErrorInput && (
            <small className="text-danger">
              {getMessageErrorSubmit(messageErrorInput, "name")}
            </small>
          )}
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm={3}>
          Organisation <span className="text-danger">*</span>
        </Form.Label>
        <Col sm={9}>
          <Form.Control
            type="text"
            {...register("contactName", { required: true })}
          />
          {messageErrorInput ? (
            <small className="text-danger">
              {getMessageErrorSubmit(messageErrorInput, "contactName")}
            </small>
          ) : (
            <Form.Text className="text-muted">
              Organisation, service ou nom du client
            </Form.Text>
          )}
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm={3}>
          Contact email <span className="text-danger">*</span>
        </Form.Label>
        <Col sm={9}>
          <Form.Control
            type="email"
            {...register("contactEmail", { required: true })}
          />
          {messageErrorInput && (
            <small className="text-danger">
              {getMessageErrorSubmit(messageErrorInput, "contactEmail")}
            </small>
          )}
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm={3}>
          Téléphone <span className="text-danger">*</span>
        </Form.Label>
        <Col sm={9}>
          <Form.Control
            type="number"
            {...register("contactPhone", { required: true })}
          />
          {messageErrorInput && (
            <small className="text-danger">
              {getMessageErrorSubmit(messageErrorInput, "contactPhone")}
            </small>
          )}
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3">
        <Col sm={{ span: 9, offset: 3 }}>
          <hr />
          <span className="text-muted d-block mb-2">
            Options de notification :
          </span>
          <div className="form-check">
            <input
              type="checkbox"
              className="form-check-input"
              id="smsProviderActive"
              {...register("smsProviderActive")}
            />
            <label className="form-check-label" htmlFor="smsProviderActive">
              SMS
            </label>
          </div>
          <div className="form-check">
            <input
              type="checkbox"
              className="form-check-input"
              id="mailProviderActive"
              {...register("mailProviderActive")}
            />
            <label className="form-check-label" htmlFor="mailProviderActive">
              Email
            </label>
          </div>
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3 float-end">
        <Col>
          <Button type="submit" variant="dark">
            {onSubmitLoading ? <Spinner size={"sm"} /> : "Enregistrer"}
          </Button>
        </Col>
      </Form.Group>
    </Form>
  );
};
