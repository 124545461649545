import AuthService from "@/services/Auth/AuthService";
import CompanyService from "@/services/Company/CompanyService";
import { CompanyType } from "@/types/CompanyType";
import { flashStore } from "@/utils/Store";
import { AxiosError, AxiosResponse } from "axios";
import React from "react";
import {
  Badge,
  Button,
  Card,
  Col,
  Form,
  Image,
  Modal,
  Row,
  Spinner,
} from "react-bootstrap";
import * as Sentry from "@sentry/react";
import { setDate } from "@/utils/Functions";
import { Loading } from "@/components/lib/Loading";
import { UserDetailCard } from "../user/UserDetailCard";
import UserService from "@/services/User/UserService";
import { UserType } from "@/types/UserType";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { Edit, Plus } from "lucide-react";
import { useUserRole } from "@/hooks/useUserRole";

type Props = {
  id_company: string | undefined;
};

export const CompanyDetailCard: React.FC<Props> = ({ id_company }: Props) => {
  /* States / Hooks
   *******************************************************************************************/
  const [data, setData] = React.useState<CompanyType>();
  const [users, setUsers] = React.useState<UserType[]>([]);
  const [owner, setOwner] = React.useState<number>(0);
  const [showModalOwner, setShowModalOwner] = React.useState<boolean>(false);
  const [onSubmitLoading, setOnSubmitLoading] = React.useState<boolean>(false);
  const { register, handleSubmit } = useForm<UserType>();
  const flash = flashStore();
  const myRole = useUserRole();

  /* useEffect
   *******************************************************************************************/
  React.useEffect(() => {
    if (id_company) {
      getData(id_company);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id_company, owner]);

  /* Functions / Events
   *******************************************************************************************/
  const getData = (id_company: string) => {
    CompanyService.getCompanyById(Number(id_company))
      .then((response: AxiosResponse) => {
        setData(response.data);
        if (response.data.owner) {
          const uri_owner = response.data.owner;
          const id_owner = uri_owner.substring(uri_owner.lastIndexOf("/") + 1);
          setOwner(id_owner);
        } else {
          setOwner(0);
        }
      })
      .catch((error: AxiosError) => {
        AuthService.refreshToken();
        flash.setMessageErrorFetch();
        Sentry.captureException(error);
      });
  };

  const getUser = () => {
    UserService.getAllUser()
      .then((response: AxiosResponse) => {
        setUsers(response.data["hydra:member"]);
      })
      .catch((error: AxiosError) => {
        AuthService.refreshToken();
        flash.setMessageErrorFetch();
        Sentry.captureException(error);
      });
  };

  const onSubmitAddOwner = (data: UserType) => {
    setOnSubmitLoading(true);
    const new_uri_owner: string = "api/users/" + data.id;
    CompanyService.addOwner(Number(id_company), {
      owner: new_uri_owner,
    })
      .then(() => {
        flash.setMessage("success", "Proriétaire ajouté !");
        setOwner(data.id);
        handleCloseModalOwner();
      })
      .catch((error: AxiosError) => {
        flash.setMessageErrorForm();
        Sentry.captureException(error);
      })
      .finally(() => {
        setOnSubmitLoading(false);
      });
  };

  const handleCloseModalOwner = () => setShowModalOwner(false);
  const handleShowModalOwner = () => {
    getUser();
    setShowModalOwner(true);
  };

  return (
    <>
      {/* Modal */}
      {myRole === import.meta.env.VITE_APP_ROLE_SUPER_ADMIN &&
        (!data ||
          (owner === 0 && (
            <>
              <Button
                variant="dark"
                className="w-100 mb-3"
                onClick={handleShowModalOwner}
              >
                Ajouter un propriétaire
              </Button>

              <Modal show={showModalOwner} onHide={handleCloseModalOwner}>
                <Form onSubmit={handleSubmit(onSubmitAddOwner)}>
                  <Modal.Header closeButton>
                    <Modal.Title>Ajouter un propriétaire</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Form.Group as={Row} className="mb-3">
                      <Col>
                        <Form.Select
                          aria-label="Rôle de l'utilisateur"
                          className="form-control"
                          {...register("id", { required: true })}
                        >
                          <option>
                            -- Sélectionnez un utilisateur existant --
                          </option>
                          {users?.map((user: UserType) => (
                            <option value={user.id}>
                              {user.firstName + " " + user.lastName}
                            </option>
                          ))}
                        </Form.Select>
                      </Col>
                    </Form.Group>
                  </Modal.Body>
                  <Modal.Footer>
                    <Link
                      className="float-end btn-sm btn btn-outline-dark"
                      to={`/societes/${id_company}/utilisateur/creation`}
                    >
                      <Plus size={20} /> Nouveau propriétaire
                    </Link>
                    <Button type="submit" variant="success">
                      {onSubmitLoading ? (
                        <Spinner size={"sm"} />
                      ) : (
                        "Enregistrer"
                      )}
                    </Button>
                  </Modal.Footer>
                </Form>
              </Modal>
            </>
          )))}
      {/* Content */}
      <Card className="py-3">
        <Card.Body>
          {!data ? (
            <Loading />
          ) : (
            <>
              <div className="text-center">
                <Image
                  src={"/img/image-dummy.png"}
                  alt="Image"
                  className="img-fluid mb-3"
                  width={100}
                />
              </div>
              <div className="text-center mb-4">
                <Card.Title>{data?.name}</Card.Title>
                <Badge bg="primary pt-2 pb-1 mb-3">
                  Ref : {data?.cleveronCompanyId}
                </Badge>
                <br />
                <small className="text-muted">
                  Date création : {setDate(data?.createdAt)}
                </small>
              </div>
              <hr />
              <p>
                Nom :<span className="float-end">{data?.contactName}</span>
              </p>
              <p>
                Email :<span className="float-end">{data?.contactEmail}</span>
              </p>
              <p>
                Tél :<span className="float-end">{data?.contactPhone}</span>
              </p>
              <hr />
              <p>
                Option Email :
                <span className="float-end">
                  {data?.mailProviderActive ? "Activé" : "Désactivé"}
                </span>
              </p>
              <p>
                Option Sms :
                <span className="float-end">
                  {data?.smsProviderActive ? "Activé" : "Désactivé"}
                </span>
              </p>
              <hr />
              <Link
                className="btn btn-sm btn-dark d-block"
                to={"/societes/modification/" + data?.id}
              >
                <Edit className="me-2" size={20} />
                Modifier les informations
              </Link>
            </>
          )}
        </Card.Body>
      </Card>
      <br />
      {owner !== 0 && <UserDetailCard id_user={owner} owner />}
    </>
  );
};
