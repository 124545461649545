import { Row, Col } from 'react-bootstrap'
import { Title } from '@/components/lib/Title'
import { ButtonLink } from '@/components/lib/ButtonLink'
import { UserListTable } from '@/components/entities/user/UserListTable'
import { useUserRole } from '@/hooks/useUserRole'

export const UserList = () => {
  const myRole = useUserRole()
  /* Render
   *******************************************************************************************/
  return (
    <>
      <Row>
        <Col>
          <Title name={'Utilisateurs'} />
        </Col>
        <Col>
          {myRole === import.meta.env.VITE_APP_ROLE_SUPER_ADMIN && (
            <ButtonLink
              name={'Nouvel utilisateur'}
              href={'/utilisateurs/creation'}
              icon={'Plus'}
            />
          )}
        </Col>
      </Row>
      <Row className='mt-4'>
        <Col>
          <UserListTable />
        </Col>
      </Row>
    </>
  )
}
