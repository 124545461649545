import { Card, Col, Row } from "react-bootstrap";
import { Title } from "@/components/lib/Title";
import { ButtonLinkBack } from "@/components/lib/ButtonLinkBack";
import { UserUpdatePassword } from "@/components/entities/user/UserUpdatePassword";

export const UserUpdatePass = () => {
  /* Render
   *******************************************************************************************/
  return (
    <>
      <Row>
        <Col>
          <Title name={"Utilisateur / mot de passe"} />
        </Col>
        <Col>
          <ButtonLinkBack />
        </Col>
      </Row>
      <Row className="mt-4">
        <Col className="col-xl-6 col-lg-10 col-md-12 mx-auto">
          <Card className="mb-4">
            <Card.Header>
              <h5 className="mt-2">Modifier le mot de passe</h5>
            </Card.Header>
            <Card.Body className="p-5">
              <UserUpdatePassword />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};
