import { EmptyData } from '@/components/lib/EmptyData'
import { Loading } from '@/components/lib/Loading'
import AuthService from '@/services/Auth/AuthService'
import CompanyService from '@/services/Company/CompanyService'
import OrderService from '@/services/Order/OrderService'
import { CompanyType } from '@/types/CompanyType'
import { OrderType } from '@/types/OrderType'
import { setDateTime, setOrderStatus } from '@/utils/Functions'
import { flashStore } from '@/utils/Store'
import * as Sentry from '@sentry/react'
import { AxiosError, AxiosResponse } from 'axios'
import { Plus } from 'lucide-react'
import React from 'react'
import { Button, Card, Col, Form, Row, Table } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Swal, { SweetAlertResult } from 'sweetalert2'

interface ApiResponse {
  response: AxiosResponse
  violations: Violation[]
}

interface Violation {
  propertyPath: string
  message: string
  code: string
}

export const OrderListTable = () => {
  /* States / Hooks
   *******************************************************************************************/
  const [data, setData] = React.useState<OrderType[]>([])
  const [companies, setCompanies] = React.useState<CompanyType[]>([])
  const [loading, setLoading] = React.useState<boolean>(true)
  const [nextLoading, setNextLoading] = React.useState<boolean>(false)
  const [nextPage, setNextPage] = React.useState<string | undefined>()
  const [currentPage] = React.useState<number>(1)
  const [perPage] = React.useState<number>(10)
  const flash = flashStore()

  /* useEffect
   *******************************************************************************************/
  React.useEffect(() => {
    getData(currentPage, perPage)
    getCompanies()
  }, [currentPage, perPage])

  /* Functions
   *******************************************************************************************/
  const getData = (page: number, perPage: number) => {
    setLoading(true)
    OrderService.getOrdersPerPage(page, perPage)
      .then((response: AxiosResponse) => {
        setData(response.data['hydra:member'])
        setNextPage(response.data['hydra:view']['hydra:next'])
      })
      .catch((error: AxiosError) => {
        AuthService.refreshToken()
        Sentry.captureException(error)
      })
      .finally(() => setLoading(false))
  }

  const getCompanies = () => {
    CompanyService.getAllCompany()
      .then((response: AxiosResponse) => {
        setCompanies(response.data['hydra:member'])
      })
      .catch((error: AxiosError) => {
        AuthService.refreshToken()
        Sentry.captureException(error)
      })
  }

  const getDataWithFilterStatus = (status: string) => {
    setLoading(true)
    OrderService.getOrdersByStatusPerPage(status, currentPage, perPage)
      .then((response: AxiosResponse) => {
        setData(response.data['hydra:member'])
        setNextPage(response.data['hydra:view']['hydra:next'])
      })
      .catch((error: AxiosError) => {
        AuthService.refreshToken()
        Sentry.captureException(error)
      })
      .finally(() => setLoading(false))
  }

  const getDataWithFilterCompany = (status: string) => {
    setLoading(true)
    OrderService.getOrdersByCompanyPerPage(status, currentPage, perPage)
      .then((response: AxiosResponse) => {
        setData(response.data['hydra:member'])
        setNextPage(response.data['hydra:view']['hydra:next'])
      })
      .catch((error: AxiosError) => {
        AuthService.refreshToken()
        Sentry.captureException(error)
      })
      .finally(() => setLoading(false))
  }

  const handleUpdateStatus = (id_order: number, status: string) => {
    Swal.fire({
      title: 'Confirmation !',
      text: 'Voulez-vous vraiment modifier le statut de la commande #' + id_order + ' ?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Oui, je confirme',
      cancelButtonText: 'Non',
    }).then((result: SweetAlertResult) => {
      if (result.isConfirmed) {
        OrderService.updateStatus(id_order, status)
          .then(() => {
            flash.setMessage('success', 'Commande #' + id_order + ' modifiée !')
            getData(currentPage, perPage)
          })
          .catch((error: ApiResponse) => {
            flash.setMessageErrorForm()
            Sentry.captureException(error)
          })
      }
    })
  }

  const handleNextPage = () => {
    setNextLoading(true)
    OrderService.getOrderLoadNextPage(nextPage)
      .then((response: AxiosResponse) => {
        setData(data.concat(response.data['hydra:member']))
        setNextPage(response.data['hydra:view']['hydra:next'])
      })
      .catch((error: AxiosError) => {
        AuthService.refreshToken()
        Sentry.captureException(error)
      })
      .finally(() => setNextLoading(false))
  }

  const handleChangeStatus = (value: string) => {
    if (value === 'refresh') {
      getData(currentPage, perPage)
    } else {
      getDataWithFilterStatus(value)
    }
  }

  const handleDelete = (id: number) => {
    Swal.fire({
      title: 'Confirmation !',
      text: 'Voulez-vous vraiment supprimer la commande #' + id + ' ?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Oui, je confirme',
      cancelButtonText: 'Non',
    }).then((result: SweetAlertResult) => {
      if (result.isConfirmed) {
        OrderService._delete(id)
          .then(() => {
            flash.setMessage('success', 'Commande supprimée !')
            getData(currentPage, perPage)
          })
          .catch((e: ApiResponse) => {
            if (e?.response?.status == 403) {
              flash.setMessage('error', e?.response?.data['hydra:description'])
            }
            Sentry.captureException(e)
          })
      }
    })
  }

  const handleSendNotification = (id_order: number, key: string) => {
    let nameKey: string = "l'email"
    if (key === 'sms') {
      nameKey = 'le sms'
    }

    Swal.fire({
      title: 'Confirmation !',
      text: 'Voulez-vous vraiment renvoyer ' + nameKey + ' de la commande #' + id_order + ' ?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Oui, je confirme',
      cancelButtonText: 'Non',
    }).then((result: SweetAlertResult) => {
      if (result.isConfirmed) {
        if (key === 'sms') {
          sendNotificationOrder(id_order, 'sms')
        } else {
          sendNotificationOrder(id_order, 'mail')
        }
      }
    })
  }

  const sendNotificationOrder = (id_order: number, $provider: string) => {
    OrderService.resendNotification(id_order, $provider)
      .then(() => {
        flash.setMessage('success', 'Votre demande a bien été effectuée !')
      })
      .catch((error: ApiResponse) => {
        flash.setMessageErrorForm()
        Sentry.captureException(error)
      })
  }

  /* Render
   *******************************************************************************************/
  return (
    <>
      {/* Filter component */}
      <Row>
        <Col className='col-3 bg-transparent mb-3'>
          <Form.Select
            aria-label='Default select example'
            onChange={(e) => getDataWithFilterCompany(e.target.value)}
          >
            <option value='refresh'>-- Sociétés --</option>
            {companies?.map((company: CompanyType) => (
              <option key={company.id} value={company.cleveronCompanyId}>
                {company.name}
              </option>
            ))}
            <option value='refresh'>Tout afficher</option>
          </Form.Select>
        </Col>
        <Col className='col-3 bg-transparent mb-3'>
          <Form.Select
            aria-label='Default select example'
            onChange={(e) => handleChangeStatus(e.target.value)}
          >
            <option value='refresh'>-- Statut de la commande --</option>
            <option value='cancel'>Commande annulée</option>
            <option value='created'>Commande créée</option>
            <option value='ready_for_delivery'>Commande prête pour la livraison</option>
            <option value='picked_up'>Commande en cours de livraison</option>
            <option value='operin'>Commande déposée</option>
            <option value='receive'>Commande récupérée par le client</option>
            <option value='operout'>Commande récupérée par le coursier</option>
            <option value='reminder'>Commande non récupérée depuis un certain temps</option>
            <option value='overtimedue'>Commande proche de l'expiration</option>
            <option value='overtime'>Commande expirée</option>
            <option value='left_for_customer_service'>
              Commande retournée au service client
            </option>
            <option value='return'>Retour de la commande par le client</option>
            <option value='refresh'>Tout afficher</option>
          </Form.Select>
        </Col>
      </Row>
      <Card>
        <>
          {loading ? (
            <Loading />
          ) : data?.length === 0 ? (
            <EmptyData />
          ) : (
            <>
              {/* List Table */}
              <Table striped hover>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Société</th>
                    <th>Machine</th>
                    <th>Barcode</th>
                    <th>Id Slot</th>
                    <th>Statut</th>
                    <th>Date de création</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((order: OrderType) => (
                    <tr key={order?.id}>
                      <td>
                        <Link to={'/livraisons/detail/' + order?.id}>{order?.id}</Link>
                      </td>
                      <td>
                        <Link to={'/livraisons/detail/' + order?.id}>
                          {order?.company?.name}
                        </Link>
                      </td>
                      <td>
                        <Link to={'/livraisons/detail/' + order?.id}>
                          {order?.locker?.location}
                          <br />
                          <small className='text-muted'>{order?.locker?.shortLocation}</small>
                        </Link>
                      </td>
                      <td>
                        <Link to={'/livraisons/detail/' + order?.id}>{order?.barcode}</Link>
                      </td>
                      <td>
                        {order?.status === 'operin' ||
                        order?.status === 'reminder' ||
                        order?.status === 'overtimedue' ||
                        order?.status === 'overtime' ? (
                          <Link to={'/livraisons/detail/' + order?.id}>
                            {order?.cleveronSlotId ? order?.cleveronSlotId : '-'}
                          </Link>
                        ) : (
                          '-'
                        )}
                      </td>
                      <td>
                        <Link to={'/livraisons/detail/' + order?.id}>
                          {setOrderStatus(order?.status)}
                        </Link>
                      </td>
                      <td>
                        <Link to={'/livraisons/detail/' + order?.id}>
                          {setDateTime(order?.createdAt)}
                        </Link>
                      </td>
                      <td>
                        <div className='dropdown no-arrow btn-actions'>
                          <button
                            className='btn btn-secondary dropdown-toggle '
                            type='button'
                            data-toggle='dropdown'
                            aria-expanded='false'
                          >
                            <i className='ri-more-2-fill ri-xl'></i>
                          </button>
                          <div className='dropdown-menu py-0'>
                            <Link
                              className='dropdown-item border-bottom py-3'
                              to={'/livraisons/detail/' + order?.id}
                            >
                              <i className='ri-list-check-2 ri-lg mr-2 align-middle'></i>
                              Afficher le détail
                            </Link>
                            {order?.status === 'picked_up' ? (
                              <>
                                <span
                                  className='dropdown-item border-bottom py-3 cursor-pointer'
                                  onClick={() =>
                                    handleUpdateStatus(
                                      Number(order?.id),
                                      'left_for_customer_service'
                                    )
                                  }
                                >
                                  <i className='ri-arrow-go-back-line ri-lg mr-2 align-middle'></i>
                                  Commande retournée au service client
                                </span>
                                <span
                                  className='dropdown-item border-bottom py-3 cursor-pointer'
                                  onClick={() =>
                                    handleUpdateStatus(Number(order?.id), 'operin')
                                  }
                                >
                                  <i className='ri-inbox-archive-line ri-lg mr-2 align-middle'></i>
                                  Commande déposée dans la EasyBox
                                </span>
                                <span
                                  className='dropdown-item border-bottom py-3 cursor-pointer'
                                  onClick={() =>
                                    handleUpdateStatus(Number(order?.id), 'cancel')
                                  }
                                >
                                  <i className='ri-close-circle-line ri-lg mr-2 align-middle'></i>
                                  Annuler cette commande
                                </span>
                              </>
                            ) : null}
                            {order?.status === 'ready_for_delivery' ? (
                              <span
                                className='dropdown-item border-bottom py-3 cursor-pointer'
                                onClick={() =>
                                  handleUpdateStatus(Number(order?.id), 'picked_up')
                                }
                              >
                                <i className='ri-truck-line ri-lg mr-2 align-middle'></i>
                                Commande en cours de livraison
                              </span>
                            ) : null}
                            {order?.status === 'created' && (
                              <span
                                className='dropdown-item border-bottom py-3 cursor-pointer'
                                onClick={() =>
                                  handleUpdateStatus(Number(order?.id), 'ready_for_delivery')
                                }
                              >
                                <i className='ri-inbox-unarchive-line ri-lg mr-2 align-middle'></i>
                                Commande prête pour la livraison
                              </span>
                            )}
                            {order?.status === 'operout' && (
                              <>
                                <span
                                  className='dropdown-item border-bottom py-3 cursor-pointer'
                                  onClick={() =>
                                    handleUpdateStatus(
                                      Number(order?.id),
                                      'left_for_customer_service'
                                    )
                                  }
                                >
                                  <i className='ri-arrow-go-back-line ri-lg mr-2 align-middle'></i>
                                  Commande retournée au service client
                                </span>
                                <span
                                  className='dropdown-item border-bottom py-3 cursor-pointer'
                                  onClick={() =>
                                    handleUpdateStatus(Number(order?.id), 'cancel')
                                  }
                                >
                                  <i className='ri-close-circle-line ri-lg mr-2 align-middle'></i>
                                  Annuler cette commande
                                </span>
                              </>
                            )}
                            {order?.status === 'operin' && (
                              <>
                                <span
                                  className='dropdown-item border-bottom py-3 cursor-pointer'
                                  onClick={() =>
                                    handleSendNotification(Number(order?.id), 'sms')
                                  }
                                >
                                  <i className='ri-message-2-line ri-lg mr-2 align-middle'></i>
                                  Renvoyer le SMS
                                </span>
                                <span
                                  className='dropdown-item border-bottom py-3 cursor-pointer'
                                  onClick={() =>
                                    handleSendNotification(Number(order?.id), 'mail')
                                  }
                                >
                                  <i className='ri-mail-send-line ri-lg mr-2 align-middle'></i>
                                  Renvoyer l'email
                                </span>
                              </>
                            )}
                            {order?.status === 'operin' ||
                            order?.status === 'reminder' ||
                            order?.status === 'overtimedue' ||
                            order?.status === 'left_for_customer_service' ||
                            order?.status === 'overtime' ? (
                              <span
                                className='dropdown-item border-bottom py-3 cursor-pointer'
                                onClick={() =>
                                  handleUpdateStatus(Number(order?.id), 'receive')
                                }
                              >
                                <i className='ri-checkbox-line ri-lg mr-2 align-middle'></i>
                                Commande récupérée par le client
                              </span>
                            ) : null}
                            {order?.status === 'operin' ||
                            order?.status === 'reminder' ||
                            order?.status === 'overtimedue' ||
                            order?.status === 'overtime' ? (
                              <span
                                className='dropdown-item border-bottom py-3 cursor-pointer'
                                onClick={() =>
                                  handleUpdateStatus(Number(order?.id), 'operout')
                                }
                              >
                                <i className='ri-logout-box-line ri-lg mr-2 align-middle'></i>
                                Commande récupérée par le coursier
                              </span>
                            ) : null}
                            {order?.status === 'created' ||
                            order?.status === 'left_for_customer_service' ||
                            order?.status === 'ready_for_delivery' ? (
                              <span
                                className='dropdown-item border-bottom py-3 cursor-pointer'
                                onClick={() => handleUpdateStatus(Number(order?.id), 'cancel')}
                              >
                                <i className='ri-close-circle-line ri-lg mr-2 align-middle'></i>
                                Annuler cette commande
                              </span>
                            ) : (
                              order?.status === 'cancel' && (
                                <span
                                  className='dropdown-item border-bottom py-3 cursor-pointer'
                                  onClick={() => handleDelete(Number(order?.id))}
                                >
                                  <i className='ri-delete-bin-2-line ri-lg mr-2 align-middle'></i>
                                  Supprimer cette commande
                                </span>
                              )
                            )}
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              {nextPage !== undefined && (
                <div className='ms-auto mb-3 me-3'>
                  <Button variant='dark' className='d-inline w-20' onClick={handleNextPage}>
                    {nextLoading ? (
                      'En cours de chargement...'
                    ) : (
                      <>
                        <Plus size={20} /> Charger plus{' '}
                      </>
                    )}
                  </Button>
                </div>
              )}
            </>
          )}
        </>
      </Card>
    </>
  )
}
