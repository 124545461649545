import React from "react";
import { Modal } from "react-bootstrap";

export const FooterApp: React.FC = () => {
  /* States
   *******************************************************************************************/
  const [showModalML, setShowModalML] = React.useState(false);
  const [showModalPC, setShowModalPC] = React.useState(false);

  /* Functions
   *******************************************************************************************/
  const handleCloseML = () => setShowModalML(false);
  const handleShowML = () => setShowModalML(true);
  const handleClosePC = () => setShowModalPC(false);
  const handleShowPC = () => setShowModalPC(true);

  /* Render
   *******************************************************************************************/
  return (
    <>
      {/* Mentions légales */}
      <Modal size="lg" show={showModalML} onHide={handleCloseML}>
        <Modal.Header closeButton>
          <Modal.Title>Mention légales</Modal.Title>
        </Modal.Header>
        <Modal.Body>Contenu</Modal.Body>
      </Modal>
      {/* Politique de confidentialité */}
      <Modal size="lg" show={showModalPC} onHide={handleClosePC}>
        <Modal.Header closeButton>
          <Modal.Title>Politique de confidentialité</Modal.Title>
        </Modal.Header>
        <Modal.Body>Contenu</Modal.Body>
      </Modal>

      <footer className="sticky-footer bg-white">
        <div className="container my-auto">
          <div className="copyright text-center my-auto text-muted">
            <small
              className="text-gray-500 cursor-pointer"
              onClick={handleShowML}
            >
              Mentions légales
            </small>{" "}
            <span className="text-gray-500">|</span>{" "}
            <small
              className="text-gray-500 cursor-pointer"
              onClick={handleShowPC}
            >
              Politique de confidentialité
            </small>
            <br />
            <br />
            <small>
              Copyright &copy; {import.meta.env.VITE_APP_CREDIT} 2023 - Version{" "}
              {import.meta.env.VITE_APP_VERSION}
            </small>
          </div>
        </div>
      </footer>
    </>
  );
};
