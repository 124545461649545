import { Edit, Plus } from "lucide-react";
import { Link } from "react-router-dom";

type Props = {
  name: string;
  href: string;
  icon: string;
};

export const ButtonLink: React.FC<Props> = ({ name, href, icon }) => {
  return (
    <Link className="float-end btn-sm btn btn-success" to={href}>
      {icon === "Plus" && (
        <>
          <Plus size={20} />
          {name}
        </>
      )}
      {icon === "Edit" && (
        <>
          <Edit size={20} className="me-2" />
          {name}
        </>
      )}
    </Link>
  );
};
