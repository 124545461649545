import { Loading } from '@/components/lib/Loading'
import * as Sentry from '@sentry/react'
import { AxiosError, AxiosResponse } from 'axios'
import { Check, Plus, X } from 'lucide-react'
import React from 'react'
import { Button, Card, Col, Row, Table } from 'react-bootstrap'
import { Link } from 'react-router-dom'

import { EmptyData } from '@/components/lib/EmptyData'
import AuthService from '@/services/Auth/AuthService'
import CompanyService from '@/services/Company/CompanyService'
import { BookingSlotType } from '@/types/BookingSlotType'

import { useUserRole } from '@/hooks/useUserRole'

type Props = {
  id_company: string | undefined
}

export const CompanyBookingSlotListTable: React.FC<Props> = ({ id_company }: Props) => {
  /* States / Hooks
   *******************************************************************************************/
  const [data, setData] = React.useState<BookingSlotType[]>([])
  const [loading, setLoading] = React.useState<boolean>(true)
  const myRole = useUserRole()
  const [nextLoading, setNextLoading] = React.useState<boolean>(false)
  const [nextPage, setNextPage] = React.useState<string | undefined>()
  const [currentPage] = React.useState<number>(1)
  const [perPage] = React.useState<number>(20)

  /* useEffect
   *******************************************************************************************/
  React.useEffect(() => {
    getData(Number(id_company), currentPage, perPage)
  }, [id_company, currentPage, perPage])

  /* Functions
   *******************************************************************************************/
  const getData = (id_company: number, page: number, perPage: number) => {
    CompanyService.getBookingsByCompanyId(id_company, page, perPage)
      .then((response: AxiosResponse) => {
        setData(response.data['hydra:member'])
        setNextPage(response.data['hydra:view']['hydra:next'])
      })
      .catch((error: AxiosError) => {
        AuthService.refreshToken()
        Sentry.captureException(error)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  // Todo
  const handleNextPage = () => {
    setNextLoading(true)
    CompanyService.getCompanyLoadNextPage(nextPage)
      .then((response: AxiosResponse) => {
        setData(data.concat(response.data['hydra:member']))
        setNextPage(response.data['hydra:view']['hydra:next'])
      })
      .catch((error: AxiosError) => {
        AuthService.refreshToken()
        Sentry.captureException(error)
      })
      .finally(() => setNextLoading(false))
  }

  /* Render
   *******************************************************************************************/
  return (
    <Card>
      <Card.Header>
        <Row>
          <Col>
            <h5 className='mt-2'>Bookings</h5>
          </Col>
          {myRole === import.meta.env.VITE_APP_ROLE_SUPER_ADMIN && (
            <Col className='text-end'>
              <Link
                className='float-end btn-sm btn btn-outline-info'
                to={`/societes/${id_company}/booking/creation`}
              >
                <Plus size={20} /> Nouvelle réservation
              </Link>
            </Col>
          )}
        </Row>
      </Card.Header>
      <Card.Body>
        {loading ? (
          <Loading />
        ) : data?.length === 0 ? (
          <EmptyData />
        ) : (
          <>
            <Table striped hover>
              <thead>
                <tr>
                  <th>Machine</th>
                  <th>Nb. casiers réservés</th>
                  <th>Nb. casiers disponibles</th>
                  <th>Statut</th>
                </tr>
              </thead>
              <tbody>
                {data.map((booking: BookingSlotType) => (
                  <tr key={booking?.id}>
                    <td>
                      {booking?.slot?.temperatureZone?.locker?.shortLocation}
                      <br />
                      {booking?.slot?.temperatureZone?.name}
                      <br />H {booking?.slot?.height} x L {booking?.slot?.width} x P{' '}
                      {booking?.slot?.depth} cm
                    </td>
                    <td>{booking.capacity}</td>
                    <td>{booking.available}</td>
                    <td>
                      {booking.active ? (
                        <Check className='text-success' />
                      ) : (
                        <X className='text-danger' />
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            {nextPage !== undefined && (
              <div className='ms-auto mb-3 me-3'>
                <Button variant='dark' className='d-inline w-20' onClick={handleNextPage}>
                  {nextLoading ? (
                    'En cours de chargement...'
                  ) : (
                    <>
                      <Plus size={20} /> Charger plus{' '}
                    </>
                  )}
                </Button>
              </div>
            )}
          </>
        )}
      </Card.Body>
    </Card>
  )
}
