import React, { ChangeEvent } from "react";
import { Button, Col, Row, Form, Spinner } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { UserType } from "@/types/UserType";
import { flashStore } from "@/utils/Store";
import UserService from "@/services/User/UserService";
import { AxiosError, AxiosResponse } from "axios";
import * as Sentry from "@sentry/react";
import { getMessageErrorSubmit } from "@/utils/Functions";
import CompanyService from "@/services/Company/CompanyService";
import AuthService from "@/services/Auth/AuthService";
import { CompanyType } from "@/types/CompanyType";
import { Eye, EyeOff } from "lucide-react";

interface ApiResponse {
  response: AxiosResponse;
  violations: Violation[];
}

interface Violation {
  propertyPath: string;
  message: string;
  code: string;
}

export const UserAddForm: React.FC = () => {
  /* States / Hooks
   *******************************************************************************************/
  const [onSubmitLoading, setOnSubmitLoading] = React.useState<boolean>(false);
  const [company, setCompany] = React.useState<CompanyType>();
  const [messageErrorInput, setMessageErrorInput] = React.useState();
  const [passVisible, setPassVisible] = React.useState<boolean>(false);
  const [inputApmAccessCode, setInputApmAccessCode] =
    React.useState<boolean>(false);
  const navigate = useNavigate();
  const flash = flashStore();
  const { register, handleSubmit, watch } = useForm<UserType>();
  const apmAccessCode = watch("apmAccessCode");
  const param = useParams();

  /* UseEffect
   *******************************************************************************************/
  React.useEffect(() => {
    if (param.id_company) {
      getCompany(Number(param.id_company));
    }
  }, [param]);

  /* Functions / Events
   *******************************************************************************************/
  const getCompany = (company_id: number) => {
    CompanyService.getCompanyById(company_id)
      .then((response: AxiosResponse) => {
        setCompany(response.data);
      })
      .catch((error: AxiosError) => {
        AuthService.refreshToken();
        Sentry.captureException(error);
      });
  };

  const handleTogglePassVisible = () => {
    if (passVisible) {
      setPassVisible(false);
    } else {
      setPassVisible(true);
    }
  };

  const onSubmit = (data: UserType) => {
    setOnSubmitLoading(true);
    // Formatter les données
    let owner: boolean = false;
    data["apmAccessCode"] = 0;

    if (data["roles"].includes("OWNER")) {
      owner = true;
      data["roles"] = ["ROLE_ADMIN"];
    } else if (data["roles"].includes("ROLE_SHIPPER")) {
      data["apmAccessCode"] = Number(apmAccessCode);
      data["roles"] = Array.isArray(data.roles) ? data.roles : [data.roles];
    } else if (data["roles"].includes("ROLE_TECH")) {
      data["apmAccessCode"] = Number(apmAccessCode);
      data["roles"] = Array.isArray(data.roles) ? data.roles : [data.roles];
    } else {
      data["roles"] = Array.isArray(data.roles) ? data.roles : [data.roles];
    }

    UserService.create(data)
      .then((response: AxiosResponse) => {
        if (param.id_company) {
          if (owner) {
            addOwner(response?.data?.id);
            return;
          } else {
            addMember(response?.data?.id);
          }
        } else {
          flash.setMessage("success", "Utilisateur créé !");
          navigate(-1);
        }
      })
      .catch((error: ApiResponse) => {
        if (error?.response?.data?.violations?.length > 0) {
          flash.setMessageErrorValidator();
          setMessageErrorInput(error.response.data.violations);
        } else {
          flash.setMessageErrorForm();
          Sentry.captureException(error);
        }
      })
      .finally(() => {
        setOnSubmitLoading(false);
      });
  };

  const addOwner = (id_user: number) => {
    const new_uri_owner: string = "api/users/" + id_user;

    CompanyService.addOwner(Number(param.id_company), {
      owner: new_uri_owner,
    })
      .then(() => {
        flash.setMessage("success", "Utilisateur créé !");
        navigate(-1);
      })
      .catch((error: ApiResponse) => {
        flash.setMessageErrorForm();
        Sentry.captureException(error);
      })
      .finally(() => {
        setOnSubmitLoading(false);
      });
  };

  const addMember = (id_user: number) => {
    const new_uri_member: string = "/api/users/" + id_user;
    const new_members: string[] = [...(company?.members ?? []), new_uri_member];

    CompanyService.addMember(Number(param.id_company), {
      members: new_members,
    })
      .then(() => {
        flash.setMessage("success", "Utilisateur créé !");
        navigate(-1);
      })
      .catch((error: ApiResponse) => {
        flash.setMessageErrorForm();
        Sentry.captureException(error);
      })
      .finally(() => {
        setOnSubmitLoading(false);
      });
  };

  const handleSelectChange = (selectedValue: string) => {
    if (
      selectedValue == import.meta.env.VITE_APP_ROLE_SHIPPER ||
      selectedValue == import.meta.env.VITE_APP_ROLE_TECH
    ) {
      setInputApmAccessCode(true);
    } else {
      setInputApmAccessCode(false);
    }
  };

  /* Render
   *******************************************************************************************/
  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      {param.id_company && (
        <Form.Group as={Row} className="mb-3">
          <Form.Label column sm={3}>
            Société
          </Form.Label>
          <Col sm={9}>
            <Form.Control type="text" disabled defaultValue={company?.name} />
          </Col>
        </Form.Group>
      )}
      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm={3}>
          Nom <span className="text-danger">*</span>
        </Form.Label>
        <Col sm={9}>
          <Form.Control
            type="text"
            {...register("lastName", { required: true })}
          />
          {messageErrorInput && (
            <small className="text-danger">
              {getMessageErrorSubmit(messageErrorInput, "lastName")}
            </small>
          )}
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm={3}>
          Prénom <span className="text-danger">*</span>
        </Form.Label>
        <Col sm={9}>
          <Form.Control
            type="text"
            {...register("firstName", { required: true })}
          />
          {messageErrorInput && (
            <small className="text-danger">
              {getMessageErrorSubmit(messageErrorInput, "firstName")}
            </small>
          )}
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm={3}>
          Adresse email <span className="text-danger">*</span>
        </Form.Label>
        <Col sm={9}>
          <Form.Control
            type="email"
            {...register("email", { required: true })}
          />
          {messageErrorInput && (
            <small className="text-danger">
              {getMessageErrorSubmit(messageErrorInput, "email")}
            </small>
          )}
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm={3}>
          N° portable <span className="text-danger">*</span>
        </Form.Label>
        <Col sm={9}>
          <Form.Control
            type="number"
            {...register("phone", { required: true })}
          />
          {messageErrorInput && (
            <small className="text-danger">
              {getMessageErrorSubmit(messageErrorInput, "phone")}
            </small>
          )}
        </Col>
      </Form.Group>
      <hr />
      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm={3}>
          Identifiant <span className="text-danger">*</span>
        </Form.Label>
        <Col sm={9}>
          <Form.Control
            type="text"
            {...register("username", { required: true })}
          />
          {messageErrorInput && (
            <small className="text-danger">
              {getMessageErrorSubmit(messageErrorInput, "username")}
            </small>
          )}
        </Col>
      </Form.Group>
      {import.meta.env.VITE_APP_ENV === "develop" && (
        <Form.Group as={Row} className="mb-3">
          <Form.Label column sm={3}>
            Mot de passe <span className="text-danger">*</span>
          </Form.Label>
          <Col sm={9}>
            <div className="input-group">
              <Form.Control
                type={passVisible ? "text" : "password"}
                {...register("plainPassword", { required: true })}
              />
              <span
                className="input-group-text cursor-pointer"
                onClick={handleTogglePassVisible}
              >
                {passVisible ? <Eye size={18} /> : <EyeOff size={18} />}
              </span>
            </div>
            {messageErrorInput ? (
              <small className="text-danger">
                {getMessageErrorSubmit(messageErrorInput, "plainPassword")}
              </small>
            ) : (
              <small className="text-muted">
                Doit comporter plus de 12 caractères avec au minimun 1 majuscule
                et 1 caractère spécial
              </small>
            )}
          </Col>
        </Form.Group>
      )}
      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm={3}>
          Rôle <span className="text-danger">*</span>
        </Form.Label>
        <Col sm={9}>
          <Form.Select
            aria-label="Rôle de l'utilisateur"
            className="form-control"
            {...register("roles", { required: true })}
            onChange={(e: ChangeEvent<HTMLSelectElement>) =>
              handleSelectChange(e.currentTarget.value)
            }
          >
            <option value="">-- Choisissez un rôle --</option>
            {param.id_company && <option value="OWNER">Propriétaire</option>}
            <option value={import.meta.env.VITE_APP_ROLE_SUPER_ADMIN}>
              Super administrateur
            </option>
            <option value={import.meta.env.VITE_APP_ROLE_ADMIN}>
              Administrateur
            </option>
            <option value={import.meta.env.VITE_APP_ROLE_MEMBER}>Membre</option>
            <option value={import.meta.env.VITE_APP_ROLE_TECH}>
              Opérateur
            </option>
            <option value={import.meta.env.VITE_APP_ROLE_SHIPPER}>
              Livreur
            </option>
          </Form.Select>
          {messageErrorInput && (
            <small className="text-danger">
              {getMessageErrorSubmit(messageErrorInput, "roles")}
            </small>
          )}
        </Col>
      </Form.Group>
      {inputApmAccessCode && (
        <Form.Group as={Row} className="mb-3">
          <Form.Label column sm={3}>
            Apm Access Code <span className="text-danger">*</span>
          </Form.Label>
          <Col sm={9}>
            <Form.Control
              type="text"
              {...register("apmAccessCode", { required: true })}
            />
            <small className="text-muted">
              Un code qui permet de s'authentifier auprès du Locker
            </small>
            {messageErrorInput && (
              <small className="text-danger">
                {getMessageErrorSubmit(messageErrorInput, "apmAccessCode")}
              </small>
            )}
          </Col>
        </Form.Group>
      )}
      <Form.Group as={Row} className="mb-3">
        <Col sm={{ span: 9, offset: 3 }}>
          <div className="form-check">
            <input
              type="checkbox"
              className="form-check-input"
              id="enabled"
              {...register("enabled")}
              defaultChecked={true}
            />
            <label className="form-check-label" htmlFor="enabled">
              Activer cet utilisateur
            </label>
          </div>
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3 float-end">
        <Col>
          <Button type="submit" variant="dark">
            {onSubmitLoading ? <Spinner size={"sm"} /> : "Enregistrer"}
          </Button>
        </Col>
      </Form.Group>
    </Form>
  );
};
