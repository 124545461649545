import { Loading } from '@/components/lib/Loading'
import AuthService from '@/services/Auth/AuthService'
import LockerService from '@/services/Locker/LockerService'
import { LockerType } from '@/types/LockerType'
import { getMessageErrorSubmit } from '@/utils/Functions'
import { flashStore } from '@/utils/Store'
import * as Sentry from '@sentry/react'
import { AxiosError, AxiosResponse } from 'axios'
import React from 'react'
import { Button, Card, Col, Form, Row, Spinner } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'

interface ApiResponse {
  response: AxiosResponse
  violations: Violation[]
}

interface Violation {
  propertyPath: string
  message: string
  code: string
}

export const LockerEditForm = () => {
  /* States / Hooks
   *******************************************************************************************/
  const [onSubmitLoading, setOnSubmitLoading] = React.useState<boolean>(false)
  const [messageErrorInput, setMessageErrorInput] = React.useState()
  const navigate = useNavigate()
  const flash = flashStore()
  const { register, handleSubmit } = useForm<LockerType>()
  const [data, setData] = React.useState<LockerType>()
  const param = useParams()

  /* useEffect
   *******************************************************************************************/
  React.useEffect(() => {
    getData(param.id_locker)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [param])

  /* Functions / Events
   *******************************************************************************************/
  const getData = (id: string | undefined) => {
    LockerService.getLocker(Number(id))
      .then((response: AxiosResponse) => {
        setData(response.data)
      })
      .catch((error: AxiosError) => {
        AuthService.refreshToken()
        flash.setMessageErrorFetch()
        Sentry.captureException(error)
      })
  }

  const onSubmit = (data: LockerType) => {
    setOnSubmitLoading(true)
    const setData = {
      cleveronApmId: data.cleveronApmId,
      type: Number(data.type),
      location: data.location,
      shortLocation: data.shortLocation,
      city: data.city,
      longitude: Number(data.longitude),
      latitude: Number(data.latitude),
    }

    LockerService.update(Number(param.id_locker), setData)
      .then(() => {
        flash.setMessage('success', 'Machine modifiée !')
        navigate(-1)
      })
      .catch((error: ApiResponse) => {
        if (error?.response?.data?.violations?.length > 0) {
          flash.setMessageErrorValidator()
          setMessageErrorInput(error.response.data.violations)
        } else {
          flash.setMessageErrorForm()
          Sentry.captureException(error)
        }
      })
      .finally(() => {
        setOnSubmitLoading(false)
      })
  }

  /* Render
   *******************************************************************************************/
  return (
    <>
      {!data ? (
        <Loading />
      ) : (
        <Row className='mt-4'>
          <Col className='col-xl-6 col-lg-10 col-md-12 mx-auto'>
            <Card className='mb-4'>
              <Card.Header>
                <h5 className='mt-2'>Modifier les informations de la machine</h5>
              </Card.Header>
              <Card.Body className='p-5'>
                <Form onSubmit={handleSubmit(onSubmit)}>
                  <Form.Group as={Row} className='mb-3'>
                    <Form.Label column sm={3}>
                      Machine ID <span className='text-danger'>*</span>
                    </Form.Label>
                    <Col sm={9}>
                      <Form.Control
                        type='text'
                        {...register('cleveronApmId', { required: true })}
                        defaultValue={data?.cleveronApmId}
                      />
                      {messageErrorInput ? (
                        <small className='text-danger'>
                          {getMessageErrorSubmit(messageErrorInput, 'cleveronApmId')}
                        </small>
                      ) : (
                        <small className='text-muted'>
                          Fait référence à l'APM ID de CleverOffice
                        </small>
                      )}
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className='mb-3'>
                    <Form.Label column sm={3}>
                      Type <span className='text-danger'>*</span>
                    </Form.Label>
                    <Col sm={9}>
                      <Form.Control
                        type='text'
                        {...register('type', { required: true })}
                        defaultValue={data?.type}
                      />
                      {messageErrorInput && (
                        <small className='text-danger'>
                          {getMessageErrorSubmit(messageErrorInput, 'type')}
                        </small>
                      )}
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className='mb-3'>
                    <Form.Label column sm={3}>
                      Localisation <span className='text-danger'>*</span>
                    </Form.Label>
                    <Col sm={9}>
                      <Form.Control
                        type='text'
                        {...register('location', { required: true })}
                        defaultValue={data?.location}
                      />
                      {messageErrorInput ? (
                        <small className='text-danger'>
                          {getMessageErrorSubmit(messageErrorInput, 'location')}
                        </small>
                      ) : (
                        <small className='text-muted'>
                          Lieu d'entreprosage (Ex: Parking de Carrefour Punaauia)
                        </small>
                      )}
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className='mb-3'>
                    <Form.Label column sm={3}>
                      Nom utilisé <span className='text-danger'>*</span>
                    </Form.Label>
                    <Col sm={9}>
                      <Form.Control
                        type='text'
                        {...register('shortLocation', { required: true })}
                        defaultValue={data?.shortLocation}
                      />
                      {messageErrorInput ? (
                        <small className='text-danger'>
                          {getMessageErrorSubmit(messageErrorInput, 'shortLocation')}
                        </small>
                      ) : (
                        <small className='text-muted'>
                          Valeur affichée dans l'application mobile.
                        </small>
                      )}
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className='mb-3'>
                    <Form.Label column sm={3}>
                      Commune <span className='text-danger'>*</span>
                    </Form.Label>
                    <Col sm={9}>
                      <Form.Control
                        type='text'
                        {...register('city', { required: true })}
                        defaultValue={data?.city}
                      />
                      {messageErrorInput && (
                        <small className='text-danger'>
                          {getMessageErrorSubmit(messageErrorInput, 'city')}
                        </small>
                      )}
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className='mb-3'>
                    <Form.Label column sm={3}>
                      Géolocalisation <span className='text-danger'>*</span>
                    </Form.Label>
                    <Col sm={4}>
                      <Form.Control
                        type='text'
                        {...register('longitude', { required: true })}
                        defaultValue={data?.longitude}
                      />
                      {messageErrorInput && (
                        <small className='text-danger'>
                          {getMessageErrorSubmit(messageErrorInput, 'longitude')}
                        </small>
                      )}
                    </Col>
                    <Col sm={5}>
                      <Form.Control
                        type='text'
                        {...register('latitude', { required: true })}
                        defaultValue={data?.latitude}
                      />
                      {messageErrorInput && (
                        <small className='text-danger'>
                          {getMessageErrorSubmit(messageErrorInput, 'latitude')}
                        </small>
                      )}
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className='mb-3 float-end'>
                    <Col>
                      <Button type='submit' variant='dark'>
                        {onSubmitLoading ? <Spinner size={'sm'} /> : 'Enregistrer'}
                      </Button>
                    </Col>
                  </Form.Group>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      )}
    </>
  )
}
