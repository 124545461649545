import React from "react";
import { Alert, Card, Col, Row, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { CompanyType } from "@/types/CompanyType";
import { setUriId } from "@/utils/Functions";

type Props = {
  ownerships: undefined | CompanyType[];
};

export const OwnerShipListTable: React.FC<Props> = ({ ownerships }: Props) => {
  /* States / Hooks
   *******************************************************************************************/

  /* useEffect
   *******************************************************************************************/
  React.useEffect(() => {}, []);

  /* Render
   *******************************************************************************************/
  return (
    <Card>
      <Card.Header>
        <Row>
          <Col>
            <h5 className="mt-2">Propriétaire des sociétés</h5>
          </Col>
        </Row>
      </Card.Header>
      <Card.Body>
        {ownerships?.length === 0 ? (
          <Alert variant="light" className="text-center pb-5">
            <i className="ri-information-line ri-4x"></i>
            <br />
            Aucune affection !
            <br />
          </Alert>
        ) : (
          <>
            <Table striped hover>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Nom</th>
                  <th>Client / Organisation</th>
                  <th>Adresse email</th>
                  <th>Téléphone</th>
                </tr>
              </thead>
              <tbody>
                {ownerships?.map((company: CompanyType) => (
                  <tr key={company?.id}>
                    <td>
                      <Link to={"/societes/detail/" + setUriId(company["@id"])}>
                        {setUriId(company["@id"])}
                      </Link>
                    </td>
                    <td>
                      <Link to={"/societes/detail/" + setUriId(company["@id"])}>
                        {company?.name}
                      </Link>
                    </td>
                    <td>
                      <Link to={"/societes/detail/" + setUriId(company["@id"])}>
                        {company?.contactName}
                      </Link>
                    </td>
                    <td>
                      <Link to={"/societes/detail/" + setUriId(company["@id"])}>
                        {company?.contactEmail}
                      </Link>
                    </td>
                    <td>
                      <Link to={"/societes/detail/" + setUriId(company["@id"])}>
                        {company?.contactPhone}
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </>
        )}
      </Card.Body>
    </Card>
  );
};
