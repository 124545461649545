import React from "react";
import * as Sentry from "@sentry/react";
import { AxiosError, AxiosResponse } from "axios";
import { Form, Button, Row, Col, Image, Modal } from "react-bootstrap";
import { useForm, SubmitHandler } from "react-hook-form";
import AuthService from "@/services/Auth/AuthService";
import { Link, useNavigate } from "react-router-dom";
import { flashStore } from "@/utils/Store";
import { login, saveSession } from "@/utils/Functions";

type Inputs = {
  username: string;
  password: string;
};

export const Login = () => {
  /* States / Hooks
   *******************************************************************************************/
  const [onSubmitLoading, setOnSubmitLoading] = React.useState<boolean>(false);
  const [showModalML, setShowModalML] = React.useState(false);
  const [showModalPC, setShowModalPC] = React.useState(false);
  const flash = flashStore();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>();

  /* Functions
   *******************************************************************************************/
  const onSubmit: SubmitHandler<Inputs> = (data) => {
    setOnSubmitLoading(true);
    AuthService.login(data.username, data.password)
      .then((response: AxiosResponse) => {
        login(response);
        AuthService.getInfo()
          .then((response_user: AxiosResponse) => {
            saveSession(response_user);
            navigate("/");
          })
          .catch((e: AxiosError) => {
            Sentry.captureException(e);
            setOnSubmitLoading(false);
            flash.setMessage(
              "error",
              "Une erreur s'est produite ! Veuillez recommencer"
            );
          });
      })
      .catch((e: AxiosError) => {
        Sentry.captureException(e);
        setOnSubmitLoading(false);
        flash.setMessage(
          "error",
          "Nom d'utilisateur ou mot de passe incorrect."
        );
      });
  };
  const handleCloseML = () => setShowModalML(false);
  const handleShowML = () => setShowModalML(true);
  const handleClosePC = () => setShowModalPC(false);
  const handleShowPC = () => setShowModalPC(true);

  /* Render
   *******************************************************************************************/
  return (
    <>
      {/* Mentions légales */}
      <Modal size="lg" show={showModalML} onHide={handleCloseML}>
        <Modal.Header closeButton>
          <Modal.Title>Mention légales</Modal.Title>
        </Modal.Header>
        <Modal.Body>Contenu</Modal.Body>
      </Modal>
      {/* Politique de confidentialité */}
      <Modal size="lg" show={showModalPC} onHide={handleClosePC}>
        <Modal.Header closeButton>
          <Modal.Title>Politique de confidentialité</Modal.Title>
        </Modal.Header>
        <Modal.Body>Contenu</Modal.Body>
      </Modal>
      {/* Content Form */}
      <Row className="justify-content-center mt-5 p-5">
        <Col className="col-lg-4 col-sm-8 col-xs-12 align-middle">
          <div className="mx-auto text-center">
            <Image
              src="/img/logo-full.png"
              alt="EasyBox"
              width={250}
              className="text-center"
            />
          </div>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Group className="mb-3" controlId="User Name">
              <Form.Control
                type="text"
                placeholder="Nom d'utilisateur"
                autoComplete="username"
                {...register("username", {
                  required: "Le nom d'utilisateur est obligatoire",
                })}
              />
              {errors.username && (
                <small role="alert" className="text-danger">
                  {errors.username.message}
                </small>
              )}
            </Form.Group>
            <Form.Group className="mb-3" controlId="Password">
              <Form.Control
                type="password"
                placeholder="Mot de passe"
                {...register("password")}
              />
            </Form.Group>
            <Button variant="success" className="btn-sm w-100" type="submit">
              {onSubmitLoading ? "Tentative de connexion..." : "Se connecter"}
            </Button>
          </Form>
          <div className="text-center mt-3">
            <Link to={"/mot-de-passe-oublie"}>Mot de passe oublié ?</Link>
          </div>
          <div className="app-version text-center mt-5">
            <small
              className="text-gray-500 cursor-pointer"
              onClick={handleShowML}
            >
              Mentions légales
            </small>{" "}
            <span className="text-gray-500">|</span>{" "}
            <small
              className="text-gray-500 cursor-pointer"
              onClick={handleShowPC}
            >
              Politique de confidentialité
            </small>
            <br />
            <small className="text-gray-500">
              Version {import.meta.env.VITE_APP_VERSION}
            </small>
          </div>
        </Col>
      </Row>
    </>
  );
};
