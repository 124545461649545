import { UserType } from '@/types/UserType'
import { AxiosResponse } from 'axios'
import parse from 'html-react-parser'

interface ErrorDetail {
  propertyPath: string
  message: string
  code: string
}

export const logout = () => {
  localStorage.removeItem(import.meta.env.VITE_APP_SESSION_TOKEN)
  localStorage.removeItem(import.meta.env.VITE_APP_SESSION_USER)
}

export const login = (response: AxiosResponse) => {
  localStorage.setItem(import.meta.env.VITE_APP_SESSION_TOKEN, JSON.stringify(response.data))
}

export const saveSession = (response: AxiosResponse) => {
  localStorage.setItem(import.meta.env.VITE_APP_SESSION_USER, JSON.stringify(response.data))
}

export const getMessageErrorSubmit = (
  error: ErrorDetail[],
  param: string
): string | undefined => {
  const foundError = error.find((d) => d.propertyPath === param)
  return foundError ? foundError.message : undefined
}

export const setDate = (dateToFormat: Date | string | number | undefined) => {
  if (dateToFormat === undefined) {
    return '' // ou une valeur par défaut appropriée
  }

  const date = new Date(dateToFormat)
  return date.toLocaleDateString('fr-FR', { timeZone: 'Pacific/Tahiti' })
}

export const setDateTime = (dateToFormat: string | undefined) => {
  const date = new Date(dateToFormat as string)
  return date.toLocaleString('fr-FR', { timeZone: 'Pacific/Tahiti' })
}

export const getOrderStatusTimeLine = (name: string | undefined) => {
  if (name === 'created') {
    return parse('Commande créée')
  }
  if (name === 'ready_for_delivery') {
    return parse('Commande prête pour la livraison')
  }
  if (name === 'picked_up') {
    return parse('Commande en cours de livraison')
  }
  if (name === 'operin') {
    return 'Commande déposée'
  }
  if (name === 'receive') {
    return 'Commande récupérée par le client'
  }
  if (name === 'operout') {
    return 'Commande récupérée par le coursier'
  }
  if (name === 'reminder') {
    return 'Commande non récupérée depuis un certain temps'
  }
  if (name === 'overtimedue') {
    return "Commande proche de l'expiration"
  }
  if (name === 'overtime') {
    return 'Commande expirée'
  }
  if (name === 'left_for_customer_service') {
    return 'Commande retournée au service client'
  }
  if (name === 'return') {
    return 'Retour de la commande par le client'
  }
}

// ROLE GETTER & SETTER
export const getRoleUser = (user: UserType) => {
  if (user.roles.includes('ROLE_SUPER_ADMIN')) {
    return 'ROLE_SUPER_ADMIN'
  }
  if (user.roles.includes('ROLE_SHIPPER')) {
    return 'ROLE_SHIPPER'
  }
  if (user.roles.includes('ROLE_TECH')) {
    return 'ROLE_TECH'
  }
  if (user.roles.includes('ROLE_ADMIN')) {
    return 'ROLE_ADMIN'
  }
  return 'ROLE_USER'
}

export const getRoleUserText = (user: UserType) => {
  if (user.roles.includes('ROLE_SUPER_ADMIN')) {
    return 'Super administrateur'
  }
  if (user.roles.includes('ROLE_SHIPPER')) {
    return 'Livreur'
  }
  if (user.roles.includes('ROLE_TECH')) {
    return 'Opérateur'
  }
  if (user.roles.includes('ROLE_ADMIN')) {
    return 'Administrateur'
  }
  return 'Membre'
}

export const setImageLocker = (type: number) => {
  return '/img/apm-' + type + '.png'
}

// Order Status SETTER
export const setOrderStatus = (name: string | undefined) => {
  if (name === 'ready_for_delivery') {
    return parse("<span className='badge bg-warning'>Commande prête pour la livraison</span>")
  }
  if (name === 'picked_up') {
    return parse("<span className='badge bg-secondary'>Commande en cours de livraison</span>")
  }
  if (name === 'created') {
    return parse("<span className='badge bg-dark'>Commande créée</span>")
  }
  if (name === 'operin') {
    return parse("<span className='badge bg-info'>Commande déposée</span>")
  }
  if (name === 'receive') {
    return parse("<span className='badge bg-success'>Commande récupérée par le client</span>")
  }
  if (name === 'operout') {
    return parse(
      "<span className='badge bg-secondary'>Commande récupérée par le coursier</span>"
    )
  }
  if (name === 'reminder') {
    return parse(
      "<span className='badge bg-warning'>Commande non récupérée depuis un certain temps</span>"
    )
  }
  if (name === 'overtimedue') {
    return parse("<span className='badge bg-danger'>Commande proche de l'expiration</span>")
  }
  if (name === 'overtime') {
    return parse("<span className='badge bg-danger'>Commande expirée</span>")
  }
  if (name === 'left_for_customer_service') {
    return parse("<span className='badge bg-info'>Commande retournée au service client</span>")
  }
  if (name === 'return') {
    return parse(
      "<span className='badge bg-danger'>Retour de la commande par le client</span>"
    )
  }
  if (name === 'cancel') {
    return parse("<span className='badge bg-danger'>Commande annulée</span>")
  }
}

// Order Status GETTER
export const getOrderStatus = (name: string | undefined) => {
  if (name === 'created') {
    return parse('Commande créée')
  }
  if (name === 'ready_for_delivery') {
    return parse('Commande prête pour la livraison')
  }
  if (name === 'picked_up') {
    return parse('Commande en cours de livraison')
  }
  if (name === 'operin') {
    return 'Commande déposée'
  }
  if (name === 'receive') {
    return 'Commande récupérée par le client'
  }
  if (name === 'operout') {
    return 'Commande récupérée par le coursier'
  }
  if (name === 'reminder') {
    return 'Commande non récupérée depuis un certain temps'
  }
  if (name === 'overtimedue') {
    return "Commande proche de l'expiration"
  }
  if (name === 'overtime') {
    return 'Commande expirée'
  }
  if (name === 'left_for_customer_service') {
    return 'Commande retournée au service client'
  }
  if (name === 'return') {
    return 'Retour de la commande par le client'
  }
}

// SetRole
export const setRoleUser = (name: string) => {
  if (name === import.meta.env.VITE_APP_ROLE_ADMIN) {
    return 'Administrateur'
  }
  if (name === import.meta.env.VITE_APP_ROLE_MEMBER) {
    return 'Membre'
  }
  if (name === import.meta.env.VITE_APP_ROLE_SHIPPER) {
    return 'Livreur'
  }
  if (name === import.meta.env.VITE_APP_ROLE_TECH) {
    return 'Opérateur'
  }
  if (name === import.meta.env.VITE_APP_ROLE_SUPER_ADMIN) {
    return 'Super admin'
  }
}

export const setUriId = (uri: string | undefined | number): number | undefined => {
  if (typeof uri === 'string') {
    const test = uri.split('/').pop()
    const id: number = parseInt(test || '0', 10)
    return id
  }

  return undefined
}

export const getAllCookies = () => {
  // Obtient tous les cookies du document
  const cookies = document.cookie

  // Divise les cookies en un tableau
  const cookieArray = cookies.split(';')

  // Affiche chaque cookie dans la console
  for (let i = 0; i < cookieArray.length; i++) {
    // console.log(cookieArray[i].trim());
  }
}

// Générer une valeur aléatoire de type numérique à 8 caractères
export const generateRandomNumber = () => {
  const randomNumber = Math.floor(Math.random() * 100000000)
  const randomNumberString = randomNumber.toString()
  const paddedRandomNumberString = randomNumberString.padStart(8, '0')

  return paddedRandomNumberString
}

export const generateMultiorderCode = () => {
  const randomNumber = Math.floor(Math.random() * 1000000)
  const randomNumberString = randomNumber.toString()
  const paddedRandomNumberString = randomNumberString.padStart(6, '0')

  return paddedRandomNumberString
}

export const generateBarcode = () => {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
  let barcode = ''

  for (let i = 0; i < 8; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length)
    barcode += characters[randomIndex]
  }

  return barcode
}

// Obtenir le currentTimestam
export const getCurrentTimestamp = () => {
  const currentDate = new Date()
  const isoTimestamp = currentDate.toISOString()
  return isoTimestamp
}

// Sort Order History
interface OrderHistory {
  '@id': string
  '@type': string
  status: string
  createdAt: string
}

export const extractId = (orderHistory: OrderHistory): number => {
  return parseInt(orderHistory['@id'].split('/').pop()!, 10)
}

export const sortOrderHistory = (history: OrderHistory[] | undefined): void => {
  if (history) {
    history.sort((a, b) => extractId(a) - extractId(b))
  }
}
