import { UserType } from '@/types/UserType'
import { logout, setRoleUser } from '@/utils/Functions'
import React from 'react'
import { Link, useNavigate } from 'react-router-dom'

function TopBarApp() {
  const navigate = useNavigate()

  const handleLogout = () => {
    logout()
    navigate('/connexion')
  }

  // État pour stocker les données de l'utilisateur
  const [userData, setUserData] = React.useState<UserType>()

  React.useEffect(() => {
    const storedUserData = localStorage.getItem(import.meta.env.VITE_APP_SESSION_USER)
    if (storedUserData) {
      const parsedUserData = JSON.parse(storedUserData)
      setUserData(parsedUserData)
    }
  }, [])

  return (
    <nav className='navbar navbar-expand navbar-light bg-white topbar mb-4 static-top'>
      <button id='sidebarToggleTop' className='btn btn-link d-md-none rounded-circle mr-3'>
        <i className='fa fa-bars'></i>
      </button>

      <ul className='navbar-nav ml-auto'>
        <li className='nav-item dropdown no-arrow'>
          <a
            className='nav-link dropdown-toggle'
            href='#'
            id='userDropdown'
            role='button'
            data-toggle='dropdown'
            aria-haspopup='true'
            aria-expanded='false'
          >
            <span className='mr-2 d-none d-lg-inline text-gray-600 small'></span>
            <img className='img-profile rounded-circle me-2' src='/img/avatar-default.png' />
            <span className='userName'>
              {userData?.firstName}
              <br />
              <small>{userData?.roles && setRoleUser(userData?.roles[0])}</small>
            </span>
          </a>

          <div
            className='dropdown-menu dropdown-menu-right shadow animated--grow-in'
            aria-labelledby='userDropdown'
          >
            <Link className='dropdown-item' to={'/mon-compte'}>
              <i className='fas fa-user fa-sm fa-fw mr-2 text-gray-400'></i> Mon compte
            </Link>
            <div className='dropdown-divider'></div>
            <a
              className='dropdown-item'
              href='#'
              onClick={handleLogout}
              data-toggle='modal'
              data-target='#logoutModal'
            >
              <i className='fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400'></i>
              Déconnexion
            </a>
          </div>
        </li>
      </ul>
    </nav>
  )
}

export default TopBarApp
