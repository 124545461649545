import BookingSlotService from '@/services/BookingSlot/BookingSlotService'
import CompanyService from '@/services/Company/CompanyService'
import LockerService from '@/services/Locker/LockerService'
import TemperatureZoneService from '@/services/TemperatureZone/TemperatureZoneService'
import { CompanyType } from '@/types/CompanyType'
import { LockerType } from '@/types/LockerType'
import { SlotType } from '@/types/SlotType'
import { TemperatureZoneType } from '@/types/TemperatureZoneType'
import { flashStore } from '@/utils/Store'
import * as Sentry from '@sentry/react'
import { AxiosResponse } from 'axios'
import React from 'react'
import { Button, Col, Form, Row, Spinner } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
// import { getMessageErrorSubmit } from "@/utils/Functions";
// import { CompanyType } from "@/types/CompanyType";

interface ApiResponse {
  response: AxiosResponse
  violations: Violation[]
}

interface Violation {
  propertyPath: string
  message: string
  code: string
}

export const BookingSlotAddForm = () => {
  /* States / Hooks
   *******************************************************************************************/
  const [onSubmitLoading, setOnSubmitLoading] = React.useState<boolean>(false)
  const [companies, setCompanies] = React.useState<CompanyType[]>([])
  const [lockers, setLockers] = React.useState<LockerType[]>([])
  const [temperatureZone, setTemperatureZone] = React.useState<TemperatureZoneType[]>([])
  const [slots, setSlots] = React.useState<SlotType[]>([])
  const navigate = useNavigate()
  const flash = flashStore()
  const { register, handleSubmit } = useForm<LockerType>()

  /* UseEffect
   *******************************************************************************************/
  React.useEffect(() => {
    getCompanies()
    getLockers()
  }, [])

  /* Functions / Events
   *******************************************************************************************/
  const getCompanies = () => {
    CompanyService.getAllCompany().then((response: AxiosResponse) => {
      setCompanies(response.data['hydra:member'])
    })
  }

  const getLockers = () => {
    LockerService.getAllLocker().then((response: AxiosResponse) => {
      setLockers(response.data['hydra:member'])
    })
  }

  const handleSelectLocker = (locker_id: string) => {
    LockerService.getTemperatureZoneByLocker(Number(locker_id)).then(
      (response: AxiosResponse) => {
        setTemperatureZone(response.data['temperatureZones'])
      }
    )
  }

  const handleSelectTemperatureZone = (temp_zone_id: string) => {
    TemperatureZoneService.getSlotsByTemperatureZone(Number(temp_zone_id)).then(
      (response: AxiosResponse) => {
        setSlots(response.data['slots'])
      }
    )
  }

  const onSubmit = (d: LockerType) => {
    setOnSubmitLoading(true)
    const data = {
      slot: '/api/slots/' + d.slot_id,
      company: '/api/companies/' + d.company_id,
      capacity: Number(d.capacity),
    }
    BookingSlotService.create(data)
      .then(() => {
        flash.setMessage('success', 'Booking créé !')
        navigate(-1)
      })
      .catch((error: ApiResponse) => {
        if (error?.response?.data?.violations?.length > 0) {
          flash.setMessageErrorValidator()
        } else {
          flash.setMessageErrorForm()
          Sentry.captureException(error)
        }
      })
      .finally(() => {
        setOnSubmitLoading(false)
      })
  }

  /* Render
   *******************************************************************************************/
  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Form.Group as={Row} className='mb-3'>
        <Form.Label column sm={3}>
          Société <span className='text-danger'>*</span>
        </Form.Label>
        <Col sm={9}>
          <Form.Select
            aria-label='Liste des sociétés'
            className='form-control'
            {...register('company_id', { required: true })}
          >
            <option>-- Sélectionnez la société --</option>
            {companies.map((company: CompanyType) => (
              <option key={company.id} value={company.id}>
                {company.name}
              </option>
            ))}
          </Form.Select>
        </Col>
      </Form.Group>
      <Form.Group as={Row} className='mb-3'>
        <Form.Label column sm={3}>
          Machine <span className='text-danger'>*</span>
        </Form.Label>
        <Col sm={9}>
          <Form.Select
            aria-label='Liste des machines'
            className='form-control'
            {...register('locker_id', { required: true })}
            onChange={(e) => handleSelectLocker(e.currentTarget.value)}
          >
            <option>-- Sélectionnez la machine --</option>
            {lockers.map((locker: LockerType) => (
              <option key={locker.id} value={locker.id}>
                {locker.location} - {locker.shortLocation}
              </option>
            ))}
          </Form.Select>
        </Col>
      </Form.Group>
      <Form.Group as={Row} className='mb-3'>
        <Form.Label column sm={3}>
          Zone de température <span className='text-danger'>*</span>
        </Form.Label>
        <Col sm={9}>
          <Form.Select
            aria-label='Liste des températures'
            className='form-control'
            {...register('temperature_zone_id', { required: true })}
            onChange={(e) => handleSelectTemperatureZone(e.currentTarget.value)}
          >
            <option>-- Sélectionnez la zone de température --</option>
            {temperatureZone.map((tz: TemperatureZoneType) => (
              <option key={tz.id} value={tz.id}>
                {tz.name}
              </option>
            ))}
          </Form.Select>
        </Col>
      </Form.Group>
      <Form.Group as={Row} className='mb-3'>
        <Form.Label column sm={3}>
          Casier <span className='text-danger'>*</span>
        </Form.Label>
        <Col sm={9}>
          <Form.Select
            aria-label='Liste des températures'
            className='form-control'
            {...register('slot_id', { required: true })}
          >
            <option>-- Sélectionnez le casier --</option>
            {slots.map((slot: SlotType) =>
              slot.available != 0 ? (
                <option key={slot.id} value={slot.id}>
                  Dimensions : {slot.width} x {slot.height} x {slot.depth} cm - Disponible :{' '}
                  {slot.available}
                </option>
              ) : (
                <option>Plus de casier disponible pour cette zone de température</option>
              )
            )}
          </Form.Select>
        </Col>
      </Form.Group>
      <Form.Group as={Row} className='mb-3'>
        <Form.Label column sm={3}>
          Qté souhaitée <span className='text-danger'>*</span>
        </Form.Label>
        <Col sm={9}>
          <Form.Control
            aria-label='Quantité souhaitée'
            className='form-control'
            {...register('capacity', { required: true })}
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row} className='mb-3 float-end'>
        <Col>
          <Button type='submit' variant='dark'>
            {onSubmitLoading ? <Spinner size={'sm'} /> : 'Enregistrer'}
          </Button>
        </Col>
      </Form.Group>
    </Form>
  )
}
