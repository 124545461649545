import { ArrowLeft } from "lucide-react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

export const ButtonLinkBack: React.FC = () => {
  /* States / Hooks
   *******************************************************************************************/
  const navigate = useNavigate();

  /* Render
   *******************************************************************************************/
  return (
    <Button
      className="float-end btn-sm btn btn-dark"
      onClick={() => navigate(-1)}
    >
      <ArrowLeft size={16} /> Retour
    </Button>
  );
};
