import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";

interface FlashState {
  status: string;
  message: string;
  setMessage: (status: string, message: string) => void;
  setMessageErrorFetch: () => void;
  setMessageErrorForm: () => void;
  setMessageErrorValidator: () => void;
}

export const flashStore = create<FlashState>()(
  devtools(
    persist(
      (set) => ({
        status: "",
        message: "",
        setMessage: (status: string, message: string) =>
          set(() => ({ status: status, message: message })),
        setMessageErrorFetch: () =>
          set(() => ({
            status: "error",
            message:
              "Une erreur s'est produite lors de la récupération de donnée ! Veuillez recharger la page.",
          })),
        setMessageErrorForm: () =>
          set(() => ({
            status: "error",
            message: "Une erreur s'est produite ! Veuillez recommencer.",
          })),
        setMessageErrorValidator: () =>
          set(() => ({
            status: "error",
            message:
              "Erreur de validation : veuillez vérifier les informations saisies.",
          })),
      }),
      {
        name: "eb_flash_session",
      }
    )
  )
);
