import { flashStore } from "@/utils/Store";
import Swal from "sweetalert2";

export const FlashComponent = () => {
  const flash = flashStore();

  if (flash.message.length > 0) {
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });

    Toast.fire({
      icon:
        flash.status === "error"
          ? "error"
          : flash.status === "success"
          ? "success"
          : "info",
      title: flash.message,
    }).then(() => {
      flash.setMessage("", "");
    });
  }
  return <></>;
};
