import axios from 'axios'
import authHeader from './../Auth/AuthHeader'

interface Locker {
  image: string
  location: string
  shortLocation: string
  cleveronApmId: string
  latitude: number
  longitude: number
  city: string
  type: number
  zones: object
}

const API_URL = import.meta.env.VITE_APP_END_POINT

class LockerService {
  getAllLocker() {
    return axios.get(API_URL + 'lockers', { headers: authHeader() })
  }

  getLockerPerPage(page: number, perPage: number) {
    return axios.get(API_URL + 'lockers?page=' + page + '&itemsPerPage=' + perPage, {
      headers: authHeader(),
    })
  }

  getLockerLoadNextPage(uri: string | undefined) {
    const new_uri = uri?.replace('/api/', '')
    return axios.get(API_URL + new_uri, { headers: authHeader() })
  }

  getLocker(id: number) {
    return axios.get(
      API_URL +
        `lockers/${id}/?q[]=createdAt&q[]=id&q[]=city&q[slotsInfos][]=total&q[slotsInfos][]=available&q[slotsInfos][]=rented&q[]=location&q[]=shortLocation&q[]=cleveronApmId&q[]=latitude&q[]=longitude&q[]=street&q[]=zipCode&q[]=available&q[]=type&q[temperatureZones][]=id&q[temperatureZones][]=myKey&q[temperatureZones][]=name&q[temperatureZones][]=currentTemp&q[temperatureZones][]=keyTemp&q[temperatureZones][slots][]=id&q[temperatureZones][slots][]=capacity&q[temperatureZones][slots][]=size&q[temperatureZones][slots][]=capacity&q[temperatureZones][slots][]=available&q[temperatureZones][slots][]=maxWeight&q[temperatureZones][slots][]=maxVolume&q[temperatureZones][slots][]=width&q[temperatureZones][slots][]=height&q[temperatureZones][slots][]=depth`,
      {
        headers: authHeader(),
      }
    )
  }

  getTemperatureZoneByLocker(id: number) {
    return axios.get(API_URL + `lockers/${id}?q[temperatureZones]=*`, {
      headers: authHeader(),
    })
  }

  getLockerByUri(uri: string) {
    const id = uri.substring(uri.lastIndexOf('/') + 1)
    return axios.get(API_URL + 'lockers/' + id, {
      headers: authHeader(),
    })
  }

  create(data: Locker) {
    return axios.post(API_URL + 'lockers', data, { headers: authHeader() })
  }

  update(id: number, data: object) {
    axios.defaults.headers.patch['Content-Type'] = 'application/merge-patch+json'
    return axios.patch(API_URL + 'lockers/' + id, data, {
      headers: authHeader(),
    })
  }

  _delete = async (id: number) => {
    return await axios.delete(API_URL + `lockers/${id}`, {
      headers: authHeader(),
    })
  }
}

export default new LockerService()
