import React from "react";
import * as Sentry from "@sentry/react";
import {
  MoreVertical,
  List,
  Pencil,
  Check,
  X,
  Trash2,
  Plus,
} from "lucide-react";
import {
  Dropdown,
  Card,
  Button,
  Table,
  Col,
  Row,
  Modal,
  Form,
  Spinner,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { Loading } from "@/components/lib/Loading";
import { AxiosError, AxiosResponse } from "axios";
import UserService from "@/services/User/UserService";
import { flashStore } from "@/utils/Store";
import { UserType } from "@/types/UserType";
import AuthService from "@/services/Auth/AuthService";
import { getRoleUserText } from "@/utils/Functions";
import { EmptyData } from "@/components/lib/EmptyData";
import { useUserRole } from "@/hooks/useUserRole";
import CompanyService from "@/services/Company/CompanyService";
import { useForm } from "react-hook-form";

type Props = {
  id_company: string | undefined;
};

export const CompanyUserListTable: React.FC<Props> = ({
  id_company,
}: Props) => {
  /* States / Hooks
   *******************************************************************************************/
  const [data, setData] = React.useState<UserType[]>([]);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [showModalOwner, setShowModalUser] = React.useState<boolean>(false);
  const [users, setUsers] = React.useState<UserType[]>([]);
  const [nextLoading, setNextLoading] = React.useState<boolean>(false);
  const [nextPage, setNextPage] = React.useState<string | undefined>();
  const [onSubmitLoading, setOnSubmitLoading] = React.useState<boolean>(false);
  const [currentPage] = React.useState<number>(1);
  const [perPage] = React.useState<number>(10);
  const flash = flashStore();
  const myRole = useUserRole();
  const { register, handleSubmit } = useForm<UserType>();

  /* useEffect
   *******************************************************************************************/
  React.useEffect(() => {
    getData(currentPage, perPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, perPage]);

  /* Functions
   *******************************************************************************************/
  const getData = (page: number, perPage: number) => {
    UserService.getMembersPerPage(Number(id_company), page, perPage)
      .then((response: AxiosResponse) => {
        setData(response.data["hydra:member"]);
        setNextPage(response.data["hydra:view"]["hydra:next"]);
      })
      .catch((error: AxiosError) => {
        AuthService.refreshToken();
        Sentry.captureException(error);
      })
      .finally(() => setLoading(false));
  };

  const handleNextPage = () => {
    setNextLoading(true);
    UserService.getUserLoadNextPage(nextPage)
      .then((response: AxiosResponse) => {
        setData(data.concat(response.data["hydra:member"]));
        setNextPage(response.data["hydra:view"]["hydra:next"]);
      })
      .catch((error: AxiosError) => {
        AuthService.refreshToken();
        Sentry.captureException(error);
      })
      .finally(() => setNextLoading(false));
  };

  const removeUserOfCompany = (id_user: number) => {
    CompanyService.getMembers(Number(id_company), 1, 100).then(
      (response: AxiosResponse) => {
        const filter = response.data["hydra:member"].filter(
          (user: UserType) => Number(user.id) !== id_user
        );
        const newMemberships = filter.map(
          (objet: { [x: string]: string }) => objet["@id"]
        );
        CompanyService.update(Number(id_company), { members: newMemberships })
          .then(() => {
            getData(currentPage, perPage);
            flash.setMessage("success", "Utilisateur retiré de la société !");
          })
          .catch((error: AxiosError) => {
            flash.setMessageErrorForm();
            Sentry.captureException(error);
          });
      }
    );
  };

  const onSubmitAddUser = (user: UserType) => {
    setOnSubmitLoading(true);
    CompanyService.getCompanyById(Number(id_company)).then(
      (response: AxiosResponse) => {
        const tab = response.data.members;
        const result = {
          members: [...tab, "/api/users/" + user.id],
        };

        CompanyService.update(Number(id_company), result)
          .then(() => {
            getData(currentPage, perPage);
            flash.setMessage("success", "Proriétaire ajouté !");
            handleCloseModalUser();
          })
          .catch((error: AxiosError) => {
            flash.setMessageErrorForm();
            Sentry.captureException(error);
          })
          .finally(() => {
            setOnSubmitLoading(false);
          });
      }
    );
  };

  const getUsers = () => {
    UserService.getAllUser()
      .then((response: AxiosResponse) => {
        setUsers(response.data["hydra:member"]);
      })
      .catch((error: AxiosError) => {
        AuthService.refreshToken();
        flash.setMessageErrorFetch();
        Sentry.captureException(error);
      });
  };

  const handleCloseModalUser = () => setShowModalUser(false);
  const handleShowModalUser = () => {
    getUsers();
    setShowModalUser(true);
  };

  /* Render
   *******************************************************************************************/
  return (
    <>
      <Modal show={showModalOwner} onHide={handleCloseModalUser}>
        <Form onSubmit={handleSubmit(onSubmitAddUser)}>
          <Modal.Header closeButton>
            <Modal.Title>Ajouter un utilisateur</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group as={Row} className="mb-3">
              <Col>
                <Form.Select
                  aria-label="Rôle de l'utilisateur"
                  className="form-control"
                  {...register("id", { required: true })}
                >
                  <option>-- Sélectionnez un utilisateur existant --</option>
                  {users?.map((user: UserType) => (
                    <option key={user?.id} value={user.id}>
                      {user.firstName + " " + user.lastName}
                    </option>
                  ))}
                </Form.Select>
              </Col>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Link
              className="float-end btn-sm btn btn-outline-dark"
              to={`/societes/${id_company}/utilisateur/creation`}
            >
              <Plus size={20} /> Nouvel utilisateur
            </Link>
            <Button type="submit" variant="success">
              {onSubmitLoading ? <Spinner size={"sm"} /> : "Enregistrer"}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
      <Card>
        <Card.Header>
          <Row>
            <Col>
              <h5 className="mt-2">Utilisateurs</h5>
            </Col>
            {(myRole === import.meta.env.VITE_APP_ROLE_SUPER_ADMIN ||
              myRole === import.meta.env.VITE_APP_ROLE_ADMIN) && (
              <Col className="text-end">
                <Button
                  variant="bg-transparent"
                  className="float-end btn-sm btn btn-outline-info"
                  onClick={handleShowModalUser}
                >
                  <Plus size={20} /> Nouvel utilisateur
                </Button>
              </Col>
            )}
          </Row>
        </Card.Header>
        {loading ? (
          <Loading />
        ) : data?.length === 0 ? (
          <EmptyData />
        ) : (
          <>
            <Card.Body>
              <Table striped hover>
                <thead>
                  <tr>
                    <th>Nom</th>
                    <th>Rôle</th>
                    <th>Adresse email</th>
                    <th>Téléphone</th>
                    <th>Statut</th>
                    {myRole === import.meta.env.VITE_APP_ROLE_SUPER_ADMIN && (
                      <th>Actions</th>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {data.map((user: UserType) => (
                    <tr key={user?.id}>
                      <td>
                        <Link to={"/utilisateurs/detail/" + user?.id}>
                          {user?.firstName + " " + user.lastName}
                        </Link>
                      </td>
                      <td>
                        <Link to={"/utilisateurs/detail/" + user?.id}>
                          {getRoleUserText(user)}
                        </Link>
                      </td>
                      <td>
                        <Link to={"/utilisateurs/detail/" + user?.id}>
                          {user?.email}
                        </Link>
                      </td>
                      <td>
                        <Link to={"/utilisateurs/detail/" + user?.id}>
                          {user?.phone}
                        </Link>
                      </td>
                      <td>
                        {user?.enabled ? (
                          <Check className="text-success" />
                        ) : (
                          <X className="text-danger" />
                        )}
                      </td>
                      {myRole === import.meta.env.VITE_APP_ROLE_SUPER_ADMIN && (
                        <td>
                          <Dropdown className="datatable-dropdown no-arrow">
                            <Dropdown.Toggle variant="secondary">
                              <MoreVertical />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item>
                                <Link
                                  to={"/utilisateurs/detail/" + user?.id}
                                  className="py-2 d-block"
                                >
                                  <List size={16} /> Détail
                                </Link>
                              </Dropdown.Item>
                              <Dropdown.Item>
                                <Link
                                  to={"/utilisateurs/modification/" + user?.id}
                                  className="py-2 d-block"
                                >
                                  <Pencil size={16} /> Modifier
                                </Link>
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={() => removeUserOfCompany(user?.id)}
                                className="py-2 d-block"
                              >
                                <Trash2 size={16} /> Retirer {user.firstName} de
                                cette société
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </td>
                      )}
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card.Body>

            {nextPage !== undefined && (
              <div className="ms-auto mb-3 me-3">
                <Button
                  variant="dark"
                  className="d-inline w-20"
                  onClick={handleNextPage}
                >
                  {nextLoading ? (
                    "En cours de chargement..."
                  ) : (
                    <>
                      <Plus size={20} /> Charger plus{" "}
                    </>
                  )}
                </Button>
              </div>
            )}
          </>
        )}
      </Card>
    </>
  );
};
