import React from "react";
import { Button, Col, Row, Form, Spinner } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { CompanyType } from "@/types/CompanyType";
import { flashStore } from "@/utils/Store";
import CompanyService from "@/services/Company/CompanyService";
import { AxiosError, AxiosResponse } from "axios";
import * as Sentry from "@sentry/react";
import { getMessageErrorSubmit } from "@/utils/Functions";
import { Loading } from "@/components/lib/Loading";
import AuthService from "@/services/Auth/AuthService";

interface ApiResponse {
  response: AxiosResponse;
  violations: Violation[];
}

interface Violation {
  propertyPath: string;
  message: string;
  code: string;
}

export const CompanyEditForm = () => {
  /* States / Hooks
   *******************************************************************************************/
  const [onSubmitLoading, setOnSubmitLoading] = React.useState<boolean>(false);
  const [messageErrorInput, setMessageErrorInput] = React.useState();
  const [data, setData] = React.useState<CompanyType>();
  const navigate = useNavigate();
  const flash = flashStore();
  const { register, handleSubmit } = useForm<CompanyType>();
  const param = useParams();

  /* useEffect
   *******************************************************************************************/
  React.useEffect(() => {
    getData(param.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [param]);

  /* Functions / Events
   *******************************************************************************************/
  const getData = (id: string | undefined) => {
    CompanyService.getCompanyById(Number(id))
      .then((response: AxiosResponse) => {
        setData(response.data);
      })
      .catch((error: AxiosError) => {
        AuthService.refreshToken();
        flash.setMessageErrorFetch();
        Sentry.captureException(error);
      });
  };

  const onSubmit = (data: CompanyType) => {
    setOnSubmitLoading(true);
    CompanyService.update(Number(param.id), data)
      .then(() => {
        flash.setMessage("success", "Société modifiée !");
        navigate(-1);
      })
      .catch((error: ApiResponse) => {
        if (error?.response?.data?.violations?.length > 0) {
          flash.setMessageErrorValidator();
          setMessageErrorInput(error.response.data.violations);
        } else {
          flash.setMessageErrorForm();
          Sentry.captureException(error);
        }
      })
      .finally(() => {
        setOnSubmitLoading(false);
      });
  };

  /* Render
   *******************************************************************************************/
  return (
    <>
      {!data ? (
        <Loading />
      ) : (
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Form.Control
            type="hidden"
            defaultValue={data?.id}
            {...register("id", { required: true })}
          />
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={3}>
              External ID <span className="text-danger">*</span>
            </Form.Label>
            <Col sm={9}>
              <Form.Control
                type="text"
                defaultValue={data?.cleveronCompanyId}
                {...register("cleveronCompanyId", { required: true })}
              />
              {messageErrorInput ? (
                <small className="text-danger">
                  {getMessageErrorSubmit(messageErrorInput, "name")}
                </small>
              ) : (
                <Form.Text className="text-muted">
                  Fait référence à l'external ID de la société créé dans Clever
                  Office
                </Form.Text>
              )}
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={3}>
              Order Event Url<span className="text-danger">*</span>
            </Form.Label>
            <Col sm={9}>
              <Form.Control
                type="url"
                defaultValue={data?.orderEventUrl}
                {...register("orderEventUrl", { required: true })}
              />
              {messageErrorInput ? (
                <small className="text-danger">
                  {getMessageErrorSubmit(messageErrorInput, "orderEventUrl")}
                </small>
              ) : (
                <Form.Text className="text-muted">
                  Url qui servira à mettre à jour les états des commandes
                </Form.Text>
              )}
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={3}>
              Nom société <span className="text-danger">*</span>
            </Form.Label>
            <Col sm={9}>
              <Form.Control
                type="text"
                defaultValue={data?.name}
                {...register("name", { required: true })}
              />
              {messageErrorInput && (
                <small className="text-danger">
                  {getMessageErrorSubmit(messageErrorInput, "name")}
                </small>
              )}
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={3}>
              Organisation <span className="text-danger">*</span>
            </Form.Label>
            <Col sm={9}>
              <Form.Control
                type="text"
                defaultValue={data?.contactName}
                {...register("contactName", { required: true })}
              />
              {messageErrorInput ? (
                <small className="text-danger">
                  {getMessageErrorSubmit(messageErrorInput, "name")}
                </small>
              ) : (
                <Form.Text className="text-muted">
                  Organisation, service ou nom du client
                </Form.Text>
              )}
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={3}>
              Contact email <span className="text-danger">*</span>
            </Form.Label>
            <Col sm={9}>
              <Form.Control
                type="email"
                defaultValue={data?.contactEmail}
                {...register("contactEmail", { required: true })}
              />
              {messageErrorInput && (
                <small className="text-danger">
                  {getMessageErrorSubmit(messageErrorInput, "contactEmail")}
                </small>
              )}
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={3}>
              N° portable <span className="text-danger">*</span>
            </Form.Label>
            <Col sm={9}>
              <Form.Control
                type="number"
                defaultValue={data?.contactPhone}
                {...register("contactPhone", { required: true })}
              />
              {messageErrorInput && (
                <small className="text-danger">
                  {getMessageErrorSubmit(messageErrorInput, "contactPhone")}
                </small>
              )}
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3">
            <Col sm={{ span: 9, offset: 3 }}>
              <hr />
              <span className="text-muted d-block mb-2">
                Options de notification :
              </span>
              <div className="form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="smsProviderActive"
                  defaultChecked={data?.smsProviderActive}
                  {...register("smsProviderActive")}
                />
                <label className="form-check-label" htmlFor="smsProviderActive">
                  SMS
                </label>
              </div>
              <div className="form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="mailProviderActive"
                  defaultChecked={data?.mailProviderActive}
                  {...register("mailProviderActive")}
                />
                <label
                  className="form-check-label"
                  htmlFor="mailProviderActive"
                >
                  Email
                </label>
              </div>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3 float-end">
            <Col>
              <Button type="submit" variant="dark">
                {onSubmitLoading ? <Spinner size={"sm"} /> : "Enregistrer"}
              </Button>
            </Col>
          </Form.Group>
        </Form>
      )}
    </>
  );
};
