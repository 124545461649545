import { EmptyData } from '@/components/lib/EmptyData'
import { Loading } from '@/components/lib/Loading'
import { useUserRole } from '@/hooks/useUserRole'
import AuthService from '@/services/Auth/AuthService'
import BookingSlotService from '@/services/BookingSlot/BookingSlotService'
import CompanyService from '@/services/Company/CompanyService'
import { BookingSlotType } from '@/types/BookingSlotType'
import { CompanyType } from '@/types/CompanyType'
import { flashStore } from '@/utils/Store'
import * as Sentry from '@sentry/react'
import { AxiosError, AxiosResponse } from 'axios'
import { Edit, Trash2 } from 'lucide-react'
import React from 'react'
import { Button, Card, Col, Form, InputGroup, Modal, Row, Spinner } from 'react-bootstrap'
import DataTable, { TableColumn } from 'react-data-table-component'
import Swal, { SweetAlertResult } from 'sweetalert2'

interface ApiResponse {
  response: AxiosResponse
  data: string
}

// interface Violation {
//   response: string
//   data: string
//   code: string
// }

export const BookingSlotListTable = () => {
  /* States / Hooks
   *******************************************************************************************/
  const [data, setData] = React.useState<CompanyType[]>([])
  const [loading, setLoading] = React.useState<boolean>(true)
  const columns: TableColumn<CompanyType>[] = [
    {
      name: 'Sociétés',
      selector: (row: CompanyType) => row.name,
      sortable: true,
      wrap: true,
      width: '220px',
    },
    {
      name: 'Machines réservées',
      cell: (row: CompanyType) => {
        return (
          <table className='table table-striped bg-transparent mb-0'>
            <thead>
              <tr>
                {myRole === import.meta.env.VITE_APP_ROLE_SUPER_ADMIN && (
                  <th scope='col'>Booking ID</th>
                )}
                <th scope='col'>Emplacement</th>
                <th scope='col'>Zone de température</th>
                <th scope='col'>Dimensions casier</th>
                <th scope='col'>Casiers réservés</th>
                <th scope='col'>Casiers disponibles</th>
                <th scope='col'>Etat</th>
                {myRole === import.meta.env.VITE_APP_ROLE_SUPER_ADMIN && (
                  <th scope='col'>Actions</th>
                )}
              </tr>
            </thead>
            <tbody>
              {row.bookingSlots?.map((booking) => (
                <tr className='bg-transparent' key={booking?.id}>
                  {myRole === import.meta.env.VITE_APP_ROLE_SUPER_ADMIN && (
                    <td>{booking?.id}</td>
                  )}
                  <td width={400}>
                    {booking?.slot?.temperatureZone?.locker?.location}
                    <br />
                    <small className='text-muted'>
                      {booking?.slot?.temperatureZone?.locker?.shortLocation}
                    </small>
                  </td>
                  <td>{booking?.slot?.temperatureZone?.name}</td>
                  <td>
                    {booking?.slot?.width} x {booking?.slot?.height} x {booking?.slot?.depth}cm
                  </td>

                  <td>{booking?.capacity}</td>
                  <td>{booking?.available}</td>
                  <td>
                    <Form.Check
                      onClick={() => handleUpdateState(Number(booking?.id), !booking?.active)}
                      defaultChecked={booking?.active}
                      type='switch'
                      label={booking?.active ? 'Activé' : 'Désactivé'}
                      id={booking?.active ? 'custom-switch' : 'disabled-custom-switch'}
                    />
                  </td>
                  {myRole === import.meta.env.VITE_APP_ROLE_SUPER_ADMIN && (
                    <td>
                      <Edit
                        className='text-muted me-2 cursor-pointer'
                        onClick={() => handleShowEditForm(booking)}
                      />
                      <Trash2
                        className='text-danger cursor-pointer'
                        onClick={() => handleDelete(Number(booking?.id))}
                      />
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        )
      },
      sortable: true,
      wrap: true,
    },
  ]
  const paginationComponentOptions = {
    rowsPerPageText: 'Lignes par page',
    rangeSeparatorText: 'de',
    selectAllRowsItem: true,
    selectAllRowsItemText: 'Tous',
  }
  const [totalRows, setTotalRows] = React.useState<number>(0)
  const [currentPage] = React.useState<number>(1)
  const [perPage, setPerPage] = React.useState<number>(10)
  const [show, setShow] = React.useState<boolean>(false)
  const [bookingInput, setBookingInput] = React.useState<BookingSlotType>()
  const [onSubmitLoading, setOnSubmitLoading] = React.useState<boolean>(false)
  const flash = flashStore()
  const myRole = useUserRole()

  /* useEffect
   *******************************************************************************************/
  React.useEffect(() => {
    getData(currentPage, perPage)
  }, [currentPage, perPage])

  /* Functions
   *******************************************************************************************/
  const getData = (page: number, perPage: number) => {
    CompanyService.getBookingSlotsOfCompanies(page, perPage)
      .then((response: AxiosResponse) => {
        setData(response.data['hydra:member'])
        setTotalRows(response.data['hydra:totalItems'])
      })
      .catch((error: AxiosError) => {
        AuthService.refreshToken()
        Sentry.captureException(error)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const handlePageChange = (page: number) => {
    getData(page, perPage)
  }

  const handlePerRowsChange = async (newPerPage: number) => {
    setPerPage(newPerPage)
  }

  const handleShowEditForm = (booking: BookingSlotType) => {
    setBookingInput(booking)
    setShow(true)
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    setOnSubmitLoading(true)
    // Récupérer les valeurs du formulaire
    const formData = new FormData(event.currentTarget)
    const id = formData.get('id') as string
    // const slot_available = Number(formData.get("slot_available"));
    const new_booking_capacity = Number(formData.get('capacity'))
    // const new_slot_available = slot_available - new_booking_capacity;

    const setDataBooking: BookingSlotType = {
      capacity: new_booking_capacity,
    }

    // const setDataSlot: SlotType = {
    //   available: new_slot_available,
    // };

    BookingSlotService.update(Number(id), setDataBooking)
      .then(() => {
        // updateSlot(Number(bookingInput?.slot?.id), setDataSlot);
        flash.setMessage('success', 'Booking #' + id + ' a bien été modifié')
      })
      .catch((error: AxiosError) => {
        flash.setMessageErrorForm()
        Sentry.captureException(error)
      })
      .finally(() => {
        setOnSubmitLoading(false)
        setShow(false)
        getData(currentPage, perPage)
      })
  }

  const handleDelete = (id: number) => {
    Swal.fire({
      title: 'Confirmation !',
      text: 'Voulez-vous vraiment supprimer le booking #' + id + ' ?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Oui, je confirme',
      cancelButtonText: 'Non',
    }).then((result: SweetAlertResult) => {
      if (result.isConfirmed) {
        deleteBooking(id)
      }
    })
  }

  const handleUpdateState = (id: number, state: boolean) => {
    const _data = { active: state }

    BookingSlotService.update(id, _data)
      .then(() => {
        if (state === false) {
          flash.setMessage('success', 'Booking #' + id + ' désactivé !')
        } else {
          flash.setMessage('success', 'Booking #' + id + ' activé !')
        }
      })
      .catch((e: ApiResponse) => {
        flash.setMessage('error', e?.response?.data['hydra:description'])
        getData(currentPage, perPage)
        Sentry.captureException(e)
      })
      .finally(() => {
        getData(currentPage, perPage)
      })
  }

  const deleteBooking = (id: number) => {
    const _data = { active: false }

    BookingSlotService.update(id, _data)
      .then(() => {
        BookingSlotService._delete(id)
          .then(() => {
            flash.setMessage('success', 'Booking supprimé !')
            getData(currentPage, perPage)
          })
          .catch((e: ApiResponse) => {
            flash.setMessage('error', e?.response?.data['hydra:description'])
            Sentry.captureException(e)
          })
      })
      .catch((e: ApiResponse) => {
        flash.setMessage('error', e?.response?.data['hydra:description'])
        Sentry.captureException(e)
      })
  }

  /* Render
   *******************************************************************************************/
  return (
    <Card>
      <>
        {loading ? (
          <Loading />
        ) : data?.length === 0 ? (
          <EmptyData />
        ) : (
          <>
            <DataTable
              columns={columns}
              data={data}
              pagination
              paginationServer
              paginationTotalRows={totalRows}
              onChangePage={handlePageChange}
              onChangeRowsPerPage={handlePerRowsChange}
              paginationComponentOptions={paginationComponentOptions}
              striped
            />
            {/* Modal Edit Form */}
            <Modal show={show} onHide={() => setShow(false)} size='lg'>
              <Form onSubmit={handleSubmit}>
                <Modal.Header closeButton className='bg-dark'>
                  <Modal.Title className='text-white'>Modifier le booking</Modal.Title>
                </Modal.Header>
                <Modal.Body className='p-5'>
                  <input type='hidden' name='id' defaultValue={bookingInput?.id} />
                  <Form.Group as={Row} className='mb-3'>
                    <Form.Label column sm={4}>
                      Nombre de casier à réserver <span className='text-danger'>*</span>
                    </Form.Label>
                    <Col sm={8}>
                      <input
                        type='hidden'
                        name='slot_available'
                        value={bookingInput?.slot?.available}
                      />
                      <InputGroup>
                        <Form.Control
                          type='text'
                          defaultValue={bookingInput?.capacity}
                          name='capacity'
                        />
                      </InputGroup>
                      <span className='text-muted mt-2 d-block'>
                        Casiers disponibles : {bookingInput?.slot?.available}
                      </span>
                    </Col>
                  </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant='secondary' onClick={() => setShow(false)}>
                    Annuler
                  </Button>
                  <Button variant='dark' type='submit'>
                    {onSubmitLoading ? <Spinner size={'sm'} /> : 'Enregistrer'}
                  </Button>
                </Modal.Footer>
              </Form>
            </Modal>
          </>
        )}
      </>
    </Card>
  )
}
