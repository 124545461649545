import { DecodedToken } from "@/types/DecodeTokenType";
import React, { useEffect } from "react";
import { useJwt } from "react-jwt";

export const useUserRole = () => {
  const [myRole, setMyRole] = React.useState<string>();
  const session_storage = localStorage.getItem(
    import.meta.env.VITE_APP_SESSION_TOKEN
  );
  const result = useJwt(session_storage || "");
  const decodedToken: DecodedToken | null = (
    result as { decodedToken: DecodedToken | null }
  )?.decodedToken;

  useEffect(() => {
    if (decodedToken) {
      setMyRole(decodedToken?.roles?.[0]);
    }
  }, [decodedToken]);

  return myRole;
};
