import { useUserRole } from '@/hooks/useUserRole'
import AuthService from '@/services/Auth/AuthService'
import SlotService from '@/services/Slot/SlotService'
import { SlotType } from '@/types/SlotType'
import { getMessageErrorSubmit } from '@/utils/Functions'
import { flashStore } from '@/utils/Store'
import * as Sentry from '@sentry/react'
import { AxiosError, AxiosResponse } from 'axios'
import { Edit, Trash2 } from 'lucide-react'
import React from 'react'
import { Button, Col, Form, InputGroup, Modal, Row, Spinner } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import Swal, { SweetAlertResult } from 'sweetalert2'

type Props = {
  id_temperature_zone: number | undefined
}

interface ApiResponse {
  response: AxiosResponse
  violations: Violation[]
}

interface Violation {
  propertyPath: string
  message: string
  code: string
}

interface ErrorResponse {
  response: AxiosResponse
  hydra: {
    description: string
  }
}

export const LockerTemperatureZoneSlotListTable: React.FC<Props> = ({
  id_temperature_zone,
}: Props) => {
  /* States / Hooks
   *******************************************************************************************/
  const [data, setData] = React.useState<SlotType[]>()
  const [slotInput, setSlotInput] = React.useState<SlotType>()
  const [onSubmitLoading, setOnSubmitLoading] = React.useState<boolean>(false)
  const [messageErrorInput, setMessageErrorInput] = React.useState()
  const [show, setShow] = React.useState<boolean>(false)
  const { register, handleSubmit } = useForm<SlotType>()
  const flash = flashStore()
  const myRole = useUserRole()

  /* useEffect
   *******************************************************************************************/
  React.useEffect(() => {
    if (id_temperature_zone) {
      getData(id_temperature_zone)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id_temperature_zone])

  /* Functions / Events
   *******************************************************************************************/
  const getData = (id_temperature_zone: number) => {
    SlotService.getSlotsByTemperatureZoneId(Number(id_temperature_zone))
      .then((response: AxiosResponse) => {
        setData(response.data['hydra:member'])
      })
      .catch((error: AxiosError) => {
        AuthService.refreshToken()
        flash.setMessageErrorFetch()
        Sentry.captureException(error)
      })
  }

  const handleClose = () => setShow(false)

  const handleShowEditForm = (slot: SlotType) => {
    setSlotInput(slot)
    setShow(true)
  }

  const handleUpdateSlot = (data: SlotType) => {
    setOnSubmitLoading(true)
    const setData: SlotType = {
      size: data.size,
      capacity: Number(data.capacity),
      height: Number(data.height),
      width: Number(data.width),
      depth: Number(data.depth),
      maxWeight: Number(data.maxWeight),
      maxVolume: Number(data.depth) * Number(data.width) * Number(data.height),
    }

    SlotService.update(Number(data.id), setData)
      .then(() => {
        flash.setMessage('success', 'le casier #' + data.id + ' a bien été modifié')
        getData(Number(id_temperature_zone))
      })
      .catch((error: ApiResponse) => {
        if (error?.response?.data?.violations?.length > 0) {
          flash.setMessageErrorValidator()
          setMessageErrorInput(error.response.data.violations)
        } else {
          flash.setMessageErrorForm()
          Sentry.captureException(error)
        }
      })
      .finally(() => {
        setOnSubmitLoading(false)
        setShow(false)
      })
  }

  const handleDelete = (id_slot: number) => {
    Swal.fire({
      title: 'Confirmation !',
      text: 'Voulez-vous vraiment supprimer le casier #' + id_slot + ' ?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Oui, je confirme',
      cancelButtonText: 'Non',
    }).then((result: SweetAlertResult) => {
      if (result.isConfirmed) {
        SlotService._delete(id_slot)
          .then(() => {
            flash.setMessage('success', 'Casier supprimé !')
            if (id_temperature_zone) {
              getData(id_temperature_zone)
            }
          })
          .catch((e: ErrorResponse) => {
            if (e?.response?.status == 403) {
              flash.setMessage('error', e?.response?.data['hydra:description'])
              Sentry.captureException(e)
            }
          })
      }
    })
  }

  /* Render
   *******************************************************************************************/
  return (
    <>
      {data?.map((slot: SlotType) => (
        <tr key={Math.random()} className='bg-light'>
          <td className='border-left text-center'>#{slot?.id}</td>
          <td className='border-left'>
            H {slot?.height} x L {slot?.width} x P {slot?.depth} cm
            <br />
            <small className='text-gray-500'>Taille : {slot?.size}</small>
          </td>
          <td className='border-left text-center'>{slot?.capacity}</td>
          <td className='border-left text-center'>{slot?.available}</td>
          <td className='border-left text-center'>{slot?.maxWeight}kg</td>
          {myRole === import.meta.env.VITE_APP_ROLE_SUPER_ADMIN && (
            <td className='border-left text-center'>
              <Edit
                className='cursor-pointer text-secondary'
                onClick={() => handleShowEditForm(slot)}
              />
              <Trash2
                className='cursor-pointer text-danger ms-2'
                onClick={() => handleDelete(Number(slot?.id))}
              />
            </td>
          )}
        </tr>
      ))}
      {/* Modal Edit Form */}
      <Modal show={show} onHide={handleClose} size='lg'>
        <Form onSubmit={handleSubmit(handleUpdateSlot)}>
          <Modal.Header closeButton className='bg-dark'>
            <Modal.Title className='text-white'>
              Modifier le casier #{slotInput?.id}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className='p-5'>
            <input
              type='hidden'
              defaultValue={slotInput?.id}
              {...register('id', { required: true })}
            />
            <Form.Group as={Row} className='mb-3'>
              <Form.Label column sm={4}>
                Taille du casier <span className='text-danger'>*</span>
              </Form.Label>
              <Col sm={8}>
                <InputGroup>
                  <Form.Control
                    type='text'
                    defaultValue={slotInput?.size}
                    {...register('size', { required: true })}
                    placeholder='M/S/XS'
                  />
                </InputGroup>
                {messageErrorInput ? (
                  <small className='text-danger'>
                    {getMessageErrorSubmit(messageErrorInput, 'size')}
                  </small>
                ) : (
                  <small className='text-muted'>Valeurs autorisées : XS / S / M</small>
                )}
              </Col>
            </Form.Group>
            <Form.Group as={Row} className='mb-3'>
              <Form.Label column sm={4}>
                Nombre de casier total <span className='text-danger'>*</span>
              </Form.Label>
              <Col sm={8}>
                <InputGroup>
                  <Form.Control
                    type='text'
                    defaultValue={slotInput?.capacity}
                    {...register('capacity', { required: true })}
                  />
                  <InputGroup.Text>cm</InputGroup.Text>
                </InputGroup>
                {messageErrorInput && (
                  <small className='text-danger'>
                    {getMessageErrorSubmit(messageErrorInput, 'capacity')}
                  </small>
                )}
              </Col>
            </Form.Group>
            <Form.Group as={Row} className='mb-3'>
              <Form.Label column sm={4}>
                La hauteur du casier <span className='text-danger'>*</span>
              </Form.Label>
              <Col sm={8}>
                <InputGroup>
                  <Form.Control
                    type='text'
                    defaultValue={slotInput?.height}
                    {...register('height', { required: true })}
                  />
                  <InputGroup.Text>cm</InputGroup.Text>
                </InputGroup>
                {messageErrorInput && (
                  <small className='text-danger'>
                    {getMessageErrorSubmit(messageErrorInput, 'height')}
                  </small>
                )}
              </Col>
            </Form.Group>
            <Form.Group as={Row} className='mb-3'>
              <Form.Label column sm={4}>
                La largeur du casier <span className='text-danger'>*</span>
              </Form.Label>
              <Col sm={8}>
                <InputGroup>
                  <Form.Control
                    type='text'
                    defaultValue={slotInput?.width}
                    {...register('width', { required: true })}
                  />
                  <InputGroup.Text>cm</InputGroup.Text>
                </InputGroup>
                {messageErrorInput && (
                  <small className='text-danger'>
                    {getMessageErrorSubmit(messageErrorInput, 'width')}
                  </small>
                )}
              </Col>
            </Form.Group>
            <Form.Group as={Row} className='mb-3'>
              <Form.Label column sm={4}>
                La profondeur du casier <span className='text-danger'>*</span>
              </Form.Label>
              <Col sm={8}>
                <InputGroup>
                  <Form.Control
                    type='text'
                    defaultValue={slotInput?.depth}
                    {...register('depth', { required: true })}
                  />
                  <InputGroup.Text>cm</InputGroup.Text>
                </InputGroup>
                {messageErrorInput && (
                  <small className='text-danger'>
                    {getMessageErrorSubmit(messageErrorInput, 'depth')}
                  </small>
                )}
              </Col>
            </Form.Group>
            <Form.Group as={Row} className='mb-3'>
              <Form.Label column sm={4}>
                Charge max <span className='text-danger'>*</span>
              </Form.Label>
              <Col sm={8}>
                <InputGroup>
                  <Form.Control
                    type='text'
                    defaultValue={slotInput?.maxWeight}
                    {...register('maxWeight', { required: true })}
                  />
                  <InputGroup.Text>kg</InputGroup.Text>
                </InputGroup>
                {messageErrorInput && (
                  <small className='text-danger'>
                    {getMessageErrorSubmit(messageErrorInput, 'maxWeight')}
                  </small>
                )}
              </Col>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant='secondary' onClick={handleClose}>
              Annuler
            </Button>
            <Button variant='dark' type='submit'>
              {onSubmitLoading ? <Spinner size={'sm'} /> : 'Enregistrer'}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  )
}
