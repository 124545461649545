import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import { UserAddForm } from "@/components/entities/user/UserAddForm";
import { Title } from "@/components/lib/Title";
import { ButtonLinkBack } from "@/components/lib/ButtonLinkBack";

export const UserAdd: React.FC = () => {
  /* Render
   *******************************************************************************************/
  return (
    <>
      <Row>
        <Col>
          <Title name={"Utilisateur / création"} />
        </Col>
        <Col>
          <ButtonLinkBack />
        </Col>
      </Row>
      <Row className="mt-4">
        <Col className="col-xl-6 col-lg-10 col-md-12 mx-auto">
          <Card className="mb-4">
            <Card.Header>
              <h5 className="mt-2">
                Ajouter les informations de l'utilisateur
              </h5>
            </Card.Header>
            <Card.Body className="p-5">
              <UserAddForm />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};
