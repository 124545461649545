import { ReactNode } from "react";
import { Navigate } from "react-router-dom";

type Props = {
  children: ReactNode;
};

export const PrivateRoute = ({ children }: Props) => {
  const isAuthenticated = localStorage.getItem(
    import.meta.env.VITE_APP_SESSION_TOKEN
  );
  return isAuthenticated ? (
    children
  ) : (
    <>
      <Navigate to="/connexion" replace />;
    </>
  );
};
