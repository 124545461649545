import AuthService from "@/services/Auth/AuthService";
import UserService from "@/services/User/UserService";
import { UserType } from "@/types/UserType";
import { flashStore } from "@/utils/Store";
import { AxiosError, AxiosResponse } from "axios";
import React from "react";
import { Badge, Card, Image } from "react-bootstrap";
import * as Sentry from "@sentry/react";
import { getRoleUserText, setDate } from "@/utils/Functions";
import { Link } from "react-router-dom";
import { Edit } from "lucide-react";
import { Loading } from "@/components/lib/Loading";
import { useUserRole } from "@/hooks/useUserRole";

type Props = {
  id_user: number | string | undefined;
  owner?: boolean;
};

export const UserDetailCard: React.FC<Props> = ({ id_user, owner }: Props) => {
  /* States / Hooks
   *******************************************************************************************/
  const [data, setData] = React.useState<UserType>();
  const flash = flashStore();
  const myRole = useUserRole();

  /* useEffect
   *******************************************************************************************/
  React.useEffect(() => {
    if (id_user) {
      getData(Number(id_user));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id_user]);

  /* Functions / Events
   *******************************************************************************************/
  const getData = (id_user: number) => {
    UserService.getUser(id_user)
      .then((response: AxiosResponse) => {
        setData(response.data);
      })
      .catch((error: AxiosError) => {
        AuthService.refreshToken();
        flash.setMessageErrorFetch();
        Sentry.captureException(error);
      });
  };

  return (
    <Card className="py-3">
      <Card.Body>
        {!data ? (
          <Loading />
        ) : (
          <>
            <div className="text-center">
              <Image
                src={"/img/image-dummy.png"}
                alt="Image"
                className="img-fluid mb-3"
                width={100}
              />
            </div>
            <div className="text-center mb-4">
              <Card.Title>{data?.firstName + " " + data?.lastName}</Card.Title>
              {owner ? (
                <Badge bg="danger pt-2 pb-1 mb-3">Propriétaire</Badge>
              ) : (
                <Badge bg="primary pt-2 pb-1 mb-3">
                  {getRoleUserText(data)}
                </Badge>
              )}
              <br />
              <small className="text-muted">
                Date création : {setDate(data?.createdAt)}
              </small>
            </div>
            <hr />
            {!owner && (
              <p>
                Username :<span className="float-end">{data?.username}</span>
              </p>
            )}
            <p>
              Email :<span className="float-end">{data?.email}</span>
            </p>
            <p>
              Tél :<span className="float-end">{data?.phone}</span>
            </p>
            {!owner && (
              <p>
                Statut :
                <span className="float-end">
                  {data?.enabled ? "Activé" : "Désactivé"}
                </span>
              </p>
            )}
            <hr />
            {myRole === import.meta.env.VITE_APP_ROLE_SUPER_ADMIN && (
              <Link
                className="btn btn-sm btn-dark d-block"
                to={"/utilisateurs/modification/" + data?.id}
              >
                <Edit className="me-2" size={20} />
                Modifier le profil
              </Link>
            )}
          </>
        )}
      </Card.Body>
    </Card>
  );
};
