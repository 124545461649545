import { Alert } from "react-bootstrap";

export const EmptyData = () => {
  return (

        <Alert variant="light" className="text-center pb-5">
          <i className="ri-information-line ri-4x"></i>
          <br />
          Aucun enregistrement ! <br />
        </Alert>

  );
};
