import React from "react";
import { Button, Col, Row, Form, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { UserType } from "@/types/UserType";
import { flashStore } from "@/utils/Store";
import UserService from "@/services/User/UserService";
import { AxiosError, AxiosResponse } from "axios";
import * as Sentry from "@sentry/react";
import { getMessageErrorSubmit } from "@/utils/Functions";
import AuthService from "@/services/Auth/AuthService";
import { Loading } from "@/components/lib/Loading";

interface ApiResponse {
  response: AxiosResponse;
  violations: Violation[];
}

interface Violation {
  propertyPath: string;
  message: string;
  code: string;
}

export const MyAccountUpdatePasswordForm = () => {
  /* States / Hooks
   *******************************************************************************************/
  const [onSubmitLoading, setOnSubmitLoading] = React.useState<boolean>(false);
  const [messageErrorInput, setMessageErrorInput] = React.useState();
  const navigate = useNavigate();
  const flash = flashStore();
  const { register, handleSubmit } = useForm<UserType>();
  const [data, setData] = React.useState<UserType>();
  const [userId, setUserId] = React.useState<number>();

  /* useEffect
   *******************************************************************************************/
  React.useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /* Functions / Events
   *******************************************************************************************/
  const getData = () => {
    UserService.getCurrentUser()
      .then((response: AxiosResponse) => {
        setData(response.data);
        setUserId(response.data.id);
      })
      .catch((error: AxiosError) => {
        AuthService.refreshToken();
        flash.setMessageErrorFetch();
        Sentry.captureException(error);
      });
  };

  const onSubmit = (data: UserType) => {
    setOnSubmitLoading(true);
    UserService.updatePassword(Number(userId), data)
      .then(() => {
        flash.setMessage("success", "Votre mot de passe a bien été modifié !");
        navigate(-1);
      })
      .catch((error: ApiResponse) => {
        if (
          error &&
          error.response.data["hydra:description"] == "Invalid password."
        ) {
          flash.setMessage("error", "Le mot de passe actuel est incorrect");
        } else if (error?.response?.data?.violations?.length > 0) {
          flash.setMessageErrorValidator();
          setMessageErrorInput(error.response.data.violations);
        } else {
          flash.setMessageErrorForm();
          Sentry.captureException(error);
        }
      })
      .finally(() => {
        setOnSubmitLoading(false);
      });
  };

  /* Render
   *******************************************************************************************/
  return (
    <>
      {!data ? (
        <Loading />
      ) : (
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={4}>
              Mot de passe actuel <span className="text-danger">*</span>
            </Form.Label>
            <Col sm={8}>
              <Form.Control
                type="password"
                {...register("currentPassword", { required: true })}
              />
              {messageErrorInput && (
                <small className="text-danger">
                  {getMessageErrorSubmit(messageErrorInput, "currentPassword")}
                </small>
              )}
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={4}>
              Nouveau mot de passe <span className="text-danger">*</span>
            </Form.Label>
            <Col sm={8}>
              <Form.Control
                type="password"
                {...register("newPassword", { required: true })}
              />
              {messageErrorInput && (
                <small className="text-danger">
                  {getMessageErrorSubmit(messageErrorInput, "newPassword")}
                </small>
              )}
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={4}>
              Confirmer le nouveau mot de passe{" "}
              <span className="text-danger">*</span>
            </Form.Label>
            <Col sm={8}>
              <Form.Control
                type="password"
                {...register("newPasswordRetyped", { required: true })}
              />
              {messageErrorInput && (
                <small className="text-danger">
                  {getMessageErrorSubmit(
                    messageErrorInput,
                    "newPasswordRetyped"
                  )}
                </small>
              )}
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3 float-end">
            <Col>
              <Button type="submit" variant="dark">
                {onSubmitLoading ? <Spinner size={"sm"} /> : "Enregistrer"}
              </Button>
            </Col>
          </Form.Group>
        </Form>
      )}
    </>
  );
};
