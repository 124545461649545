import { Outlet } from "react-router-dom";
import { FlashComponent } from "@/components/lib/Flash";

export const PublicLayout = () => {
  return (
    <>
      <FlashComponent />
      <div id="layout-wrapper">
        <Outlet />
      </div>
    </>
  );
};
