import { CompanyType } from '@/types/CompanyType'
import axios from 'axios'
import authHeader from '../Auth/AuthHeader'

const API_URL = import.meta.env.VITE_APP_END_POINT

class CompanyService {
  getAllCompany() {
    return axios.get(
      API_URL +
        'companies?q[]=id&q[]=cleveronCompanyId&q[]=name&q[]=contactName&q[]=contactEmail&q[]=contactPhone&q[]=createdAt',
      { headers: authHeader() }
    )
  }

  getCompanyPerPage(page: number, perPage: number) {
    return axios.get(
      API_URL +
        'companies?q[]=id&q[]=cleveronCompanyId&q[]=name&q[]=contactName&q[]=contactEmail&q[]=contactPhone&q[]=createdAt&page=' +
        page +
        '&itemsPerPage=' +
        perPage,
      {
        headers: authHeader(),
      }
    )
  }

  getCompanyLoadNextPage(uri: string | undefined) {
    const new_uri = uri?.replace('/api/', '')
    return axios.get(API_URL + new_uri, { headers: authHeader() })
  }

  getCompanyById(id: number) {
    return axios.get(API_URL + 'companies/' + id, { headers: authHeader() })
  }

  getCompanyByCleveronCompanyId(id: number) {
    return axios.get(API_URL + 'companies?cleveronCompanyId=' + id, {
      headers: authHeader(),
    })
  }

  getBookingsByCompanyId(id: number, page: number, perPage: number) {
    return axios.get(
      API_URL +
        'companies/' +
        id +
        '/bookings?q[]=id&q[]=capacity&q[]=available&q[]=active&q[company][]=cleveronCompanyId&q[company][]=name&q[slot][temperatureZone][]=name&q[slot][temperatureZone][locker][]=type&q[slot][temperatureZone][locker][]=location&&q[slot][]=size&q[slot][temperatureZone][]=myKey&q[slot][temperatureZone][locker][]=shortLocation&q[slot][]=height&q[slot][]=width&q[slot][]=depth&page=' +
        page +
        '&itemsPerPage=' +
        perPage,
      {
        headers: authHeader(),
      }
    )
  }

  getMembers(id: number, page: number, perPage: number) {
    return axios.get(
      API_URL + 'companies/' + id + '/members?page=' + page + '&itemsPerPage=' + perPage,
      {
        headers: authHeader(),
      }
    )
  }

  create(data: CompanyType) {
    return axios.post(API_URL + 'companies', data, { headers: authHeader() })
  }

  update(id: number, data: CompanyType | object) {
    axios.defaults.headers.patch['Content-Type'] = 'application/merge-patch+json'
    return axios.patch(API_URL + 'companies/' + id, data, {
      headers: authHeader(),
    })
  }

  updateOptionNotification(id: number, key: string, value: boolean) {
    let data: object
    axios.defaults.headers.patch['Content-Type'] = 'application/merge-patch+json'
    if (key === 'email') {
      data = { mailProviderActive: value }
    } else {
      data = { smsProviderActive: value }
    }
    return axios.patch(API_URL + 'companies/' + id, data, {
      headers: authHeader(),
    })
  }

  getBookingSlotsOfCompanies(page: number, perPage: number) {
    return axios.get(
      API_URL +
        'companies?q[]=name&q[]=id&q[]=cleveronCompanyId&q[bookingSlots][]=id&q[bookingSlots][slot][]=id&q[bookingSlots][slot][]=size&q[bookingSlots][]=active&q[bookingSlots][]=capacity&q[bookingSlots][]=available&q[bookingSlots][slot][]=width&q[bookingSlots][slot][]=capacity&q[bookingSlots][slot][]=available&q[bookingSlots][slot][]=height&q[bookingSlots][slot][]=depth&q[bookingSlots][slot][temperatureZone][]=id&q[bookingSlots][slot][temperatureZone][]=myKey&q[bookingSlots][slot][temperatureZone][]=name&q[bookingSlots][slot][temperatureZone][locker][]=id&q[bookingSlots][slot][temperatureZone][locker][]=type&q[bookingSlots][slot][temperatureZone][locker][]=location&q[bookingSlots][slot][temperatureZone][locker][]=shortLocation&page=' +
        page +
        '&itemsPerPage=' +
        perPage,
      { headers: authHeader() }
    )
  }

  addMember(
    id: number,
    data: {
      members: string[]
    }
  ) {
    axios.defaults.headers.patch['Content-Type'] = 'application/merge-patch+json'
    return axios.patch(API_URL + 'companies/' + id, data, {
      headers: authHeader(),
    })
  }

  addOwner(
    id: number,
    data: {
      owner: string
    }
  ) {
    axios.defaults.headers.patch['Content-Type'] = 'application/merge-patch+json'
    return axios.patch(API_URL + 'companies/' + id, data, {
      headers: authHeader(),
    })
  }

  _delete = async (id: number) => {
    return await axios.delete(API_URL + `companies/${id}`, {
      headers: authHeader(),
    })
  }
}

export default new CompanyService()
