import axios from "axios";
import authHeader from "../Auth/AuthHeader";
import { SlotType } from "@/types/SlotType";

const API_URL = import.meta.env.VITE_APP_END_POINT;

class SlotService {
  getSlotsByTemperatureZoneId(id: number) {
    return axios.get(API_URL + "temperature_zones/" + id + "/slots", {
      headers: authHeader(),
    });
  }
  getSlotById(id: number) {
    return axios.get(API_URL + "/" + id, {
      headers: authHeader(),
    });
  }
  getSlotByUri(uri: string) {
    const id = uri.substring(uri.lastIndexOf("/") + 1);
    return axios.get(API_URL + "slots/" + id, {
      headers: authHeader(),
    });
  }

  update(id: number, data: SlotType) {
    axios.defaults.headers.patch["Content-Type"] =
      "application/merge-patch+json";
    return axios.patch(API_URL + "slots/" + id, data, {
      headers: authHeader(),
    });
  }

  _delete = async (id: number) => {
    return await axios.delete(API_URL + `slots/${id}`, {
      headers: authHeader(),
    });
  };
}

export default new SlotService();
