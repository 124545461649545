import { Title } from "@/components/lib/Title";
import { ButtonLink } from "@/components/lib/ButtonLink";
import { Row, Col } from "react-bootstrap";
import { BookingSlotListTable } from "@/components/entities/booking_slot/BookingSlotListTable";
import { useUserRole } from "@/hooks/useUserRole";

export const BookingSlotList = () => {
  const myRole = useUserRole();
  /* Render
   *******************************************************************************************/
  return (
    <>
      <Row>
        <Col>
          <Title name={"Bookings"} />
        </Col>
        <Col>
          {myRole === import.meta.env.VITE_APP_ROLE_SUPER_ADMIN && (
            <ButtonLink
              name={"Nouvelle réservation"}
              href={"/bookings/creation"}
              icon={"Plus"}
            />
          )}
        </Col>
      </Row>
      <Row className="mt-4">
        <Col>
          <BookingSlotListTable />
        </Col>
      </Row>
    </>
  );
};
