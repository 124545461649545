import { Col, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { Title } from "@/components/lib/Title";
import { ButtonLinkBack } from "@/components/lib/ButtonLinkBack";
import { LockerDetailCard } from "@/components/entities/locker/LockerDetailCard";
import React from "react";
import { LockerType } from "@/types/LockerType";
import { flashStore } from "@/utils/Store";
import LockerService from "@/services/Locker/LockerService";
import { AxiosError, AxiosResponse } from "axios";
import AuthService from "@/services/Auth/AuthService";
import * as Sentry from "@sentry/react";
import { LockerSummaryCard } from "@/components/entities/locker/LockerSummaryCard";
import { LockerTemperatureZoneListTable } from "@/components/entities/locker/LockerTemperatureZoneListTable";

export const LockerDetail = () => {
  /* States / Hooks
   *******************************************************************************************/
  const param = useParams();
  const [data, setData] = React.useState<LockerType>();
  const flash = flashStore();

  /* useEffect
   *******************************************************************************************/
  React.useEffect(() => {
    if (param.id_locker) {
      getData(param.id_locker);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [param]);

  /* Functions / Events
   *******************************************************************************************/
  const getData = (id_locker: string) => {
    LockerService.getLocker(Number(id_locker))
      .then((response: AxiosResponse) => {
        setData(response.data);
      })
      .catch((error: AxiosError) => {
        AuthService.refreshToken();
        flash.setMessageErrorFetch();
        Sentry.captureException(error);
      });
  };

  /* Render
   *******************************************************************************************/
  return (
    <>
      <Row>
        <Col>
          <Title name={"Information du Locker"} />
        </Col>
        <Col>
          <ButtonLinkBack />
        </Col>
      </Row>
      <Row className="mt-4">
        <Col lg={3}>
          <LockerDetailCard data={data} />
        </Col>
        <Col lg={9}>
          <LockerSummaryCard data={data} />
          <LockerTemperatureZoneListTable id_locker={data?.id} />
        </Col>
      </Row>
    </>
  );
};
