import React from "react";
import * as Sentry from "@sentry/react";
import {
  MoreVertical,
  List,
  Pencil,
  Check,
  X,
  Trash2,
  Plus,
} from "lucide-react";
import { Dropdown, Card, Button, Table, Badge } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Loading } from "@/components/lib/Loading";
import { AxiosError, AxiosResponse } from "axios";
import UserService from "@/services/User/UserService";
import Swal, { SweetAlertResult } from "sweetalert2";
import { flashStore } from "@/utils/Store";
import { UserType } from "@/types/UserType";
import AuthService from "@/services/Auth/AuthService";
import { getRoleUserText, setDateTime } from "@/utils/Functions";
import { EmptyData } from "@/components/lib/EmptyData";
import { useUserRole } from "@/hooks/useUserRole";

interface ErrorResponse {
  response: AxiosResponse;
  hydra: {
    description: string;
  };
}

export const UserListTable = () => {
  /* States / Hooks
   *******************************************************************************************/
  const [data, setData] = React.useState<UserType[]>([]);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [nextLoading, setNextLoading] = React.useState<boolean>(false);
  const [nextPage, setNextPage] = React.useState<string | undefined>();
  const [currentPage] = React.useState<number>(1);
  const [perPage] = React.useState<number>(10);
  const flash = flashStore();
  const myRole = useUserRole();

  /* useEffect
   *******************************************************************************************/
  React.useEffect(() => {
    getData(currentPage, perPage);
  }, [currentPage, perPage]);

  /* Functions
   *******************************************************************************************/
  const getData = (page: number, perPage: number) => {
    UserService.getUserPerPage(page, perPage)
      .then((response: AxiosResponse) => {
        setData(response.data["hydra:member"]);
        setNextPage(response.data["hydra:view"]["hydra:next"]);
      })
      .catch((error: AxiosError) => {
        AuthService.refreshToken();
        Sentry.captureException(error);
      })
      .finally(() => setLoading(false));
  };

  const handleNextPage = () => {
    setNextLoading(true);
    UserService.getUserLoadNextPage(nextPage)
      .then((response: AxiosResponse) => {
        setData(data.concat(response.data["hydra:member"]));
        setNextPage(response.data["hydra:view"]["hydra:next"]);
      })
      .catch((error: AxiosError) => {
        AuthService.refreshToken();
        Sentry.captureException(error);
      })
      .finally(() => setNextLoading(false));
  };

  const handleDelete = (data: UserType) => {
    Swal.fire({
      title: "Confirmation !",
      text:
        "Voulez-vous vraiment supprimer " +
        data.firstName +
        " " +
        data.lastName +
        " ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Oui, je confirme",
      cancelButtonText: "Non",
    }).then((result: SweetAlertResult) => {
      if (result.isConfirmed) {
        UserService._delete(data.id)
          .then(() => {
            flash.setMessage("success", "Utilisateur supprimé !");
            getData(currentPage, perPage);
          })
          .catch((e: ErrorResponse) => {
            if (e?.response?.status == 403) {
              flash.setMessage("error", e?.response?.data["hydra:description"]);
            }
          });
      }
    });
  };

  const getCompaniesOfUser = (user: UserType) => {
    return user?.ownerships.length > 0 ? (
      user.ownerships.map((ownership) => (
        <Badge key={ownership["@id"]} className="me-2 mb-1 bg-secondary">
          {ownership.name}
        </Badge>
      ))
    ) : user?.memberships.length > 0 ? (
      user.memberships.map((membership) => (
        <Badge key={membership["@id"]} className="me-2 mb-1 bg-secondary">
          {membership.name}
        </Badge>
      ))
    ) : (
      <span className="text-danger">Aucune société</span>
    );
  };

  /* Render
   *******************************************************************************************/
  return (
    <Card>
      <>
        {loading ? (
          <Loading />
        ) : data?.length === 0 ? (
          <EmptyData />
        ) : (
          <>
            <Table striped hover>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Nom</th>
                  <th>Sociétés</th>
                  <th>Rôle</th>
                  <th>Adresse email</th>
                  <th>Téléphone</th>
                  <th>Statut</th>
                  <th>Date de création</th>
                  {myRole === import.meta.env.VITE_APP_ROLE_SUPER_ADMIN && (
                    <th>Actions</th>
                  )}
                </tr>
              </thead>
              <tbody>
                {data.map((user: UserType) => (
                  <tr key={user?.id}>
                    <td>
                      <Link to={"/utilisateurs/detail/" + user?.id}>
                        {user?.id}
                      </Link>
                    </td>
                    <td>
                      <Link to={"/utilisateurs/detail/" + user?.id}>
                        {user?.firstName + " " + user.lastName}
                      </Link>
                    </td>
                    <td width={200}>
                      {getRoleUserText(user) === "Super administrateur" ? (
                        <Badge>iTahiti Lab</Badge>
                      ) : (
                        getCompaniesOfUser(user)
                      )}
                    </td>
                    <td>
                      <Link to={"/utilisateurs/detail/" + user?.id}>
                        {getRoleUserText(user)}
                      </Link>
                    </td>
                    <td>
                      <Link to={"/utilisateurs/detail/" + user?.id}>
                        {user?.email}
                      </Link>
                    </td>
                    <td>
                      <Link to={"/utilisateurs/detail/" + user?.id}>
                        {user?.phone}
                      </Link>
                    </td>
                    <td>
                      {user?.enabled ? (
                        <Check className="text-success" />
                      ) : (
                        <X className="text-danger" />
                      )}
                    </td>
                    <td>
                      <Link to={"/utilisateurs/detail/" + user?.id}>
                        {setDateTime(user?.createdAt)}
                      </Link>
                    </td>
                    {(myRole === import.meta.env.VITE_APP_ROLE_SUPER_ADMIN ||
                      myRole === import.meta.env.VITE_APP_ROLE_ADMIN) && (
                      <td>
                        <Dropdown className="datatable-dropdown no-arrow">
                          <Dropdown.Toggle variant="secondary">
                            <MoreVertical />
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item>
                              <Link
                                to={"/utilisateurs/detail/" + user?.id}
                                className="py-2 d-block"
                              >
                                <List size={16} /> Détail
                              </Link>
                            </Dropdown.Item>
                            <Dropdown.Item>
                              <Link
                                to={"/utilisateurs/modification/" + user?.id}
                                className="py-2 d-block"
                              >
                                <Pencil size={16} /> Modifier
                              </Link>
                            </Dropdown.Item>
                            {user.ownerships.length === 0 &&
                              user.memberships.length === 0 && (
                                <Dropdown.Item
                                  onClick={() => handleDelete(user)}
                                  className="py-2 d-block"
                                >
                                  <Trash2 size={16} /> Supprimer
                                </Dropdown.Item>
                              )}
                          </Dropdown.Menu>
                        </Dropdown>
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </Table>
            {nextPage !== undefined && (
              <div className="ms-auto mb-3 me-3">
                <Button
                  variant="dark"
                  className="d-inline w-20"
                  onClick={handleNextPage}
                >
                  {nextLoading ? (
                    "En cours de chargement..."
                  ) : (
                    <>
                      <Plus size={20} /> Charger plus{" "}
                    </>
                  )}
                </Button>
              </div>
            )}
          </>
        )}
      </>
    </Card>
  );
};
