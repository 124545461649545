import axios from "axios";
import authHeader from "./../Auth/AuthHeader";
import { UserType } from "@/types/UserType";

const API_URL = import.meta.env.VITE_APP_END_POINT;

class UserService {
  getAllUser() {
    return axios.get(API_URL + "users?page=1&itemsPerPage=10000", {
      headers: authHeader(),
    });
  }

  getUserPerPage(page: number, perPage: number) {
    return axios.get(
      API_URL + "users?page=" + page + "&itemsPerPage=" + perPage,
      { headers: authHeader() }
    );
  }

  getUserLoadNextPage(uri: string | undefined) {
    const new_uri = uri?.replace("/api/", "");
    return axios.get(API_URL + new_uri, { headers: authHeader() });
  }

  getMembersPerPage(id_company: number, page: number, perPage: number) {
    return axios.get(
      API_URL +
        "companies/" +
        id_company +
        "/members?page=" +
        page +
        "&itemsPerPage=" +
        perPage,
      { headers: authHeader() }
    );
  }

  getUser(id: number) {
    return axios.get(API_URL + `users/${id}`, { headers: authHeader() });
  }

  getCurrentUser() {
    return axios.get(API_URL + "me", { headers: authHeader() });
  }

  create(data: UserType) {
    return axios.post(API_URL + "users", data, { headers: authHeader() });
  }

  update(id: number, data: UserType) {
    axios.defaults.headers.patch["Content-Type"] =
      "application/merge-patch+json";
    return axios.patch(API_URL + "users/" + id, data, {
      headers: authHeader(),
    });
  }

  _delete = async (id: number) => {
    return await axios.delete(API_URL + `users/${id}`, {
      headers: authHeader(),
    });
  };

  updatePassword(id: number, data: UserType) {
    axios.defaults.headers.patch["Content-Type"] =
      "application/merge-patch+json";
    return axios.patch(API_URL + "users/" + id + "/update-password", data, {
      headers: authHeader(),
    });
  }

  forgotPassword = (data: object) => {
    return axios.post(API_URL + "forgot-password", data, {
      headers: authHeader(),
    });
  };
}

export default new UserService();
