import "bootstrap/dist/css/bootstrap.min.css";
import "./layouts/assets/css/styles.css";
import "remixicon/fonts/remixicon.css";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Login } from "./pages/public/Login";
import { ForgotPassword } from "./pages/public/ForgotPassword";
import { ResetPassword } from "./pages/public/ResetPassword";
import { Dashboard } from "./pages/admin/Dashboard/Dashobard";
import { AdminLayout } from "./layouts/AdminLayout";
import { PublicLayout } from "./layouts/PublicLayout";
import { PrivateRoute } from "./middleware/PrivateRoute";
import { PublicRoute } from "./middleware/PublicRoute";
import { CompanyList } from "./pages/admin/Company/CompanyList";
import { CompanyAdd } from "./pages/admin/Company/CompanyAdd";
import { CompanyEdit } from "./pages/admin/Company/CompanyEdit";
import { UserList } from "./pages/admin/User/UserList";
import { UserAdd } from "./pages/admin/User/UserAdd";
import { UserEdit } from "./pages/admin/User/UserEdit";
import { UserAccount } from "./pages/admin/User/UserAccount";
import { CompanyDetail } from "./pages/admin/Company/CompanyDetail";
import { CompanyAddMember } from "./pages/admin/Company/CompanyAddMember";
import { LockerList } from "./pages/admin/Locker/LockerList";
import { LockerDetail } from "./pages/admin/Locker/LockerDetail";
import { LockerAdd } from "./pages/admin/Locker/LockerAdd";
import { BookingSlotList } from "./pages/admin/BookingSlot/BookingSlotList";
import { BookingSlotAdd } from "./pages/admin/BookingSlot/BookingSlotAdd";
import { OrderList } from "./pages/admin/Order/OrderList";
import { OrderDetail } from "./pages/admin/Order/OrderDetail";
import { UserUpdatePass } from "./pages/admin/User/UserUpdatePass";
import { MyAccount } from "./pages/admin/User/MyAccount";
import { MyAccountEdit } from "./pages/admin/User/MyAccountEdit";
import { MyAccountUpdatePassword } from "./pages/admin/User/MyAccountUpdatePassword";
import { ComponentTest } from "./pages/admin/ComponentTest/ComponentTest";
import { LockerEdit } from "./pages/admin/Locker/LockerEdit";
import { OrderAdd } from "./pages/admin/Order/OrderAdd";

// import * as Sentry from "@sentry/react";

// Sentry
// Sentry.init({
//   dsn: import.meta.env.VITE_APP_SENTRY,
//   integrations: [
//     new Sentry.BrowserTracing({
//       // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//       tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
//     }),
//     new Sentry.Replay(),
//   ],
//   // Performance Monitoring
//   tracesSampleRate: 1.0, // Capture 100% of the transactions
//   // Session Replay
//   replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//   replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// });

ReactDOM.createRoot(document.getElementById("root")!).render(
  // <React.StrictMode>
  <BrowserRouter>
    <Routes>
      {/* Public Routes */}
      <Route element={<PublicLayout />}>
        <Route
          path="/connexion"
          element={
            <PublicRoute>
              <Login />
            </PublicRoute>
          }
        />
        <Route
          path="/mot-de-passe-oublie"
          element={
            <PublicRoute>
              <ForgotPassword />
            </PublicRoute>
          }
        />
        <Route
          path="/reinitialisation-mot-de-passe/:token"
          element={
            <PublicRoute>
              <ResetPassword />
            </PublicRoute>
          }
        />
      </Route>
      {/* Admin > Dashboard */}
      <Route path="/" element={<AdminLayout />}>
        {/* ComponentTest */}
        <Route
          path="/composants"
          element={
            <PrivateRoute>
              <ComponentTest />
            </PrivateRoute>
          }
        />
        <Route
          index
          element={
            <PrivateRoute>
              <Dashboard />
            </PrivateRoute>
          }
        />
        <Route
          path="/societes"
          element={
            <PrivateRoute>
              <CompanyList />
            </PrivateRoute>
          }
        />
        <Route
          path="/societes/creation"
          element={
            <PrivateRoute>
              <CompanyAdd />
            </PrivateRoute>
          }
        />
        <Route
          path="/societes/modification/:id"
          element={
            <PrivateRoute>
              <CompanyEdit />
            </PrivateRoute>
          }
        />
        <Route
          path="/societes/:id_company/utilisateur/creation"
          element={
            <PrivateRoute>
              <CompanyAddMember />
            </PrivateRoute>
          }
        />
        <Route
          path="/societes/detail/:id_company"
          element={
            <PrivateRoute>
              <CompanyDetail />
            </PrivateRoute>
          }
        />
        <Route
          path="/utilisateurs"
          element={
            <PrivateRoute>
              <UserList />
            </PrivateRoute>
          }
        />
        <Route
          path="/utilisateurs/creation"
          element={
            <PrivateRoute>
              <UserAdd />
            </PrivateRoute>
          }
        />
        <Route
          path="/utilisateurs/modification/:id"
          element={
            <PrivateRoute>
              <UserEdit />
            </PrivateRoute>
          }
        />
        <Route
          path="/utilisateurs/modification/mot-de-passe/:id_user"
          element={
            <PrivateRoute>
              <UserUpdatePass />
            </PrivateRoute>
          }
        />
        <Route
          path="/mon-compte/modification/mot-de-passe"
          element={
            <PrivateRoute>
              <MyAccountUpdatePassword />
            </PrivateRoute>
          }
        />
        <Route
          path="/utilisateurs/detail/:id_user"
          element={
            <PrivateRoute>
              <UserAccount />
            </PrivateRoute>
          }
        />
        <Route
          path="/mon-compte"
          element={
            <PrivateRoute>
              <MyAccount />
            </PrivateRoute>
          }
        />
        <Route
          path="/mon-compte/modification"
          element={
            <PrivateRoute>
              <MyAccountEdit />
            </PrivateRoute>
          }
        />
        <Route
          path="/lockers"
          element={
            <PrivateRoute>
              <LockerList />
            </PrivateRoute>
          }
        />
        <Route
          path="/lockers/detail/:id_locker"
          element={
            <PrivateRoute>
              <LockerDetail />
            </PrivateRoute>
          }
        />
        <Route
          path="/lockers/creation"
          element={
            <PrivateRoute>
              <LockerAdd />
            </PrivateRoute>
          }
        />
        <Route
          path="/lockers/modification/:id_locker"
          element={
            <PrivateRoute>
              <LockerEdit />
            </PrivateRoute>
          }
        />
        <Route
          path="/bookings"
          element={
            <PrivateRoute>
              <BookingSlotList />
            </PrivateRoute>
          }
        />
        <Route
          path="/bookings/creation"
          element={
            <PrivateRoute>
              <BookingSlotAdd />
            </PrivateRoute>
          }
        />
        <Route
          path="/livraisons"
          element={
            <PrivateRoute>
              <OrderList />
            </PrivateRoute>
          }
        />
        <Route
          path="/livraisons/creation"
          element={
            <PrivateRoute>
              <OrderAdd />
            </PrivateRoute>
          }
        />
        <Route
          path="/livraisons/detail/:id_order"
          element={
            <PrivateRoute>
              <OrderDetail />
            </PrivateRoute>
          }
        />
      </Route>
    </Routes>
    {/* <Route path="*" element={<NotFound />} /> */}
  </BrowserRouter>
  // </React.StrictMode>
);
