import { Spinner } from "react-bootstrap";

export const Loading = () => {
  return (
    <div className="mx-auto text-center py-5">
      <Spinner animation="border" role="status" />
      <br />
      <small>Chargement...</small>
    </div>
  );
};
