import { Card, Col, Row, Alert } from "react-bootstrap";
import { CompanyAddForm } from "@/components/entities/company/CompanyAddForm";
import { Title } from "@/components/lib/Title";
import { ButtonLinkBack } from "@/components/lib/ButtonLinkBack";

export const CompanyAdd = () => {
  /* Render
   *******************************************************************************************/
  return (
    <>
      <Row>
        <Col>
          <Title name={"Société / création"} />
        </Col>
        <Col>
          <ButtonLinkBack />
        </Col>
      </Row>
      <Row className="mt-4">
        <Col className="col-xl-6 col-lg-10 col-md-12 mx-auto">
          <Card className="mb-4">
            <Card.Header>
              <h5 className="mt-2">Ajouter les informations de la société</h5>
            </Card.Header>
            <Card.Body className="p-5">
              <Alert variant="info" className="mb-5">
                <b>Note d'information !</b>
                <br />
                <small>
                  Avant tout, vérifiez que vous avez correctement rempli les
                  informations de la société dans le tableau de bord de Clever
                  Office.
                </small>
              </Alert>
              <CompanyAddForm />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};
