import axios from "axios";
import authHeader from "../Auth/AuthHeader";
import { TemperatureZoneType } from "@/types/TemperatureZoneType";

const API_URL = import.meta.env.VITE_APP_END_POINT;

class TemperatureZoneService {
  getTemperatureZonesByLockerId(id: number) {
    return axios.get(API_URL + "lockers/" + id + "/temperature_zones", {
      headers: authHeader(),
    });
  }

  getTemperatureZonesByUri(uri: string) {
    const id = uri.substring(uri.lastIndexOf("/") + 1);
    return axios.get(API_URL + "temperature_zones/" + id, {
      headers: authHeader(),
    });
  }

  getTemperatureZone(id: number) {
    return axios.get(API_URL + "temperature_zones/" + id, {
      headers: authHeader(),
    });
  }

  getSlotsByTemperatureZone(id: number) {
    return axios.get(API_URL + "temperature_zones/" + id + "?q[slots]=*", {
      headers: authHeader(),
    });
  }

  update(id: number, data: TemperatureZoneType) {
    axios.defaults.headers.patch["Content-Type"] =
      "application/merge-patch+json";
    return axios.patch(API_URL + "temperature_zones/" + id, data, {
      headers: authHeader(),
    });
  }

  _delete = async (id: number) => {
    return await axios.delete(API_URL + `temperature_zones/${id}`, {
      headers: authHeader(),
    });
  };
}

export default new TemperatureZoneService();
