import { LockerType } from '@/types/LockerType'
import React from 'react'
import { Card, Col, Row } from 'react-bootstrap'

type Props = {
  data: LockerType | undefined
}

export const LockerSummaryCard: React.FC<Props> = ({ data }: Props) => {
  return (
    <Row className='mb-4'>
      <Col lg={4} sm={12}>
        <Card>
          <Card.Body className='d-flex'>
            <div className='flex-shrink-0 avatar-sm me-3'>
              <div className='avatar-title text--primary fs-22 rounded'>
                <i className='ri-grid-line ri-2x'></i>
              </div>
            </div>
            <div className='flex-grow-1'>
              <h5 className='mb-0'>Total casiers</h5>
              <h6 className='mb-0 ms-1'>{data?.slotsInfos?.total}</h6>
            </div>
          </Card.Body>
        </Card>
      </Col>
      <Col lg={4} sm={12}>
        <Card>
          <Card.Body className='d-flex'>
            <div className='flex-shrink-0 avatar-sm me-3'>
              <div className='avatar-title text--primary fs-22 rounded'>
                <i className='ri-checkbox-line ri-2x'></i>
              </div>
            </div>
            <div className='flex-grow-1'>
              <h5 className='mb-0'>Casiers disponibles</h5>
              <h6 className='mb-0 ms-1'>{data?.slotsInfos?.available}</h6>
            </div>
          </Card.Body>
        </Card>
      </Col>
      <Col lg={4} sm={12}>
        <Card>
          <Card.Body className='d-flex'>
            <div className='flex-shrink-0 avatar-sm me-3'>
              <div className='avatar-title text--primary fs-22 rounded'>
                <i className='ri-door-lock-box-line ri-2x'></i>
              </div>
            </div>
            <div className='flex-grow-1'>
              <h5 className='mb-0'>Casiers réservés</h5>
              <h6 className='mb-0 ms-1'>{data?.slotsInfos?.rented}</h6>
            </div>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  )
}
