import { useUserRole } from '@/hooks/useUserRole'
import TemperatureZoneService from '@/services/TemperatureZone/TemperatureZoneService'
import { TemperatureZoneType } from '@/types/TemperatureZoneType'
import { flashStore } from '@/utils/Store'
import * as Sentry from '@sentry/react'
import { AxiosError, AxiosResponse } from 'axios'
import { Edit, Trash2 } from 'lucide-react'
import React from 'react'
import {
  Button,
  Card,
  Col,
  Form,
  InputGroup,
  Modal,
  Row,
  Spinner,
  Table,
} from 'react-bootstrap'
import Swal, { SweetAlertResult } from 'sweetalert2'
import { LockerTemperatureZoneSlotListTable } from './LockerTemperatureZoneSlotListTable'

type Props = {
  id_locker: number | undefined
}

export const LockerTemperatureZoneListTable: React.FC<Props> = ({ id_locker }: Props) => {
  /* States / Hooks
   *******************************************************************************************/
  const [data, setData] = React.useState<TemperatureZoneType[]>()
  const [temperatureZoneInput, setTemperatureZoneInput] = React.useState<TemperatureZoneType>()
  const [onSubmitLoading, setOnSubmitLoading] = React.useState<boolean>(false)
  const [show, setShow] = React.useState<boolean>(false)
  const flash = flashStore()
  const myRole = useUserRole()

  /* useEffect
   *******************************************************************************************/
  React.useEffect(() => {
    if (id_locker) {
      getData(id_locker)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id_locker])

  /* Functions / Events
   *******************************************************************************************/
  const getData = (id_locker: number) => {
    TemperatureZoneService.getTemperatureZonesByLockerId(Number(id_locker))
      .then((response: AxiosResponse) => {
        setData(response.data['hydra:member'])
      })
      .catch((error: AxiosError) => {
        flash.setMessageErrorFetch()
        Sentry.captureException(error)
      })
  }

  const handleShowEditForm = (temperatureZone: TemperatureZoneType) => {
    setTemperatureZoneInput(temperatureZone)
    setShow(true)
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    setOnSubmitLoading(true)
    // Récupérer les valeurs du formulaire
    const formData = new FormData(event.currentTarget)
    const id = formData.get('id') as string

    const setData: TemperatureZoneType = {
      name: formData.get('name') as string,
      myKey: formData.get('myKey') as string,
      currentTemp: parseInt(formData.get('currentTemp') as string, 10),
    }

    TemperatureZoneService.update(Number(id), setData)
      .then(() => {
        flash.setMessage('success', 'la zone #' + id + ' a bien été modifiée')
      })
      .catch((error: AxiosError) => {
        flash.setMessageErrorForm()
        Sentry.captureException(error)
      })
      .finally(() => {
        setOnSubmitLoading(false)
        setShow(false)
        getData(Number(id_locker))
      })
  }

  const handleDelete = (id_tz: number) => {
    Swal.fire({
      title: 'Confirmation !',
      text: 'Voulez-vous vraiment supprimer la zone #' + id_tz + ' ?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Oui, je confirme',
      cancelButtonText: 'Non',
    }).then((result: SweetAlertResult) => {
      if (result.isConfirmed) {
        TemperatureZoneService._delete(id_tz)
          .then(() => {
            flash.setMessage('success', 'Zone de température supprimée !')
            getData(Number(id_locker))
          })
          .catch((e: AxiosError) => {
            flash.setMessageErrorForm()
            Sentry.captureException(e)
          })
      }
    })
  }

  /* Render
   *******************************************************************************************/
  return (
    <>
      <Card className='mb-4'>
        <Card.Header>
          <Row>
            <Col>
              <h5 className='mt-2'>Informations supplémentaires</h5>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body className='pt-0'>
          <Table>
            <thead>
              <tr>
                <th>Zones</th>
                <th className='text-center'>Température</th>
                {myRole === import.meta.env.VITE_APP_ROLE_SUPER_ADMIN && (
                  <th className='text-center'>Actions</th>
                )}
                <th className='bg-gray-200 pt-3 text-center border'>Casiers</th>
              </tr>
            </thead>
            <tbody>
              {data?.map((tz: TemperatureZoneType) => (
                <tr key={tz?.id}>
                  <td>
                    {tz?.name} <small className='text-muted'>({tz?.myKey})</small>
                    <br />
                    <small className='text-muted'>({tz?.keyTemp})</small>
                  </td>
                  <td className='text-center'>{tz?.currentTemp}°</td>
                  {myRole === import.meta.env.VITE_APP_ROLE_SUPER_ADMIN && (
                    <td className='text-center'>
                      <Edit
                        className='cursor-pointer text-secondary'
                        onClick={() => handleShowEditForm(tz)}
                      />
                      <Trash2
                        className='cursor-pointer text-danger ms-2'
                        onClick={() => handleDelete(Number(tz?.id))}
                      />
                    </td>
                  )}
                  <td className='p-0 inline-table'>
                    <table className='w-100'>
                      <tbody>
                        <tr>
                          <td className='border-left bg-gray-200'>ID Casier</td>
                          <td className='border-left bg-gray-200'>Dimensions du casier</td>
                          <td className='border-left bg-gray-200'>Qté totale</td>
                          <td className='border-left bg-gray-200'>Qté disponible</td>
                          <td className='border-left bg-gray-200'>Charge max.</td>
                          {myRole === import.meta.env.VITE_APP_ROLE_SUPER_ADMIN && (
                            <td className='border-left bg-gray-200'>Actions</td>
                          )}
                        </tr>
                        <LockerTemperatureZoneSlotListTable id_temperature_zone={tz.id} />
                      </tbody>
                    </table>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
      {/* Modal Edit Form */}
      <Modal show={show} onHide={() => setShow(false)} size='lg'>
        <Form onSubmit={handleSubmit}>
          <Modal.Header closeButton className='bg-dark'>
            <Modal.Title className='text-white'>
              Modifier la zone de température #{temperatureZoneInput?.id}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className='p-5'>
            <input type='hidden' name='id' defaultValue={temperatureZoneInput?.id} />
            <Form.Group as={Row} className='mb-3'>
              <Form.Label column sm={4}>
                Nom de la température <span className='text-danger'>*</span>
              </Form.Label>
              <Col sm={8}>
                <InputGroup>
                  <Form.Control
                    type='text'
                    name='name'
                    defaultValue={temperatureZoneInput?.name}
                    placeholder='Ambiant, Froid positif...'
                  />
                </InputGroup>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className='mb-3'>
              <Form.Label column sm={4}>
                Température <span className='text-danger'>*</span>
              </Form.Label>
              <Col sm={8}>
                <InputGroup>
                  <Form.Control
                    type='number'
                    name='currentTemp'
                    defaultValue={temperatureZoneInput?.currentTemp}
                  />
                  <InputGroup.Text>°</InputGroup.Text>
                </InputGroup>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className='mb-3'>
              <Form.Label column sm={4}>
                Clé de température <span className='text-danger'>*</span>
              </Form.Label>
              <Col sm={8}>
                <Form.Select name='myKey' aria-label='currentTemp' className='form-control'>
                  <option>-- Sélectionnez --</option>
                  <option defaultValue='HT' selected={temperatureZoneInput?.myKey === 'HT'}>
                    HT
                  </option>
                  <option defaultValue='MT' selected={temperatureZoneInput?.myKey === 'MT'}>
                    MT
                  </option>
                  <option defaultValue='LT' selected={temperatureZoneInput?.myKey === 'LT'}>
                    LT
                  </option>
                </Form.Select>
              </Col>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant='secondary' onClick={() => setShow(false)}>
              Annuler
            </Button>
            <Button variant='dark' type='submit'>
              {onSubmitLoading ? <Spinner size={'sm'} /> : 'Enregistrer'}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  )
}
