import React, { ChangeEvent } from "react";
import { Button, Col, Row, Form, Spinner } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { UserType } from "@/types/UserType";
import { flashStore } from "@/utils/Store";
import UserService from "@/services/User/UserService";
import { AxiosError, AxiosResponse } from "axios";
import * as Sentry from "@sentry/react";
import { getMessageErrorSubmit, getRoleUser } from "@/utils/Functions";
import AuthService from "@/services/Auth/AuthService";
import { Loading } from "@/components/lib/Loading";
import { useJwt } from "react-jwt";
import { DecodedToken } from "@/types/DecodeTokenType";

interface ApiResponse {
  response: AxiosResponse;
  violations: Violation[];
}

interface Violation {
  propertyPath: string;
  message: string;
  code: string;
}
export const MyAccountEditForm = () => {
  /* States / Hooks
   *******************************************************************************************/
  const [onSubmitLoading, setOnSubmitLoading] = React.useState<boolean>(false);
  const [messageErrorInput, setMessageErrorInput] = React.useState();
  const [inputApmAccessCode, setInputApmAccessCode] =
    React.useState<boolean>(false);
  const navigate = useNavigate();
  const flash = flashStore();
  const { register, handleSubmit } = useForm<UserType>();
  const [data, setData] = React.useState<UserType>();
  const [userId, setUserId] = React.useState<number>();

  // MiddleWare
  const [myRole, setMyRole] = React.useState<string>();
  const session_storage = localStorage.getItem(
    import.meta.env.VITE_APP_SESSION_TOKEN
  );
  const result = useJwt(session_storage || "");
  const decodedToken: DecodedToken | null = (
    result as { decodedToken: DecodedToken | null }
  )?.decodedToken;

  /* useEffect
   *******************************************************************************************/
  React.useEffect(() => {
    getMe();

    // MiddleWare
    if (decodedToken) {
      setMyRole(decodedToken?.roles?.[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [decodedToken]);

  /* Functions / Events
   *******************************************************************************************/
  const getMe = () => {
    UserService.getCurrentUser()
      .then((response: AxiosResponse) => {
        setData(response.data);
        setUserId(response.data.id);
      })
      .catch((error: AxiosError) => {
        AuthService.refreshToken();
        flash.setMessageErrorFetch();
        Sentry.captureException(error);
      });
  };

  const onSubmit = (data: UserType) => {
    setOnSubmitLoading(true);
    // Formatter les données
    if (
      myRole === import.meta.env.VITE_APP_ROLE_ADMIN ||
      myRole === import.meta.env.VITE_APP_ROLE_SUPER_ADMIN
    ) {
      if (data["roles"].includes("ROLE_SHIPPER")) {
        data["apmAccessCode"] = Number(data.apmAccessCode);
        data["roles"] = Array.isArray(data.roles) ? data.roles : [data.roles];
      } else {
        data["roles"] = Array.isArray(data.roles) ? data.roles : [data.roles];
      }
    }

    UserService.update(Number(userId), data)
      .then(() => {
        flash.setMessage("success", "Vontre compte a bien été modifié !");
        navigate(-1);
      })
      .catch((error: ApiResponse) => {
        if (error?.response?.data?.violations?.length > 0) {
          flash.setMessageErrorValidator();
          setMessageErrorInput(error.response.data.violations);
        } else {
          flash.setMessageErrorForm();
          Sentry.captureException(error);
        }
      })
      .finally(() => {
        setOnSubmitLoading(false);
      });
  };

  const handleSelectChange = (selectedValue: string) => {
    if (selectedValue == import.meta.env.VITE_APP_ROLE_SHIPPER) {
      setInputApmAccessCode(true);
    } else {
      setInputApmAccessCode(false);
    }
  };

  /* Render
   *******************************************************************************************/
  return (
    <>
      {!data ? (
        <Loading />
      ) : (
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={3}>
              Nom <span className="text-danger">*</span>
            </Form.Label>
            <Col sm={9}>
              <Form.Control
                type="text"
                {...register("lastName", { required: true })}
                defaultValue={data?.lastName}
              />
              {messageErrorInput && (
                <small className="text-danger">
                  {getMessageErrorSubmit(messageErrorInput, "lastName")}
                </small>
              )}
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={3}>
              Prénom <span className="text-danger">*</span>
            </Form.Label>
            <Col sm={9}>
              <Form.Control
                type="text"
                defaultValue={data?.firstName}
                {...register("firstName", { required: true })}
              />
              {messageErrorInput && (
                <small className="text-danger">
                  {getMessageErrorSubmit(messageErrorInput, "firstName")}
                </small>
              )}
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={3}>
              Adresse email <span className="text-danger">*</span>
            </Form.Label>
            <Col sm={9}>
              <Form.Control
                type="email"
                defaultValue={data?.email}
                {...register("email", { required: true })}
              />
              {messageErrorInput && (
                <small className="text-danger">
                  {getMessageErrorSubmit(messageErrorInput, "email")}
                </small>
              )}
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={3}>
              N° portable <span className="text-danger">*</span>
            </Form.Label>
            <Col sm={9}>
              <Form.Control
                type="number"
                defaultValue={data?.phone}
                {...register("phone", { required: true })}
              />
              {messageErrorInput && (
                <small className="text-danger">
                  {getMessageErrorSubmit(messageErrorInput, "phone")}
                </small>
              )}
            </Col>
          </Form.Group>
          <hr />
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={3}>
              Identifiant <span className="text-danger">*</span>
            </Form.Label>
            <Col sm={9}>
              <Form.Control
                type="text"
                defaultValue={data?.username}
                {...register("username", { required: true })}
              />
              {messageErrorInput && (
                <small className="text-danger">
                  {getMessageErrorSubmit(messageErrorInput, "username")}
                </small>
              )}
            </Col>
          </Form.Group>
          {myRole === import.meta.env.VITE_APP_ROLE_ADMIN ||
            (myRole === import.meta.env.VITE_APP_ROLE_SUPER_ADMIN && (
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm={3}>
                  Rôle <span className="text-danger">*</span>
                </Form.Label>
                <Col sm={9}>
                  {data && (
                    <Form.Select
                      aria-label="Rôle de l'utilisateur"
                      className="form-control"
                      defaultValue={String(getRoleUser(data))}
                      {...register("roles", { required: true })}
                      onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                        handleSelectChange(e.currentTarget.value)
                      }
                    >
                      <option>-- Choisissez un rôle --</option>
                      <option value="ROLE_SUPER_ADMIN">
                        Super administrateur
                      </option>
                      <option value={import.meta.env.VITE_APP_ROLE_ADMIN}>
                        Administrateur
                      </option>
                      <option value={import.meta.env.VITE_APP_ROLE_MEMBER}>
                        Membre
                      </option>
                      <option value={import.meta.env.VITE_APP_ROLE_SHIPPER}>
                        Livreur
                      </option>
                    </Form.Select>
                  )}
                  {messageErrorInput && (
                    <small className="text-danger">
                      {getMessageErrorSubmit(messageErrorInput, "roles")}
                    </small>
                  )}
                </Col>
              </Form.Group>
            ))}
          {inputApmAccessCode && (
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={3}>
                Apm Access Code <span className="text-danger">*</span>
              </Form.Label>
              <Col sm={9}>
                <Form.Control
                  type="text"
                  {...register("apmAccessCode", { required: true })}
                />
                <small className="text-muted">
                  Un code qui permet de s'authentifier auprès du Locker
                </small>
                {messageErrorInput && (
                  <small className="text-danger">
                    {getMessageErrorSubmit(messageErrorInput, "apmAccessCode")}
                  </small>
                )}
              </Col>
            </Form.Group>
          )}
          {myRole === import.meta.env.VITE_APP_ROLE_ADMIN ||
            (myRole === import.meta.env.VITE_APP_ROLE_SUPER_ADMIN && (
              <Form.Group as={Row} className="mb-3">
                <Col sm={{ span: 9, offset: 3 }}>
                  <div className="form-check">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="enabled"
                      {...register("enabled")}
                      defaultChecked={data?.enabled ? true : false}
                    />
                    <label className="form-check-label" htmlFor="enabled">
                      Activer mon compte
                    </label>
                  </div>
                </Col>
              </Form.Group>
            ))}
          <Form.Group as={Row} className="mb-3">
            <Col sm={{ span: 9, offset: 3 }}>
              <Link to={"/mon-compte/modification/mot-de-passe"}>
                + Modifier mon mot de passe
              </Link>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3 float-end">
            <Col>
              <Button type="submit" variant="dark">
                {onSubmitLoading ? <Spinner size={"sm"} /> : "Enregistrer"}
              </Button>
            </Col>
          </Form.Group>
        </Form>
      )}
    </>
  );
};
