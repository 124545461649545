import { LockerAddForm } from '@/components/entities/locker/LockerAddForm'
import { ButtonLinkBack } from '@/components/lib/ButtonLinkBack'
import { Title } from '@/components/lib/Title'
import React from 'react'
import { Col, Row } from 'react-bootstrap'

export const LockerAdd: React.FC = () => {
  /* Render
   *******************************************************************************************/
  return (
    <>
      <Row>
        <Col>
          <Title name={'Machine / création'} />
        </Col>
        <Col>
          <ButtonLinkBack />
        </Col>
      </Row>
      <Row className='mt-4'>
        <Col className='col-xl-6 col-lg-10 col-md-12 mx-auto'>
          <LockerAddForm />
        </Col>
      </Row>
    </>
  )
}
