import { Col, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { CompanyDetailCard } from "@/components/entities/company/CompanyDetailCard";

import { Title } from "@/components/lib/Title";
import { ButtonLinkBack } from "@/components/lib/ButtonLinkBack";
import { CompanyUserListTable } from "@/components/entities/company/CompanyUserListTable";
import { CompanyBookingSlotListTable } from "@/components/entities/company/CompanyBookingSlotListTable";

export const CompanyDetail = () => {
  /* States / Hooks
   *******************************************************************************************/
  const param = useParams();

  /* Render
   *******************************************************************************************/
  return (
    <>
      <Row>
        <Col>
          <Title name={"Société"} />
        </Col>
        <Col>
          <ButtonLinkBack />
        </Col>
      </Row>
      <Row className="mt-4">
        <Col lg={3}>
          <CompanyDetailCard id_company={param.id_company} />
        </Col>
        <Col lg={9}>
          <CompanyUserListTable id_company={param.id_company} />
          <br />
          <CompanyBookingSlotListTable id_company={param.id_company} />
        </Col>
      </Row>
    </>
  );
};
