import { LockerListTable } from '@/components/entities/locker/LockerListTable'
import { ButtonLink } from '@/components/lib/ButtonLink'
import { Title } from '@/components/lib/Title'
import { useUserRole } from '@/hooks/useUserRole'
import { Col, Row } from 'react-bootstrap'

export const LockerList = () => {
  const myRole = useUserRole()

  /* Render
   *******************************************************************************************/
  return (
    <>
      <Row>
        <Col>
          <Title name={'Machines'} />
        </Col>
        <Col>
          {myRole === import.meta.env.VITE_APP_ROLE_SUPER_ADMIN && (
            <ButtonLink name={'Nouvelle machine'} href={'/lockers/creation'} icon={'Plus'} />
          )}
        </Col>
      </Row>
      <Row className='mt-4'>
        <Col>
          <LockerListTable />
        </Col>
      </Row>
    </>
  )
}
