import axios from "axios";
import authHeader from "../Auth/AuthHeader";
import { BookingSlotType } from "@/types/BookingSlotType";

const API_URL = import.meta.env.VITE_APP_END_POINT;

class CompanyService {
  getAll() {
    return axios.get(API_URL + "/booking_slots", { headers: authHeader() });
  }

  getBookingSlotPerPage(page: number, perPage: number) {
    return axios.get(
      API_URL + "/booking_slots?page=" + page + "&itemsPerPage=" + perPage,
      { headers: authHeader() }
    );
  }

  _delete = async (id: number) => {
    return await axios.delete(API_URL + `booking_slots/${id}`, {
      headers: authHeader(),
    });
  };

  create(data: object) {
    return axios.post(API_URL + "booking_slots", data, {
      headers: authHeader(),
    });
  }

  update(id: number, data: BookingSlotType) {
    axios.defaults.headers.patch["Content-Type"] =
      "application/merge-patch+json";
    return axios.patch(API_URL + "booking_slots/" + id, data, {
      headers: authHeader(),
    });
  }
}

export default new CompanyService();
