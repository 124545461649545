import axios, { AxiosError, AxiosResponse } from "axios";
import authHeader from "./AuthHeader";

interface SessionUser {
  token: string;
  refresh_token: string;
}

const API_URL = import.meta.env.VITE_APP_END_POINT;

class AuthService {
  login(username: string, password: string) {
    return axios.post(API_URL + "login_check", {
      username,
      password,
    });
  }

  _login(email: string, password: string) {
    return axios
      .post(API_URL + "login", {
        email,
        password,
      })
      .then((response) => {
        if (response.data.accessToken) {
          localStorage.setItem(
            import.meta.env.VITE_APP_SESSION_TOKEN,
            JSON.stringify(response.data)
          );
        }
        return response.data;
      });
  }

  logout() {
    localStorage.removeItem(import.meta.env.VITE_APP_SESSION_TOKEN);
  }

  register(email: string, password: string) {
    return axios.post(API_URL + "register", {
      email,
      password,
    });
  }

  getCurrentUser() {
    return JSON.parse(
      localStorage.getItem(import.meta.env.VITE_APP_SESSION_TOKEN) || "{}"
    );
  }

  getMe() {
    return axios.get(API_URL + "me", { headers: authHeader() });
  }

  getInfo() {
    return axios.get(API_URL + "me?q[]=firstName&q[]=roles", {
      headers: authHeader(),
    });
  }

  refreshToken() {
    const sessionUserString = localStorage.getItem(
      import.meta.env.VITE_APP_SESSION_TOKEN
    );
    const sessionUser: SessionUser =
      sessionUserString && JSON.parse(sessionUserString);

    return axios
      .post(API_URL + "token/refresh", {
        refresh_token: sessionUser.refresh_token,
      })
      .then((response: AxiosResponse) => {
        localStorage.setItem(
          import.meta.env.VITE_APP_SESSION_TOKEN,
          JSON.stringify(response.data)
        );
      })
      .catch((error: AxiosError) => {
        if (error) {
          this.logout();
          window.location.reload();
        }
      });
  }

  forgotPassword(email: string, url: string) {
    return axios.post(API_URL + "forgot-password", {
      email,
      url,
    });
  }

  resetPassword(password: string, retypedPassword: string, token: string) {
    return axios.post(API_URL + "forgot-password/" + token, {
      password,
      retypedPassword,
    });
  }
}

export default new AuthService();
