import { Row, Col } from "react-bootstrap";
import { Title } from "@/components/lib/Title";
import { ButtonLink } from "@/components/lib/ButtonLink";
import { OrderListTable } from "@/components/entities/order/OrderListTable";

export const OrderList = () => {
  /* Render
   *******************************************************************************************/
  return (
    <>
      <Row>
        <Col>
          <Title name={"Livraisons"} />
        </Col>
        <Col>
          <ButtonLink
            name={"Nouvelle livraison"}
            href={"/livraisons/creation"}
            icon={"Plus"}
          />
        </Col>
      </Row>
      <Row className="mt-4">
        <Col>
          <OrderListTable />
        </Col>
      </Row>
    </>
  );
};
