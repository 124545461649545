import LockerService from '@/services/Locker/LockerService'
import { flashStore } from '@/utils/Store'
import { AxiosResponse } from 'axios'
import React from 'react'
import { Alert, Card, Spinner } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'

interface ApiResponse {
  response: AxiosResponse
  violations: Violation[]
}

interface Violation {
  propertyPath: string
  message: string
  code: string
}

interface Slot {
  size: string
  capacity: number
  maxVolume: number
  maxWeight: number
  width: number
  height: number
  depth: number
}

interface Zone {
  key: string
  name: string
  currentTemp: number
  slots: Slot[]
}

interface Locker {
  image: string
  location: string
  shortLocation: string
  cleveronApmId: string
  latitude: number
  longitude: number
  city: string
  type: number
  zones: object
}

export const LockerAddForm = () => {
  /* Hooks
   *************************************************************/
  const [submitLoading, setSubmitLoading] = React.useState<boolean>(false)
  const [stepCreation, setStepCreation] = React.useState<string>('')
  const navigate = useNavigate()
  const flash = flashStore()
  const [ZoneSlotInput, setZoneSlotInput] = React.useState([
    {
      key: '',
      name: '',
      currentTemp: 0,
      slots: [
        {
          size: '',
          capacity: 0,
          maxVolume: 0,
          maxWeight: 0,
          width: 0,
          height: 0,
          depth: 0,
        },
      ],
    },
  ])
  const [locker, setLocker] = React.useState({
    location: '',
    shortLocation: '',
    cleveronApmId: '',
    latitude: 0,
    longitude: 0,
    city: '',
    type: 0,
    zones: [],
  })

  /* Function Event
   *******************************************************************************************/
  const handleChangeLocker = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target
    setLocker((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const addNewZone = () => {
    const zone = {
      key: '',
      name: '',
      currentTemp: 0,
      slots: [
        {
          size: '',
          capacity: 0,
          maxVolume: 0,
          maxWeight: 0,
          width: 0,
          height: 0,
          depth: 0,
        },
      ],
    }

    setZoneSlotInput([...ZoneSlotInput, zone])
  }

  const removeZone = (index: number) => {
    ZoneSlotInput.splice(index, 1)
  }

  const handleAddSlot = (zoneIndex: number) => {
    setZoneSlotInput((prevState) => {
      const updatedZoneSlotInput = [...prevState]
      updatedZoneSlotInput[zoneIndex].slots.push({
        size: '',
        capacity: 0,
        maxVolume: 0,
        maxWeight: 0,
        width: 0,
        height: 0,
        depth: 0,
      })
      return updatedZoneSlotInput
    })
  }

  const handleRemoveSlot = (zoneIndex: number, slotIndex: number) => {
    setZoneSlotInput((prevState) => {
      const updatedZoneSlotInput = [...prevState]
      updatedZoneSlotInput[zoneIndex].slots.splice(slotIndex, 1)
      return updatedZoneSlotInput
    })
  }

  const handleNameChange = (index: number, param: string) => {
    setZoneSlotInput((prevState) => {
      const updatedZoneSlotInput = [...prevState]
      updatedZoneSlotInput[index].name = param
      return updatedZoneSlotInput
    })
  }

  const handleTemperatureChange = (index: number, param: number) => {
    setZoneSlotInput((prevState) => {
      const updatedZoneSlotInput = [...prevState]
      updatedZoneSlotInput[index].currentTemp = param
      return updatedZoneSlotInput
    })
  }

  const handleKeyChange = (index: number, param: string) => {
    setZoneSlotInput((prevState) => {
      const updatedZoneSlotInput = [...prevState]
      updatedZoneSlotInput[index].key = param
      return updatedZoneSlotInput
    })
  }

  const handleCapacityChange = (zoneIndex: number, slotIndex: number, newCapacity: number) => {
    setZoneSlotInput((prevState) => {
      const updatedZoneSlotInput = [...prevState]
      updatedZoneSlotInput[zoneIndex].slots[slotIndex].capacity = newCapacity
      return updatedZoneSlotInput
    })
  }

  const handleDepthChange = (zoneIndex: number, slotIndex: number, newDepth: number) => {
    setZoneSlotInput((prevState) => {
      const updatedZoneSlotInput = [...prevState]
      updatedZoneSlotInput[zoneIndex].slots[slotIndex].depth = newDepth
      updatedZoneSlotInput[zoneIndex].slots[slotIndex].maxVolume =
        updatedZoneSlotInput[zoneIndex].slots[slotIndex].height *
        updatedZoneSlotInput[zoneIndex].slots[slotIndex].width *
        newDepth
      return updatedZoneSlotInput
    })
  }

  const handleHeightChange = (zoneIndex: number, slotIndex: number, newHeight: number) => {
    setZoneSlotInput((prevState) => {
      const updatedZoneSlotInput = [...prevState]
      updatedZoneSlotInput[zoneIndex].slots[slotIndex].height = newHeight
      updatedZoneSlotInput[zoneIndex].slots[slotIndex].maxVolume =
        newHeight *
        updatedZoneSlotInput[zoneIndex].slots[slotIndex].width *
        updatedZoneSlotInput[zoneIndex].slots[slotIndex].depth
      return updatedZoneSlotInput
    })
  }

  const handleWidthChange = (zoneIndex: number, slotIndex: number, newWidth: number) => {
    setZoneSlotInput((prevState) => {
      const updatedZoneSlotInput = [...prevState]
      updatedZoneSlotInput[zoneIndex].slots[slotIndex].width = newWidth
      updatedZoneSlotInput[zoneIndex].slots[slotIndex].maxVolume =
        updatedZoneSlotInput[zoneIndex].slots[slotIndex].height *
        newWidth *
        updatedZoneSlotInput[zoneIndex].slots[slotIndex].depth
      return updatedZoneSlotInput
    })
  }

  const handleMaxWeightChange = (
    zoneIndex: number,
    slotIndex: number,
    newMaxWeight: number
  ) => {
    setZoneSlotInput((prevState) => {
      const updatedZoneSlotInput = [...prevState]
      updatedZoneSlotInput[zoneIndex].slots[slotIndex].maxWeight = newMaxWeight
      return updatedZoneSlotInput
    })
  }

  const handleSizeChange = (zoneIndex: number, slotIndex: number, newSize: string) => {
    setZoneSlotInput((prevState) => {
      const updatedZoneSlotInput = [...prevState]
      updatedZoneSlotInput[zoneIndex].slots[slotIndex].size = newSize
      return updatedZoneSlotInput
    })
  }

  const createLocker = () => {
    const zones: { [key: string]: Zone } = {}

    ZoneSlotInput.forEach((item, index) => {
      const zoneItem = {
        key: item.key,
        name: item.name,
        currentTemp: item.currentTemp,
        slots: item.slots,
      }

      zones[`${index}`] = zoneItem
    })

    const data: Locker = {
      image: '/img/temp/apm-' + locker.type + '.png',
      location: locker.location,
      shortLocation: locker.shortLocation,
      cleveronApmId: locker.cleveronApmId,
      latitude: Number(locker.latitude),
      longitude: Number(locker.longitude),
      city: locker.city,
      type: Number(locker.type),
      zones,
    }

    setSubmitLoading(true)
    setStepCreation('Création de la machine en cours...')

    LockerService.create(data)
      .then((response: AxiosResponse) => {
        setTimeout(() => {
          flash.setMessage('success', 'La machine a bien été créée !')
          navigate('/lockers/detail/' + response.data.id)
        }, 1000)
      })
      .catch((error: ApiResponse) => {
        setSubmitLoading(false)
        flash.setMessage(
          'error',
          'Erreur dans le formulare ! Veuillez vérifier tous vos champs'
        )
        if (error?.response?.data?.violations?.length > 0) {
          // console.log("sentry");
        }
      })
  }

  /* Render
   *******************************************************************************************/
  return (
    <>
      {/* Infos Machine */}
      <Card className='mb-4'>
        <Card.Header>
          <h5 className='mt-2'>Renseignez les informations de la machine</h5>
        </Card.Header>
        <Card.Body className='p-5'>
          {submitLoading && (
            <div className='bg-loading-form-2'>
              <Spinner variant={'dark'} />
              <h6>{stepCreation}</h6>
            </div>
          )}
          <Alert variant='info' className='mb-5'>
            <b>Note d'information !</b>
            <br />
            <small>
              Avant tout, vérifiez que vous avez correctement rempli les informations de la
              machine dans le backoffice de Clever Office.
            </small>
          </Alert>

          <div className='row mb-3'>
            <label htmlFor='inputApmId' className='col-sm-3 col-form-label'>
              Apm ID <span className='text-danger'>*</span>
            </label>
            <div className='col-sm-9'>
              <input
                type='text'
                name='cleveronApmId'
                onChange={handleChangeLocker}
                className='form-control mb-0'
                required
              />
              <small className='text-muted'>
                Fait référence à l'external ID de la machine créée dans Clever Office
              </small>
            </div>
          </div>
          <div className='row mb-3'>
            <label htmlFor='inputTypeLocker' className='col-sm-3 col-form-label'>
              Type <span className='text-danger'>*</span>
            </label>
            <div className='col-sm-9'>
              <input
                type='number'
                name='type'
                onChange={handleChangeLocker}
                className='form-control'
                required
              />
            </div>
          </div>

          <div className='row mb-3'>
            <label htmlFor='inputLocation' className='col-sm-3 col-form-label'>
              Localisation <span className='text-danger'>*</span>
            </label>
            <div className='col-sm-9'>
              <input
                className='form-control mb-0'
                type='text'
                name='location'
                onChange={handleChangeLocker}
                required
              />
              <small className='text-muted'>
                Lieu d'entreprosage (Ex: Parking de Carrefour Punaauia)
              </small>
            </div>
          </div>
          <div className='row mb-3'>
            <label htmlFor='inputLocation' className='col-sm-3 col-form-label'>
              Nom utilisé <span className='text-danger'>*</span>
            </label>
            <div className='col-sm-9'>
              <input
                className='form-control mb-0'
                type='text'
                name='shortLocation'
                onChange={handleChangeLocker}
                required
              />
              <small className='text-muted'>Valeur affichée dans l'application mobile.</small>
            </div>
          </div>

          <div className='row mb-3'>
            <label htmlFor='inputCommune' className='col-sm-3 col-form-label'>
              Commune <span className='text-danger'>*</span>
            </label>
            <div className='col-sm-9'>
              <input
                type='text'
                name='city'
                onChange={handleChangeLocker}
                className='form-control mb-0'
                required
              />
            </div>
          </div>

          <div className='row mb-3'>
            <label htmlFor='inputGeoloc' className='col-sm-3 col-form-label'>
              Géolocalisation <span className='text-danger'>*</span>
            </label>
            <div className='col-sm-4'>
              <input
                type='number'
                name='longitude'
                onChange={handleChangeLocker}
                className='form-control mb-0'
                required
              />
            </div>
            <div className='col-sm-5'>
              <input
                className='form-control mb-0'
                type='number'
                name='latitude'
                onChange={handleChangeLocker}
                required
              />
            </div>
          </div>
        </Card.Body>
      </Card>
      {/* Zone de température */}
      {ZoneSlotInput.map((zone: Zone, index: number) => (
        <div className='card mb-4' key={index}>
          <a
            href={'#collapseCardTempZone' + index}
            className='d-block card-header py-3'
            data-toggle='collapse'
            role='button'
            aria-expanded='true'
            aria-controls={'collapseCardTempZone' + index}
          >
            <span className='float-end mt--1 me-2' onClick={() => removeZone(index)}>
              <strong>X</strong>
            </span>
            <h6 className='m-0 fs-5'>Zone de température</h6>
          </a>

          <div className='collapse' id={'collapseCardTempZone' + index}>
            <div className='card-body'>
              {/* Row Form Zone */}
              <div className='row mb-3'>
                <div className='col-sm-4'>
                  <input
                    type='text'
                    className='form-control mb-0'
                    placeholder='Nom'
                    required
                    onChange={(e) => handleNameChange(index, e.target.value)}
                  />
                </div>
                <div className='col-sm-4'>
                  <input
                    type='number'
                    className='form-control mb-0'
                    placeholder='Température'
                    required
                    onChange={(e) => handleTemperatureChange(index, parseInt(e.target.value))}
                  />
                </div>
                <div className='col-sm-4'>
                  <input
                    type='text'
                    className='form-control mb-0'
                    placeholder='Mot clé (MT/LT)'
                    required
                    onChange={(e) => handleKeyChange(index, e.target.value)}
                  />
                </div>
              </div>

              <>
                {/* Row Form Slot */}
                {zone.slots.map((slot: Slot, slotIndex: number) => (
                  <div key={slotIndex} className='card mb-4'>
                    <a
                      href={'#collapseCardExample' + slotIndex}
                      className='d-block card-header bg-secondary py-3'
                      data-toggle='collapse'
                      role='button'
                      aria-expanded='true'
                      aria-controls={'collapseCardExample' + slotIndex}
                    >
                      <span
                        className='float-end mt--1 me-2'
                        onClick={() => handleRemoveSlot(index, slotIndex)}
                      >
                        <strong>X</strong>
                      </span>
                      <h6 className='m-0 fs-5'>Casier</h6>
                    </a>

                    <div className='collapse' id={'collapseCardExample' + slotIndex}>
                      <div className='card-body'>
                        <div className='row mb-3'>
                          <div className='col-sm-4'>
                            <input
                              type='number'
                              className='form-control mb-0'
                              placeholder='Nb. Slots'
                              value={slot.capacity}
                              onChange={(e) =>
                                handleCapacityChange(
                                  index,
                                  slotIndex,
                                  parseInt(e.target.value)
                                )
                              }
                              required
                            />
                          </div>
                          <div className='col-sm-4'>
                            <input
                              type='text'
                              className='form-control mb-0'
                              placeholder='Taille (M/S)'
                              value={slot.size}
                              onChange={(e) =>
                                handleSizeChange(index, slotIndex, e.target.value)
                              }
                              required
                            />
                          </div>
                          <div className='col-sm-4'>
                            <input
                              type='number'
                              className='form-control mb-0'
                              placeholder='Hauteur (cm)'
                              value={slot.height}
                              onChange={(e) =>
                                handleHeightChange(index, slotIndex, parseInt(e.target.value))
                              }
                              required
                            />
                          </div>
                          <div className='col-sm-4 mt-3'>
                            <input
                              type='number'
                              className='form-control mb-0'
                              placeholder='Largeur (cm)'
                              required
                              value={slot.width}
                              onChange={(e) =>
                                handleWidthChange(index, slotIndex, parseInt(e.target.value))
                              }
                            />
                          </div>
                          <div className='col-sm-4 mt-3'>
                            <input
                              type='number'
                              className='form-control mb-0'
                              placeholder='Profondeur (cm)'
                              value={slot.depth}
                              onChange={(e) =>
                                handleDepthChange(index, slotIndex, parseInt(e.target.value))
                              }
                              required
                            />
                          </div>
                          <div className='col-sm-4 mt-3'>
                            <input
                              type='number'
                              className='form-control mb-0'
                              placeholder='Poid limit (kg)'
                              value={slot.maxWeight}
                              onChange={(e) =>
                                handleMaxWeightChange(
                                  index,
                                  slotIndex,
                                  parseInt(e.target.value)
                                )
                              }
                              required
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </>
              {/* Btn new Slot */}
              <div className='row mb-3'>
                <div className='col'>
                  <button
                    type='submit'
                    className='btn btn-outline-secondary w-100 radius-light py-4 border-dashed'
                    onClick={() => handleAddSlot(index)}
                  >
                    <i className='ri-add-circle-line fs-4'></i>
                    <br />
                    Ajouter un nouveau casier
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
      {/* Btn new Zone */}
      <div className='row mb-3'>
        <div className='col'>
          <button
            type='submit'
            className='btn btn-outline-secondary w-100 radius-light py-4 border-dashed'
            onClick={addNewZone}
          >
            <i className='ri-add-circle-line fs-4'></i>
            <br />
            Ajouter une nouvelle zone de température
          </button>
        </div>
      </div>
      <hr />
      {/* Btn Submit */}
      <div className='row mb-3'>
        <div className='col'>
          <button type='submit' className='btn btn--primary w-100' onClick={createLocker}>
            Enregistrer
          </button>
        </div>
      </div>
    </>
  )
}
