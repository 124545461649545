import AuthService from "@/services/Auth/AuthService";
import { flashStore } from "@/utils/Store";
import { AxiosResponse } from "axios";
import { ArrowLeft } from "lucide-react";
import React from "react";
import { Form, Button, Row, Col, Image } from "react-bootstrap";
import { useForm, SubmitHandler } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

type Inputs = {
  email: string;
};

interface ApiResponse {
  response: AxiosResponse;
  violations: Violation[];
}

interface Violation {
  propertyPath: string;
  message: string;
  code: string;
}

export const ForgotPassword: React.FC = () => {
  /* States / Hooks
   *******************************************************************************************/
  const [onSubmitLoading, setOnSubmitLoading] = React.useState<boolean>(false);
  const [messageErrorInput, setMessageErrorInput] = React.useState<string>("");
  const flash = flashStore();
  const { register, handleSubmit } = useForm<Inputs>();
  const navigate = useNavigate();

  /* UseEffect
   *******************************************************************************************/
  React.useEffect(() => {}, []);

  /* Functions
   *******************************************************************************************/
  const onSubmit: SubmitHandler<Inputs> = (data) => {
    setOnSubmitLoading(true);
    const currentUrl: string =
      window.location.origin + "/reinitialisation-mot-de-passe";
    AuthService.forgotPassword(data.email, currentUrl)
      .then(() => {
        Swal.fire({
          title: "Demande effectuée !",
          text:
            "Un e-mail a été envoyé à " +
            data.email +
            " pour réinitialiser votre mot de passe.",
          icon: "success",
        });
      })
      .catch((error: ApiResponse) => {
        if (error.response.data["hydra:description"]) {
          flash.setMessageErrorForm();
          setMessageErrorInput(error.response.data["hydra:description"]);
        } else {
          flash.setMessageErrorForm();
        }
      })
      .finally(() => {
        setOnSubmitLoading(false);
      });
  };

  /* Render
   *******************************************************************************************/
  return (
    <>
      <Row className="justify-content-center mt-5 p-5">
        <Col className="col-lg-4 col-sm-8 col-xs-12 align-middle">
          <div className="mx-auto text-center">
            <Link to="/connexion">
              <Image
                src="/img/logo-full.png"
                alt="EasyBox"
                width={250}
                className="text-center"
              />
            </Link>
          </div>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Group className="mb-3" controlId="User Name">
              <Form.Control
                type="email"
                placeholder="Votre adresse e-mail"
                autoComplete="email"
                {...register("email", {
                  required: "L'adresse e-mail est obligatoire",
                })}
              />
              {messageErrorInput !== "" && (
                <small className="text-danger">{messageErrorInput}</small>
              )}
            </Form.Group>
            <Button variant="success" className="btn-sm w-100" type="submit">
              {onSubmitLoading
                ? "Traitement en cours..."
                : "Réinitialiser mon mot de passe"}
            </Button>
          </Form>
          <div className="text-center mt-3">
            <Button variant="bg-transparent" onClick={() => navigate(-1)}>
              <ArrowLeft size={14} /> Retour
            </Button>
          </div>
        </Col>
      </Row>
    </>
  );
};
