import AuthService from "@/services/Auth/AuthService";
import { getMessageErrorSubmit } from "@/utils/Functions";
import { flashStore } from "@/utils/Store";
import * as Sentry from "@sentry/react";
import { AxiosResponse } from "axios";
import React from "react";
import { Form, Button, Row, Col, Image } from "react-bootstrap";
import { useForm, SubmitHandler } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";

type Inputs = {
  password: string;
  retypedPassword: string;
};

interface ApiResponse {
  response: AxiosResponse;
  violations: Violation[];
}

interface Violation {
  propertyPath: string;
  message: string;
  code: string;
}

export const ResetPassword: React.FC = () => {
  /* States / Hooks
   *******************************************************************************************/
  const [onSubmitLoading, setOnSubmitLoading] = React.useState<boolean>(false);
  const [messageErrorInput, setMessageErrorInput] = React.useState();
  const param = useParams();
  const flash = flashStore();
  const navigate = useNavigate();
  const { register, handleSubmit } = useForm<Inputs>();

  /* UseEffect
   *******************************************************************************************/
  React.useEffect(() => {}, []);

  /* Functions
   *******************************************************************************************/
  const onSubmit: SubmitHandler<Inputs> = (data) => {
    setOnSubmitLoading(true);
    if (!param.token) {
      flash.setMessage(
        "error",
        "Désolé ! Vous n'avez fait aucune demande de réinitialisation."
      );
      return;
    }
    AuthService.resetPassword(data.password, data.retypedPassword, param.token)
      .then(() => {
        flash.setMessage(
          "success",
          "Mot de passe enregistré. Veuillez vous connecter"
        );
        navigate("/connexion");
      })
      .catch((error: ApiResponse) => {
        if (error?.response?.data?.violations?.length > 0) {
          flash.setMessageErrorValidator();
          setMessageErrorInput(error.response.data.violations);
        } else {
          flash.setMessageErrorForm();
          Sentry.captureException(error);
        }
      })
      .finally(() => {
        setOnSubmitLoading(false);
      });
  };

  /* Render
   *******************************************************************************************/
  return (
    <>
      <Row className="justify-content-center mt-5 p-5">
        <Col className="col-lg-4 col-sm-8 col-xs-12 align-middle">
          <div className="mx-auto text-center">
            <Image
              src="/img/logo-full.png"
              alt="EasyBox"
              width={250}
              className="text-center"
            />
          </div>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Group className="mb-3" controlId="Password">
              <Form.Control
                type="password"
                placeholder="Votre nouveau mot de passe"
                {...register("password", {
                  required: "Mot de passe obligatoire",
                })}
              />
              {messageErrorInput && (
                <small className="text-danger">
                  {getMessageErrorSubmit(messageErrorInput, "password")}
                </small>
              )}
            </Form.Group>
            <Form.Group className="mb-3" controlId="retypedPassword">
              <Form.Control
                type="password"
                placeholder="Confirmez votre nouveau mot de passe"
                {...register("retypedPassword", {
                  required: "Confirmation du mot de passe obligatoire",
                })}
              />
            </Form.Group>
            <Button variant="success" className="btn-sm w-100" type="submit">
              {onSubmitLoading ? "Traitement en cours..." : "Enregistrer"}
            </Button>
          </Form>
        </Col>
      </Row>
    </>
  );
};
