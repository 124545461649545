import { EmptyData } from '@/components/lib/EmptyData'
import { Loading } from '@/components/lib/Loading'
import { useUserRole } from '@/hooks/useUserRole'
import AuthService from '@/services/Auth/AuthService'
import LockerService from '@/services/Locker/LockerService'
import { LockerType } from '@/types/LockerType'
import { flashStore } from '@/utils/Store'
import * as Sentry from '@sentry/react'
import { AxiosError, AxiosResponse } from 'axios'
import { List, MoreVertical, Plus, Trash2 } from 'lucide-react'
import React from 'react'
import { Button, Card, Dropdown, Table } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Swal, { SweetAlertResult } from 'sweetalert2'

interface ErrorResponse {
  response: AxiosResponse
  hydra: {
    description: string
  }
}

export const LockerListTable = () => {
  /* States / Hooks
   *******************************************************************************************/
  const [data, setData] = React.useState<LockerType[]>([])
  const [loading, setLoading] = React.useState<boolean>(true)
  const [nextLoading, setNextLoading] = React.useState<boolean>(false)
  const [nextPage, setNextPage] = React.useState<string | undefined>()
  const [currentPage] = React.useState<number>(1)
  const [perPage] = React.useState<number>(10)
  const flash = flashStore()
  const myRole = useUserRole()

  /* useEffect
   *******************************************************************************************/
  React.useEffect(() => {
    getData(currentPage, perPage)
  }, [currentPage, perPage])

  /* Functions
   *******************************************************************************************/
  const getData = (page: number, perPage: number) => {
    LockerService.getLockerPerPage(page, perPage)
      .then((response: AxiosResponse) => {
        setData(response.data['hydra:member'])
        setNextPage(response.data['hydra:view']['hydra:next'])
      })
      .catch((error: AxiosError) => {
        AuthService.refreshToken()
        Sentry.captureException(error)
      })
      .finally(() => setLoading(false))
  }

  const handleNextPage = () => {
    setNextLoading(true)
    LockerService.getLockerLoadNextPage(nextPage)
      .then((response: AxiosResponse) => {
        setData(data.concat(response.data['hydra:member']))
        setNextPage(response.data['hydra:view']['hydra:next'])
      })
      .catch((error: AxiosError) => {
        AuthService.refreshToken()
        Sentry.captureException(error)
      })
      .finally(() => setNextLoading(false))
  }

  const handleDelete = (data: LockerType) => {
    Swal.fire({
      title: 'Confirmation !',
      text: 'Voulez-vous vraiment supprimer la machine #' + data.id + ' ?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Oui, je confirme',
      cancelButtonText: 'Non',
    }).then((result: SweetAlertResult) => {
      if (result.isConfirmed) {
        LockerService._delete(Number(data.id))
          .then(() => {
            flash.setMessage('success', 'Machine supprimée !')
            getData(currentPage, perPage)
          })
          .catch((e: ErrorResponse) => {
            if (e?.response?.status == 403) {
              flash.setMessage('error', e?.response?.data['hydra:description'])
            }
          })
      }
    })
  }

  /* Render
   *******************************************************************************************/
  return (
    <Card>
      <>
        {loading ? (
          <Loading />
        ) : data?.length === 0 ? (
          <EmptyData />
        ) : (
          <>
            <Table striped hover>
              <thead>
                <tr>
                  <th>#</th>
                  <th>ID</th>
                  <th>Modèle</th>
                  <th>Nom utilisé</th>
                  <th>Emplacement</th>
                  <th>Commune</th>
                  {myRole === import.meta.env.VITE_APP_ROLE_SUPER_ADMIN && <th>Actions</th>}
                </tr>
              </thead>
              <tbody>
                {data.map((locker: LockerType) => (
                  <tr key={locker?.id}>
                    <td>
                      <Link to={'/lockers/detail/' + locker?.id}>{locker?.id}</Link>
                    </td>
                    <td>
                      <Link to={'/lockers/detail/' + locker?.id}>{locker?.cleveronApmId}</Link>
                    </td>
                    <td>
                      <Link to={'/lockers/detail/' + locker?.id}>{locker?.type}</Link>
                    </td>
                    <td>
                      <Link to={'/lockers/detail/' + locker?.id}>{locker?.shortLocation}</Link>
                    </td>
                    <td>
                      <Link to={'/lockers/detail/' + locker?.id}>{locker?.location}</Link>
                    </td>
                    <td>
                      <Link to={'/lockers/detail/' + locker?.id}>{locker?.city}</Link>
                    </td>
                    {myRole === import.meta.env.VITE_APP_ROLE_SUPER_ADMIN && (
                      <td>
                        <Dropdown className='datatable-dropdown no-arrow'>
                          <Dropdown.Toggle variant='secondary'>
                            <MoreVertical />
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item>
                              <Link
                                to={'/lockers/detail/' + locker?.id}
                                className='py-2 d-block'
                              >
                                <List size={16} /> Détail
                              </Link>
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => handleDelete(locker)}
                              className='py-2 d-block'
                            >
                              <Trash2 size={16} /> Supprimer
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </Table>
            {nextPage !== undefined && (
              <div className='ms-auto mb-3 me-3'>
                <Button variant='dark' className='d-inline w-20' onClick={handleNextPage}>
                  {nextLoading ? (
                    'En cours de chargement...'
                  ) : (
                    <>
                      <Plus size={20} /> Charger plus{' '}
                    </>
                  )}
                </Button>
              </div>
            )}
          </>
        )}
      </>
    </Card>
  )
}
