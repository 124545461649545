import React from "react";
import * as Sentry from "@sentry/react";
import { MoreVertical, List, Pencil, Trash2, Plus } from "lucide-react";
import { Button, Card, Dropdown, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Loading } from "@/components/lib/Loading";
import { AxiosError, AxiosResponse } from "axios";
import CompanyService from "@/services/Company/CompanyService";
import Swal, { SweetAlertResult } from "sweetalert2";
import { flashStore } from "@/utils/Store";
import { CompanyType } from "@/types/CompanyType";
import AuthService from "@/services/Auth/AuthService";
import { setDateTime } from "@/utils/Functions";
import { EmptyData } from "@/components/lib/EmptyData";
import { useUserRole } from "@/hooks/useUserRole";

interface ErrorResponse {
  response: AxiosResponse;
  hydra: {
    description: string;
  };
}

export const CompanyListTable = () => {
  /* States / Hooks
   *******************************************************************************************/
  const [data, setData] = React.useState<CompanyType[]>([]);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [nextLoading, setNextLoading] = React.useState<boolean>(false);
  const [nextPage, setNextPage] = React.useState<string | undefined>();
  const [currentPage] = React.useState<number>(1);
  const [perPage] = React.useState<number>(20);
  const flash = flashStore();
  const myRole = useUserRole();

  /* useEffect
   *******************************************************************************************/
  React.useEffect(() => {
    getData(currentPage, perPage);
     
  }, [currentPage, perPage]);

  /* Functions
   *******************************************************************************************/
  const getData = (page: number, perPage: number) => {
    CompanyService.getCompanyPerPage(page, perPage)
      .then((response: AxiosResponse) => {
        setData(response.data["hydra:member"]);
        setNextPage(response.data["hydra:view"]["hydra:next"]);
      })
      .catch((error: AxiosError) => {
        AuthService.refreshToken();
        Sentry.captureException(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleNextPage = () => {
    setNextLoading(true);
    CompanyService.getCompanyLoadNextPage(nextPage)
      .then((response: AxiosResponse) => {
        setData(data.concat(response.data["hydra:member"]));
        setNextPage(response.data["hydra:view"]["hydra:next"]);
      })
      .catch((error: AxiosError) => {
        AuthService.refreshToken();
        Sentry.captureException(error);
      })
      .finally(() => setNextLoading(false));
  };

  const handleDelete = (data: CompanyType) => {
    Swal.fire({
      title: "Confirmation !",
      text: "Voulez-vous vraiment supprimer la société " + data.name + " ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Oui, je confirme",
      cancelButtonText: "Non",
    }).then((result: SweetAlertResult) => {
      if (result.isConfirmed) {
        CompanyService._delete(Number(data.id))
          .then(() => {
            flash.setMessage(
              "success",
              "Société " + data.name + " supprimée !"
            );
            getData(currentPage, perPage);
          })
          .catch((e: ErrorResponse) => {
            if (e?.response?.status == 403) {
              flash.setMessage("error", e?.response?.data["hydra:description"]);
            }
          });
      }
    });
  };

  /* Render
   *******************************************************************************************/
  return (
    <Card>
      <>
        {loading ? (
          <Loading />
        ) : data?.length === 0 ? (
          <EmptyData />
        ) : (
          <>
            <Table striped hover>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Nom</th>
                  <th>Client / Organisation</th>
                  <th>Adresse email</th>
                  <th>Téléphone</th>
                  <th>Date de création</th>
                  {myRole === import.meta.env.VITE_APP_ROLE_ADMIN ||
                    (myRole === import.meta.env.VITE_APP_ROLE_SUPER_ADMIN && (
                      <th>Actions</th>
                    ))}
                </tr>
              </thead>
              <tbody>
                {data.map((company: CompanyType) => (
                  <tr key={company?.id}>
                    <td>
                      <Link to={"/societes/detail/" + company?.id}>
                        {company?.id}
                      </Link>
                    </td>
                    <td>
                      <Link to={"/societes/detail/" + company?.id}>
                        {company?.name}
                      </Link>
                    </td>
                    <td>
                      <Link to={"/societes/detail/" + company?.id}>
                        {company?.contactName}
                      </Link>
                    </td>
                    <td>
                      <Link to={"/societes/detail/" + company?.id}>
                        {company?.contactEmail}
                      </Link>
                    </td>
                    <td>
                      <Link to={"/societes/detail/" + company?.id}>
                        {company?.contactPhone}
                      </Link>
                    </td>
                    <td>
                      <Link to={"/societes/detail/" + company?.id}>
                        {setDateTime(company?.createdAt)}
                      </Link>
                    </td>
                    {myRole === import.meta.env.VITE_APP_ROLE_ADMIN ||
                      (myRole === import.meta.env.VITE_APP_ROLE_SUPER_ADMIN && (
                        <td>
                          <Dropdown className="datatable-dropdown no-arrow">
                            <Dropdown.Toggle variant="secondary">
                              <MoreVertical />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item>
                                <Link
                                  to={"/societes/detail/" + company?.id}
                                  className="py-2 d-block"
                                >
                                  <List size={16} /> Détail
                                </Link>
                              </Dropdown.Item>
                              <Dropdown.Item
                                href={"/societes/modification/" + company?.id}
                                className="py-2 d-block"
                              >
                                <Pencil size={16} /> Modifier
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={() => handleDelete(company)}
                                className="py-2"
                              >
                                <Trash2 size={16} /> Supprimer
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </td>
                      ))}
                  </tr>
                ))}
              </tbody>
            </Table>
            {nextPage !== undefined && (
              <div className="ms-auto mb-3 me-3">
                <Button
                  variant="dark"
                  className="d-inline w-20"
                  onClick={handleNextPage}
                >
                  {nextLoading ? (
                    "En cours de chargement..."
                  ) : (
                    <>
                      <Plus size={20} /> Charger plus{" "}
                    </>
                  )}
                </Button>
              </div>
            )}
          </>
        )}
      </>
    </Card>
  );
};
