import { Loading } from '@/components/lib/Loading'
import { useUserRole } from '@/hooks/useUserRole'
import { LockerType } from '@/types/LockerType'
import { setDateTime, setImageLocker } from '@/utils/Functions'
import { Edit } from 'lucide-react'
import React from 'react'
import { Badge, Card, Image } from 'react-bootstrap'
import { Link } from 'react-router-dom'

type Props = {
  data: LockerType | undefined
}

export const LockerDetailCard: React.FC<Props> = ({ data }: Props) => {
  const myRole = useUserRole()

  return (
    <Card className='py-3'>
      <Card.Body>
        {!data ? (
          <Loading />
        ) : (
          <>
            <div className='text-center'>
              <Image
                src={setImageLocker(Number(data?.type))}
                alt={data?.cleveronApmId}
                className='img-thumbnail mb-3'
              />
            </div>
            <div className='text-center mb-4'>
              <Card.Title>Modèle {data?.type}</Card.Title>
              <Badge bg='primary pt-2 pb-1 mb-3'>Ref: {data?.cleveronApmId}</Badge>
              <br />
              <small className='text-muted'>
                Date création : {setDateTime(data?.createdAt)}
              </small>
            </div>
            <hr />
            <p>
              Emplacement :<span className='float-end'>{data?.location}</span>
            </p>
            <p>
              Commune :<span className='float-end'>{data?.city}</span>
            </p>
            {myRole === import.meta.env.VITE_APP_ROLE_SUPER_ADMIN && (
              <>
                <hr />
                <Link
                  className='btn btn-sm btn-dark d-block'
                  to={'/lockers/modification/' + data?.id}
                >
                  <Edit className='me-2' size={20} />
                  Modifier les informations
                </Link>
              </>
            )}
          </>
        )}
      </Card.Body>
    </Card>
  )
}
