import React from "react";
import { Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

export const Dashboard = () => {
  const navigate = useNavigate();
  React.useEffect(() => {
    navigate("/societes");
  }, [navigate]);
  return (
    <Row>
      <h3>Tableau de bord</h3>
    </Row>
  );
};
