import { Card, Col, Row } from "react-bootstrap";
import { Title } from "@/components/lib/Title";
import { ButtonLinkBack } from "@/components/lib/ButtonLinkBack";
import { BookingSlotAddForm } from "@/components/entities/booking_slot/BookingSlotAddForm";

export const BookingSlotAdd = () => {
  /* Render
   *******************************************************************************************/
  return (
    <>
      <Row>
        <Col>
          <Title name={"Booking / création"} />
        </Col>
        <Col>
          <ButtonLinkBack />
        </Col>
      </Row>
      <Row className="mt-4">
        <Col className="col-xl-6 col-lg-10 col-md-12 mx-auto">
          <Card className="mb-4">
            <Card.Header>
              <h5 className="mt-2">Ajouter un nouveau booking</h5>
            </Card.Header>
            <Card.Body className="p-5">
              <BookingSlotAddForm />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};
